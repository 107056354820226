import React from "react";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import "./styles.css";

// MUI Components Import
import { Container } from "@mui/material";
import { useUserThemeContext } from "../../contexts/ThemeContext";
import SideBar from "./components/Sidebar";

// Components Import

function LandingPage() {
  const { bgTheme } = useUserThemeContext();

  return (
    <>
      <Wrapper maxWidth={false} sx={{ backgroundColor: `${bgTheme}` }}>
        <SideBar />
        <Outlet />
      </Wrapper>
    </>
  );
}

// Styled Component
const Wrapper = styled(Container)(({ theme }) => ({
  padding: "0 !important",
  display: "flex",
  overflow: "hidden",
}));

export default LandingPage;
