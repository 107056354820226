import { Box, Typography } from "@mui/material";
import React from "react";
// Icons Import
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";

const FooterBG = ({ bg, textClr }) => {
  const textColor = textClr;

  return (
    <>
      <Box
        sx={{
          backgroundColor: bg,
          width: "100%",
          padding: "15px 20px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "start",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.4rem",
              fontWeight: "800",
              color: textColor,
            }}
          >
            Store Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              gap: "5px",
            }}
          >
            <LocationIcon
              sx={{
                fontSize: "20px",
                color: textColor,
              }}
            />
            <Typography
              sx={{
                fontSize: "1.0rem",
                fontWeight: "400",
                color: textColor,
              }}
            >
              Al Yasmeen, Saudi Arabia
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              gap: "5px",
            }}
          >
            <PhoneIcon
              sx={{
                fontSize: "20px",
                color: textColor,
              }}
            />
            <Typography
              sx={{
                fontSize: "1.0rem",
                fontWeight: "400",
                color: textColor,
              }}
            >
              +920035359
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              gap: "5px",
            }}
          >
            <EmailIcon
              sx={{
                fontSize: "20px",
                color: textColor,
              }}
            />
            <Typography
              sx={{
                fontSize: "1.0rem",
                fontWeight: "400",
                color: textColor,
              }}
            >
              info@nawahai.com.sa
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FooterBG;
