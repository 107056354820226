import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  InputBase,
  Pagination,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import Loader from "../../../../../components/Loaders/Loader";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/MUITable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../../constants/api";
import { useLocation } from "react-router-dom";
import { CUSTOMER_PAGINATION_LIMIT } from "../../../../../constants/constant";
import Heading from "../../../components/Heading";
import CustomChip from "../../../../../components/CustomChip";

const headerData = {
  heading: "Customer Orders",
  subheading: "Keep track of your Orders",
};

// Update the order of the table headers
const tableHead = [
  "#",
  "Order ID",
  "Date",
  "Amount",
  "Order Status",
  "Payment Status",
];

function CustomerOrder() {
  const token = localStorage.getItem("user-token");
  const location = useLocation();
  const id = location?.pathname.split("/").pop() || {};

  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const fetchOrders = async () => {
    if (startDate && endDate) {
      handleApply();
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/orders/${id}`, {
        params: {
          limit: CUSTOMER_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const results = response?.data?.results || [];
      setOrders(results);
      setTotalCount(response.data?.total_count);
    } catch (err) {
      console.log("Error", err);
      toast.error("Failed to fetch orders");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [page]);

  const handleSearch = async () => {
    const ordId = searchTerm.trim();
    if (ordId === "") return;
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/api/orders/${id}?limit=${CUSTOMER_PAGINATION_LIMIT}&offset=${page}`,
        {
          ordId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const results = response?.data?.results || [];
      setOrders(results);
      setTotalCount(response.data?.total_count);
    } catch (err) {
      console.log("Error", err);
      toast.error("Failed to fetch orders");
    } finally {
      setIsLoading(false);
    }
  };

  const handleApply = async () => {
    if (!startDate || !endDate) {
      toast.error("Please select start and end date");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/orders/${id}?limit=${CUSTOMER_PAGINATION_LIMIT}&offset=${page}`,
        {
          start_time: startDate,
          end_time: endDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const results = response?.data?.results || [];
      setOrders(results);
      setTotalCount(response.data?.total_count);
    } catch (err) {
      console.log("Error", err);
      toast.error("Failed to fetch orders");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSearchTerm("");
    setPage(1);
    fetchOrders();
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Heading data={headerData} />
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="secondary"
            startIcon={<DownloadIcon />}
            onClick={() => {
              /* Download functionality */
            }}
          >
            Download XLXS
          </Button>
        </Box>
        <SearchNav>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by Order ID"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <StyledButton onClick={handleSearch}>Search</StyledButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              sx={{ width: "150px" }}
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              sx={{ width: "150px" }}
            />
            <StyledButton onClick={handleApply}>Apply</StyledButton>
            <StyledButton onClick={handleReset}>Reset</StyledButton>
          </Box>
        </SearchNav>

        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead}>
              {orders.length > 0 ? (
                orders.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.ordId || "N/A"}</StyledTableCell>
                    <StyledTableCell>
                      {row.created_at
                        ? new Date(row.created_at).toLocaleString()
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.total_price !== undefined
                        ? parseFloat(row.total_price).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {<CustomChip label={row?.status_display} />}
                    </StyledTableCell>
                    <StyledTableCell>
                      {
                        <CustomChip
                          label={row.transaction?.payment_status_display}
                        />
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead.length}
                    align="center"
                  >
                    No records found
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                margin: "1px 0 0",
                padding: "15px 10px",
              }}
            >
              <Pagination
                page={page}
                count={Math.ceil(totalCount / CUSTOMER_PAGINATION_LIMIT)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#333",
  padding: "0 20px 0 40px",
  width: "100%",
  height: "100%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: "none",
  borderRadius: "8px",
  padding: "6px 16px",
  textTransform: "none",
  color: theme.palette.primary.main,
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    boxShadow: "none",
  },
}));

export default CustomerOrder;
