import React, { useEffect, useState } from "react";
import Heading from "../../../components/Heading";

// MUI Components import
import {
  Box,
  Button,
  Container,
  IconButton,
  InputBase,
  Pagination,
  Tooltip,
  styled,
} from "@mui/material";

// Icons Import
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/BorderColor";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/MUITable";
import { useNavigate } from "react-router-dom";
import { useGetRFQsQuery } from "../../../../../redux/api/rfqsApiSlice";
import { RFQ_PAGINATION_LIMIT } from "../../../../../constants/constant";
import Loader from "../../../../../components/Loaders/Loader";
import { CiDeliveryTruck } from "react-icons/ci";
import CustomChip from "../../../../../components/CustomChip";
import axios from "axios";
import { baseUrl } from "../../../../../constants/api";
import { useLoaderContext } from "../../../../../contexts/LoaderContext";
import { MoonLoader } from "react-spinners";
import { GiNotebook } from "react-icons/gi";

const headerData = {
  heading: "My RFQs",
  subheading: "",
};

function RfqList() {
  const navigate = useNavigate();
  const { loading, handleLoader } = useLoaderContext();
  const token = localStorage.getItem("user-token");

  const [rfqs, setRFQs] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRFQ, setSelectedRFQ] = useState("");
  const [RFQResponse, setRFQResponse] = useState("");

  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchRFQs,
  } = useGetRFQsQuery({
    limit: RFQ_PAGINATION_LIMIT,
    offset: page,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data) {
      setCount(data?.total_count);
      setRFQs(data?.results);
    }
  }, [data, page]);

  const tableHead = [
    "#",
    "RFQ ID",
    "RFQ Status",
    "RFQ Closing Date",
    "Payment Status",
    "Delivery Address",
    "Last Update",
    "Closing Date",
    "Action",
  ];

  // todo: SET RFQ STATUS API
  const RFQStatusHandle = async (id) => {
    try {
      handleLoader(true);
      const res = await axios.get(
        `${baseUrl}/mv/api/delivery/note/create/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res) {
        setRFQResponse(res?.data);
        refetchRFQs();
        handleLoader(false);
        navigate(`/dashboard/rfqs/delivery-note/${res?.data?.uuid}`);
      }
    } catch (error) {
      handleLoader(false);
      // Handle error appropriately, e.g., show a notification to the user
    }
  };

  useEffect(() => {
    if (RFQResponse) {
      console.log("RFQResponse api", RFQResponse);
    }
  }, [RFQResponse]);

  const searchRFQ = () => {
    handleLoader(true);
    axios
      .post(
        `${baseUrl}/api/supplier/rfqs`,
        {
          search_term: searchTerm,
        },
        {
          params: {
            limit: RFQ_PAGINATION_LIMIT,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setRFQs(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Heading data={headerData} />
        </Box>
        <SearchNav>
          <Box
            sx={{
              width: "30vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search RFQ Id"
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "90%", left: "30px" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <Button onClick={() => searchRFQ()}>Search</Button>
          </Box>
        </SearchNav>
        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead} handleChangePage={handleChangePage}>
              {isSuccess && rfqs?.length > 0 ? (
                rfqs?.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{row?.rfqId}</StyledTableCell>
                      <StyledTableCell>
                        {<CustomChip label={row?.status_display} />}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(row?.rfqCloseDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {<CustomChip label={row?.payment_status_display} />}
                      </StyledTableCell>
                      <StyledTableCell>{row?.deliveryLocation}</StyledTableCell>
                      <StyledTableCell>
                        {new Date(row?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(row?.rfqCloseDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="View Details" placement="top">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              navigate(
                                `/dashboard/rfqs/rfq-detail/${row?.uuid}`
                              )
                            }
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Tooltip>
                        {row?.user?.status === 1 && (
                          <Tooltip title="Edit RFQ" placement="top">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                navigate(
                                  `/dashboard/rfqs/rfq-edit/${row?.uuid}`
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {/* Delivery */}
                        {loading && selectedRFQ?.uuid === row?.uuid ? (
                          <Tooltip title="Delivery" placement="top">
                            <IconButton color="primary">
                              <MoonLoader color="#000" size={18} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ((row?.user?.status === 2 &&
                            row?.createdBy?.status === 2) ||
                            row?.createdBy?.status === 3) && (
                            <Tooltip title="Delivery" placement="top">
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  if (row?.dn === null) {
                                    setSelectedRFQ(row);
                                    RFQStatusHandle(row?.uuid);
                                  } else {
                                    navigate(
                                      `/dashboard/rfqs/delivery-note/${row?.dn?.uuid}`
                                    );
                                  }
                                }}
                              >
                                <CiDeliveryTruck />
                              </IconButton>
                            </Tooltip>
                          )
                        )}
                        {/* Showing Delivery Details of RFQ */}
                        {(row?.status_display === "Dispatched" ||
                          row?.status_display === "Delivered") && (
                          <Tooltip
                            title="RFQ Delivery Note Details"
                            placement="top"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                navigate(
                                  `/dashboard/rfqs/delivery-note/${row?.dn?.uuid}`
                                );
                              }}
                            >
                              <GiNotebook />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {rfqs?.length === 0 ? "No records found" : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                padding: "10px",
                background: "#fff",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Pagination
                count={Math.ceil(count / RFQ_PAGINATION_LIMIT)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));
export default RfqList;
