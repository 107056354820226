// MUI Imports
import { Box, Button } from "@mui/material";
// Custom
import Errors from "../../../../../../components/Error";
import { SubHeading } from "../../../../../../components/Heading";
import SelectInput from "../../../../../../components/SelectInput";
import PrimaryInput from "../../../../../../components/PrimaryInput/PrimaryInput";

export const renderInputField = (
  heading,
  placeholder,
  type,
  name,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  multiline = false, // Optional parameter for multiline
  maxRows = null, // Optional parameter for maxRows
  isItService = false
) => {
  const numberValidation = [
    "productQuantity",
    "minQty",
    "orderLimit",
    "reorderLevel",
    "purchasePrice",
    "sellingPrice",
    "onlinePrice",
    "weight",
    "VHeight",
    "VWidth",
    "VLength",
  ];

  const headingArrayOne = [
    "Product Name (Eng)",
    "Product Name (Arabic)",
    "Product Bar Code",
    "Product Quantity", // another
    "Online Price",
    "Description (Eng)",
    "Description (Arabic)",
  ];

  const headingArrayTwo = [
    "Product Name (Eng)",
    "Product Name (Arabic)",
    "Product Bar Code",
    "Online Price",
    "Description (Eng)",
    "Description (Arabic)",
  ];

  const headingArray = isItService ? headingArrayTwo : headingArrayOne;

  const handleInputChange = (e) => {
    const { value } = e.target;
    let formattedValue = value;

    if (numberValidation.includes(name)) {
      // Remove non-numeric characters except for commas and periods
      formattedValue = formattedValue.replace(/[^0-9.,]/g, "");

      // Remove existing commas to avoid issues with formatting
      formattedValue = formattedValue.replace(/,/g, "");

      // Allow clearing the input field entirely
      if (/^\d*(\.\d{0,2})?$/.test(formattedValue)) {
        // Format the input value with commas
        formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    handleChange({
      target: {
        name,
        value: formattedValue,
      },
    });
  };

  const handleInputBlur = (e) => {
    let value = values[name];

    if (numberValidation.includes(name) && value) {
      // Remove commas for processing
      value = value.replace(/,/g, "");

      // If the value does not contain a decimal point, add ".00" at the end
      if (!/\./.test(value)) {
        value = `${value}.00`;
      }

      // Format with commas again
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Update the value after formatting and trigger blur
    handleChange({
      target: {
        name,
        value,
      },
    });

    handleBlur(e); // Trigger the provided handleBlur function
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: multiline ? "auto" : "85px", // Set height conditionally
      }}
    >
      <SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
        {heading}
        {headingArray.includes(heading) && (
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        )}
      </SubHeading>
      <PrimaryInput
        readOnly={isItService}
        type={type}
        label=""
        name={name}
        placeholder={placeholder}
        value={values[name]}
        helperText={errors[name] && touched[name] ? errors[name] : ""}
        error={errors[name] && touched[name] ? true : false}
        onChange={
          numberValidation.includes(name) ? handleInputChange : handleChange
        }
        onBlur={numberValidation.includes(name) ? handleInputBlur : handleBlur}
        borderRadius="4px"
        multiline={multiline} // Apply multiline prop
        maxRows={multiline ? maxRows : undefined} // Apply maxRows only if multiline is true
      />
    </Box>
  );
};

export const renderDropdownField = (
  heading,
  name,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  options,
  handleAddClick = null // Optional handler for the "Add New" button
) => {
  const headingArray = ["Category", "Subcategory"];

  return (
    <Box sx={{ width: "100%", height: "85px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <SubHeading
          sx={{ fontSize: "12px", display: "flex", alignItems: "flex-end" }}
        >
          {heading}
          {headingArray.includes(heading) && (
            <span
              style={{
                color: "red",
              }}
            >
              *
            </span>
          )}
        </SubHeading>
        {handleAddClick && (
          <Button
            sx={{
              fontSize: "12px",
              padding: "2px 4px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#2196f3",
              width: "fit-content",
              color: "#fff",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#1976d2",
              },
            }}
            onClick={() => handleAddClick(heading)}
          >
            Add New
          </Button>
        )}
      </Box>

      <SelectInput
        name={name}
        styles={{ width: "100%" }}
        value={values[name] || ""}
        borderRadius="4px"
        onChange={handleChange}
        data={options}
        onBlur={handleBlur}
        error={errors[name] && touched[name] ? true : false}
        label={`Select ${heading}`}
        options={options?.map((option) => ({
          ...option,
          id: option.value,
          value: option,
          label: option.arb_name
            ? `${option.name} - ${option.arb_name}`
            : option.name,
        }))}
      >
        {errors[name] && (
          <Errors sx={{ paddingLeft: "0" }}>
            {errors[name] && touched[name] ? errors[name] : ""}
          </Errors>
        )}
      </SelectInput>
    </Box>
  );
};
