// React Imports
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// MUI
import { Box, Button, Container, Typography } from "@mui/material";
// Utils
import { onKeyDown } from "../../../../utils";
// Formik
import { Form, Formik } from "formik";
// Icons
import AddPhoto from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";
// Redux Toolkit
import { useGetSaudiCityQuery } from "../../../../redux/api/utilApiSlice";
import { useGetServicesQuery } from "../../../../redux/api/serviceApiSlice";
import { useGetServiceProvidersQuery } from "../../../../redux/api/serviceProviderApiSlice";
import { useGetServiceProductQuery } from "../../../../redux/api/serviceProductsApiSlice";
// React Toastify
import { toast } from "react-toastify";
// Axios
import axios from "axios";
// React Spinners
import { MoonLoader } from "react-spinners";
// Custom
import PrimaryInput from "../../../../components/PrimaryInput/PrimaryInput";
import { SubHeading } from "../../../../components/Heading";
import PrimaryPhoneInput from "../../../../components/PhoneInput";
import { addDoctorSchema } from "../validations/addDoctorSchema";
import Loader from "../../../../components/Loaders/Loader";
import Errors from "../../../../components/Error";
import SelectInputSearch from "../../../../components/SelectInputSearch";
import SelectInput from "../../../../components/SelectInput";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import { baseUrl } from "../../../../constants/api";
import styled from "styled-components";

function AddServiceProvider() {
  const navigate = useNavigate();
  const fileRef = useRef();
  const location = useLocation();
  const id = location?.pathname.split("/")[4];

  const token = localStorage.getItem("user-token");
  const { loading, handleLoader } = useLoaderContext();

  const [formValues, setFormValues] = useState({
    name: "",
    details: "",
    details_ar: "",
    city: "",
    address: "",
    phone: "",
    WhatsApp: "",
    service: "",
    serviceProvider: "",
    qualification: "",
    qualification_ar: "",
    specialty: "",
    specialty_ar: "",
    pictures: [],
  });

  // todo: GET SAUDI API CALL
  const { data: getSaudiCity, isLoading: getSaudiCityLoading } =
    useGetSaudiCityQuery({});

  // todo: GET SERVICES API CALL
  const { data: getServices, isLoading: getServicesLoading } =
    useGetServicesQuery({
      whole: true,
    });

  // todo: GET SERVICE PROVIDER API CALL
  const { data: getServiceProviders, isLoading: getServiceProvidersLoading } =
    useGetServiceProvidersQuery({});

  // todo: 1) GET SERVICE API CALL
  const { data: getService, isLoading: getServiceLoading } =
    useGetServiceProductQuery(id, {
      refetchOnMountOrArgChange: true,
    });

  useEffect(() => {
    if (id) {
      if (getService) {
        const service = getServices?.results?.find(
          (ser) => ser.id === getService?.service
        );

        const serviceProvider = getServiceProviders?.find(
          (ser) => ser.id === getService?.serviceProvider
        );

        const city = getSaudiCity?.find((ser) => ser.id === getService?.city);

        setFormValues({
          ...formValues,
          name: getService?.name,
          service,
          serviceProvider,
          city,
          details: getService?.details?.en?.details,
          details_ar: getService?.details?.ar?.details,
          address: getService?.address,
          phone: getService?.phone_number,
          WhatsApp: getService?.whatsapp_number,
          qualification: getService?.details?.en?.qualification,
          qualification_ar: getService?.details?.ar?.qualification,
          specialty: getService?.details?.en?.specialty,
          specialty_ar: getService?.details?.ar?.specialty,
          pictures: getService?.images.map((img) => {
            return `${img.image}`;
          }),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaudiCity, getService, getServiceProviders, getServices, id]);

  // todo: 2) ADD & UPDATE SERVICE PRODUCT API BIND
  const AddDoctorHandler = async (data) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("service", data.service.id);
    formData.append("serviceProvider", data.serviceProvider.id);
    formData.append("phone_number", data.phone);
    formData.append("whatsapp_number", data.WhatsApp);
    formData.append("address", data.address);
    formData.append("city", data.city.id);
    formData.append(
      "details",
      JSON.stringify({
        en: {
          qualification: data.qualification,
          specialty: data.specialty,
          details: data.details,
        },
        ar: {
          qualification: data.qualification_ar,
          specialty: data.specialty_ar,
          details: data.details_ar,
        },
      })
    );

    // todo: Add Case
    if (!id) {
      for (let i = 0; i < data.pictures.length; i++) {
        formData.append("serviceProductImages[]", data.pictures[i]);
      }
    }

    // todo: Update Case
    if (id) {
      for (let i = 0; i < data.pictures.length; i++) {
        if (getService?.images[i] && "image" in getService?.images[i]) {
          formData.append(
            "serviceProductImagesIds[]",
            getService?.images[i].spiId
          );
        } else {
          formData.append("serviceProductImages[]", data.pictures[i]);
        }
      }
    }

    // todo: UPDATE SERVICE PRODUCT
    if (id) {
      try {
        handleLoader(true);
        const response = await axios.put(
          `${baseUrl}/api/service-product/update/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );
        if (response?.status === 201) {
          toast.success("Service Provider has been updated successfully");
          localStorage.setItem(
            "message",
            "Service Provider has been updated successfully"
          );
          handleLoader(false);
          navigate("/dashboard/service-provider");
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
          handleLoader(false);
        }
      }

      return;
    }

    try {
      handleLoader(true);
      const response = await axios.post(
        `${baseUrl}/api/service-product/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response?.status === 201) {
        toast.success("Service Provider has been added successfully");
        localStorage.setItem(
          "message",
          "Service Provider has been added successfully"
        );
        handleLoader(false);
        navigate("/dashboard/service-provider");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        handleLoader(false);
      }
    }
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        {(getServicesLoading ||
          getSaudiCityLoading ||
          getServicesLoading ||
          getServiceProvidersLoading ||
          getServiceLoading) && <Loader />}
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Box>
            <h3>{id ? "Edit Service Provider" : "Add New Service Provider"}</h3>
          </Box>

          <Box
            sx={{
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              marginTop: "20px",
            }}
          >
            <Box>
              <Formik
                initialValues={formValues}
                onSubmit={(values) => {
                  AddDoctorHandler(values);
                }}
                validationSchema={addDoctorSchema}
                enableReinitialize
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  } = props;

                  return (
                    <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Box sx={{ height: "85px", width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Name
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                            error={errors.name && touched.name ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                          />
                        </Box>

                        <Box
                          sx={{
                            height: "85px",
                            width: "100%",
                          }}
                        >
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Service
                          </SubHeading>

                          <SelectInput
                            name="service"
                            styles={{ width: "100%" }}
                            value={values.service || ""}
                            borderRadius={3}
                            onChange={(e) => {
                              props.handleChange(e);
                            }}
                            data={getServices?.results}
                            onBlur={handleBlur}
                            error={
                              errors.service && touched.service ? true : false
                            }
                            fetching={getServicesLoading}
                            label="Select Service"
                            options={getServices?.results?.map((ser) => {
                              return {
                                ...ser,
                                id: ser.id,
                                value: ser,
                                label: ser.serviceName,
                              };
                            })}
                          >
                            {errors.service && (
                              <Errors sx={{ paddingLeft: "0" }}>
                                {errors.service && touched.service
                                  ? errors.service
                                  : ""}
                              </Errors>
                            )}
                          </SelectInput>
                        </Box>

                        <Box
                          sx={{
                            height: "85px",
                            width: "100%",
                          }}
                        >
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Provider Type
                          </SubHeading>

                          <SelectInput
                            name="serviceProvider"
                            styles={{ width: "100%" }}
                            value={values.serviceProvider || ""}
                            borderRadius={3}
                            onChange={(e) => {
                              props.handleChange(e);
                            }}
                            data={getServiceProviders}
                            onBlur={handleBlur}
                            error={
                              errors.serviceProvider && touched.serviceProvider
                                ? true
                                : false
                            }
                            fetching={getServicesLoading}
                            label="Select Provider Type"
                            options={getServiceProviders?.map((ser) => {
                              return {
                                ...ser,
                                id: ser.id,
                                value: ser,
                                label: `${ser.name} - ${ser.arb_name}`,
                              };
                            })}
                          >
                            {errors.serviceProvider && (
                              <Errors sx={{ paddingLeft: "0" }}>
                                {errors.serviceProvider &&
                                touched.serviceProvider
                                  ? errors.serviceProvider
                                  : ""}
                              </Errors>
                            )}
                          </SelectInput>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <Box sx={{ height: "85px", width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Address
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="address"
                            placeholder="Address"
                            value={values.address}
                            helperText={
                              errors.address && touched.address
                                ? errors.address
                                : ""
                            }
                            error={
                              errors.address && touched.address ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                          />
                        </Box>

                        <Box sx={{ height: "85px", width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            City
                          </SubHeading>

                          <SelectInputSearch
                            formik={props}
                            name="city"
                            options={getSaudiCity}
                            placeholder="Select City"
                            value={values.city}
                            onChange={(e, value) => {
                              setFieldValue("city", value);
                            }}
                            onBlur={handleBlur}
                            error={errors.city && touched.city ? true : false}
                            helperText={
                              errors.city && touched.city ? errors.city : ""
                            }
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <Box sx={{ height: "85px", width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Phone Number
                          </SubHeading>
                          <PrimaryPhoneInput
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="phone"
                            formik={props}
                            sx={{
                              borderRadius: "12px",
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            height: "85px",
                            width: "100%",
                          }}
                        >
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Whats App
                          </SubHeading>
                          <PrimaryPhoneInput
                            value={values.WhatsApp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="WhatsApp"
                            formik={props}
                            sx={{
                              borderRadius: "12px",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Qualification (Eng)
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="qualification"
                            placeholder="Qualification"
                            value={values.qualification}
                            helperText={
                              errors.qualification && touched.qualification
                                ? errors.qualification
                                : ""
                            }
                            error={
                              errors.qualification && touched.qualification
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                            multiline={true}
                            maxRows={2}
                          />
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Qualification (Arabic)
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="qualification_ar"
                            placeholder="Qualification (Arabic)"
                            dir="rtl"
                            value={values.qualification_ar}
                            helperText={
                              errors.qualification_ar &&
                              touched.qualification_ar
                                ? errors.qualification_ar
                                : ""
                            }
                            error={
                              errors.qualification_ar &&
                              touched.qualification_ar
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                            multiline={true}
                            maxRows={2}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Specialty (Eng)
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="specialty"
                            placeholder="Specialty"
                            value={values.specialty}
                            helperText={
                              errors.specialty && touched.specialty
                                ? errors.specialty
                                : ""
                            }
                            error={
                              errors.specialty && touched.specialty
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                            multiline={true}
                            maxRows={2}
                          />
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Specialty (Arabic)
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="specialty_ar"
                            placeholder="Specialty (Arabic)"
                            dir="rtl"
                            value={values.specialty_ar}
                            helperText={
                              errors.specialty_ar && touched.specialty_ar
                                ? errors.specialty_ar
                                : ""
                            }
                            error={
                              errors.specialty_ar && touched.specialty_ar
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                            multiline={true}
                            maxRows={2}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Details (Eng)
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="details"
                            placeholder="Details"
                            value={values.details}
                            helperText={
                              errors.details && touched.details
                                ? errors.details
                                : ""
                            }
                            error={
                              errors.details && touched.details ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                            multiline={true}
                            maxRows={2}
                          />
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Details (Arabic)
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="details_ar"
                            placeholder="Details (Arabic)"
                            dir="rtl"
                            value={values.details_ar}
                            helperText={
                              errors.details_ar && touched.details_ar
                                ? errors.details_ar
                                : ""
                            }
                            error={
                              errors.details_ar && touched.details_ar
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            borderRadius={3}
                            multiline={true}
                            maxRows={2}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          flexWrap: "wrap",
                          marginTop: "20px",
                        }}
                      >
                        <input
                          onChange={(e) => {
                            if (e.target.files) {
                              const newFiles = Array.from(e.target.files);
                              setFieldValue("pictures", [
                                ...values.pictures,
                                ...newFiles,
                              ]);
                              e.target.value = null; // Reset the file input
                            }
                          }}
                          hidden
                          ref={fileRef}
                          type="file"
                          accept="image/*"
                          multiple
                        />

                        <Box
                          onClick={() => fileRef.current.click()}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "130px",
                            height: "115px",
                            justifyContent: "center",
                          }}
                        >
                          <AddPhoto sx={{ fontSize: "25px" }} />
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "12px",
                              color: "#2A3F54",
                            }}
                          >
                            Add Images*
                          </Typography>
                          {errors.pictures && touched.pictures && (
                            <Errors sx={{ paddingLeft: "0" }}>
                              {errors.pictures}
                            </Errors>
                          )}
                        </Box>

                        {/* Showing images */}
                        {values.pictures.map((picture, index) => (
                          <Box
                            key={index}
                            sx={{ position: "relative", margin: "10px" }}
                          >
                            <img
                              src={
                                typeof picture === "string"
                                  ? picture
                                  : URL.createObjectURL(picture)
                              }
                              alt={`user-${index}`}
                              width={100}
                              height={90}
                              style={{
                                borderRadius: "10px",
                                objectFit: "cover",
                              }}
                            />
                            <CancelIcon
                              sx={{
                                fontSize: "20px",
                                position: "absolute",
                                top: "-10px",
                                right: "-9px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const newPictures = values.pictures.filter(
                                  (_, i) => i !== index
                                );
                                setFieldValue("pictures", newPictures);
                              }}
                            />
                          </Box>
                        ))}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "15px",
                          gap: "20px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{
                            height: "40px",
                            width: "150px",
                            mt: "23px",
                            borderRadius: "5px",
                          }}
                          onClick={() =>
                            navigate("/dashboard/service-provider")
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          sx={{
                            height: "40px",
                            width: "150px",
                            mt: "23px",
                            borderRadius: "5px",
                          }}
                        >
                          {loading ? (
                            <>
                              <MoonLoader color="#fff" size={20} />
                            </>
                          ) : id ? (
                            "Update"
                          ) : (
                            "Add"
                          )}
                        </Button>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default AddServiceProvider;
