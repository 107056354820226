import React, { useEffect, useRef, useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Container, Grid, Box, Typography, Button, List, Tooltip } from '@mui/material'

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loader Import
import { MoonLoader } from 'react-spinners';

// Components Import
import Heading from '../../../components/Heading';

// Icons Import
import AddPhoto from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { baseUrl } from '../../../../../constants/api';

const headerData = {
    heading: "Upload your Banners",
    subheading: "Upload banners for your store"
}

function Banners() {
    const token = window.localStorage.getItem("user-token");
    const role = parseInt(window.localStorage.getItem("user-role"));

    const imgUpload = useRef();

    const selectFiles = () => {
        imgUpload.current.click();
    };

    const [uploading, setUploading] = useState(false);
    const [banners, setBanners] = useState([]);
    const [files, setFiles] = useState([]);
    const [previewFiles, setPreviewFiles] = useState([])

    const handleImageUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        const newPreviewFiles = [...previewFiles, ...selectedFiles.map(file => URL.createObjectURL(file))];
        setPreviewFiles(newPreviewFiles);
    };

    const handleRemoveImage = (indexToRemove) => {
        setPreviewFiles((prevPreviewImages) => prevPreviewImages.filter((_, index) => index !== indexToRemove));
    };

    const uploadBanners = () => {
        setUploading(true);
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("images[]", files[i]);
        }

        axios.post(`${baseUrl}/api/banner/create`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
            },
        })
        .then(() => {
            toast.success("Banners added successfully!");
            setTimeout(() => {
                setUploading(false);
            }, 2000);
            setFiles([]);
            setPreviewFiles([])
            fetchBanners();
        })
        .catch((err) => {
            toast.error("Failed to add banners!");
            setUploading(false);
        });
    };

    const fetchBanners = () => {
        axios.get(`${baseUrl}/api/user/banners`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            setBanners(response.data);
        })
        .catch((err) => {
            console.log("Error", err);
        });
    };

    const deleteBanner = (id) => {
        axios.delete(`${baseUrl}/api/banner/delete/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        })
        .then(() => {
            toast.success("Banner has been deleted!");
            fetchBanners();
        })
        .catch((err) => {
            console.log("Error", err);
        });
    };

    useEffect(() => {
        // Clean up object URLs to avoid memory leaks
        return () => {
            files.forEach((file) => URL.revokeObjectURL(file));
        };
    }, [files]);

    useEffect(() => {
        fetchBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Wrapper maxWidth={false}>
                <Heading data={headerData} />
                <Grid container my={5}>
                    <Uploader item md={1} onClick={() => selectFiles()}>
                        <input type="file" accept="image/*" multiple ref={imgUpload} style={{ display: 'none' }} onChange={handleImageUpload} id="imageInput" />
                        <AddPhoto sx={{ fontSize: '25px' }} />
                        <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2A3F54' }}>
                            Add Images
                        </Typography>
                    </Uploader>
                    <Grid item md={12}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {previewFiles.map((file, index) => (
                                <>
                                    <ImgDisplay sx={{ background: `url(${file}) center/cover no-repeat` }} />
                                    <CancelIcon 
                                        sx={{ fontSize: "20px", position: "relative", right: "15px", top: "-10px", cursor: "pointer" }}
                                        onClick={() => handleRemoveImage(index)}
                                    />
                                </>
                            ))}
                        </div>
                    </Grid>
                    <Grid item md={12}>
                        <List>
                            <Text>
                                * File size must be less than 1MB.
                            </Text>
                        </List>
                        {role === 2 ?
                            <List>
                                <Text>
                                    * Banners uploaded here will be displayed on detail screen in Marketplace
                                </Text>
                            </List>
                            : null                        
                        }
                        <List>
                            <Text>
                                * Only JPG (JPEG), PNG and WEBP file formats are permitted for banner uploads.
                            </Text>
                        </List>
                        <List>
                            <Text>
                                * Aim for high-quality images with clear resolution (1200*600) and sharp details. Low-quality images may not render correctly or may appear pixelated when displayed on the marketplace.
                            </Text>
                        </List>
                    </Grid>
                </Grid>
                <Box mt={'50px'} gap={'30px'} width={"100%"} display={"flex"} justifyContent={"flex-end"}>
                    {files.length > 0 && (
                        <Button color="secondary" variant="contained"
                            style={{ textTransform: "none", width: '100px' }}
                            onClick={() => uploadBanners()}
                        >
                            {uploading ? (
                                <>
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Upload"
                            )}
                        </Button>
                    )}
                </Box>
                <Grid container my={5}>
                    {banners.length > 0 ?
                        <>
                            <Grid item md={12}>
                                <Typography sx={{ fontWeight: '600', fontSize: '18px', color: '#2A3F54' }}>
                                    Uploaded Banners
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: "20px" }}>
                                    {banners.map((banner, index) => (
                                        <>
                                            <ImgDisplay sx={{ background: `url(${banner.image}) center/cover no-repeat`, position: "relative" }}>
                                                <Tooltip title="Delete" placement='top'>
                                                    <DeleteIcon sx={{ fontSize: "26px", position: "absolute", right: "8px", top: "8px", cursor: "pointer", background: "#fff", color: "red", borderRadius: "5px" }}
                                                        onClick={() => deleteBanner(banner.bannerID)}
                                                    />
                                                </Tooltip>
                                            </ImgDisplay>
                                        </>
                                    ))}
                                </div>
                            </Grid>
                        </>
                        : null
                    }
                </Grid>
            </Wrapper>
        </>
    );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
    width: "100%",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}))

const Text = styled(Typography)(() => ({
    fontSize: "10px",
    color: "grey"
}))

const Uploader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "90px",
    cursor: "pointer"
}))

const ImgDisplay = styled(Box)(() => ({
    width: '40%',
    height: '200px',
    backgroundColor: '#fff',
    borderRadius: '10px',
}))

export default Banners;
