import { apiMVSlice } from "./apiMVSlice";

export const rfqsApiSlice = apiMVSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRFQs: builder.query({
      query: ({ limit, offset }) => {
        return {
          url: `supplier/rfqs?limit=${limit}&offset=${offset}`,
          method: "GET",
        };
      },
      providesTags: ["Rfqs"],
    }),
    getRFQDetail: builder.query({
      query: (id) => {
        return {
          url: `supplier/rfq/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Rfqs"],
    }),
    updateRFQ: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `supplier/rfq/update/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Rfqs"],
    }),

    getDeliveryNotes: builder.query({
      query: ({ id }) => {
        return {
          url: `delivery/note/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Rfqs"],
    }),

    updateDeliveryNote: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `delivery/note/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Rfqs"],
    }),

    dispatchDeliveryNote: builder.mutation({
      query: ({ id }) => {
        return {
          url: `delivery/note/dispatch/${id}`,
          method: "POST",
          // body,
        };
      },
      invalidatesTags: ["Rfqs"],
    }),
  }),
});

export const {
  useGetRFQsQuery,
  useGetRFQDetailQuery,
  useUpdateRFQMutation,
  useGetDeliveryNotesQuery,
  useUpdateDeliveryNoteMutation,
  useDispatchDeliveryNoteMutation,
} = rfqsApiSlice;
