import * as Yup from "yup";

export const addServiceSchema = Yup.object().shape({
  serviceName: Yup.string().required("Service Name is required"),
  rate: Yup.string().required("Rate is required"),
  chargeType: Yup.object().required("Charge Type is required"),
  category: Yup.object().required("Category is required"),
  serviceType: Yup.array()
    .min(1, "Minimum 1 Service Type is required")
    .nullable(),
  // todo: English and Arabic
  description: Yup.string().required("Description is required"),
  description_ar: Yup.string().required("Arabic Description is required"),
  serviceTerm: Yup.string().required("Service Term is required"),
  serviceTerm_ar: Yup.string().required("Arabic Service Term is required"),
});
