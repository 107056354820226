import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Tooltip,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddOutlined";
import {
  useDeleteServiceMutation,
  useGetServicesQuery,
} from "../../../redux/api/serviceApiSlice";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/MUITable";
import { MoonLoader } from "react-spinners";
// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
// React Toastify
import { toast } from "react-toastify";
import { useGetCategoriesQuery } from "../../../redux/api/categoryApiSlice";
import { useGetChargeTypesQuery } from "../../../redux/api/chargeTypeApiSlice";
import { useGetServiceTypesQuery } from "../../../redux/api/serviceTypeApiSlice";
import Loader from "../../../components/Loaders/Loader";
import { thousandSeparator } from "../../../utils";
import PrimaryInput from "../../../components/PrimaryInput/PrimaryInput";
import SearchIcon from "@mui/icons-material/Search";
import SelectInput from "../../../components/SelectInput";
import Heading from "../components/Heading";

const headerData = {
  heading: "Services List",
  subheading: "",
};

const tableHead = [
  "Service Name",
  "Category",
  "Service Type",
  "Description",
  "Service Terms",
  "Charge Type",
  "Charges",
  "Actions",
];

const Services = () => {
  const navigate = useNavigate();
  const rowsPerPage = 5;

  const [deleteValue, setDeleteValue] = useState("");
  const [page, setPage] = useState(1);
  const [services, setServices] = useState([]);
  // Filter State
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [filters, setFilters] = useState({
    category: "",
    serviceType: "",
  });

  // todo: 1) GET SERVICES API CALL
  const { data: getServices, isLoading: getServicesLoading } =
    useGetServicesQuery({
      limit: rowsPerPage,
      offset: page,
      searchTerm: searchText,
      category: filters.category,
      serviceType: filters.serviceType,
    });

  useEffect(() => {
    if (getServices) {
      setServices(getServices);
    }
  }, [getServices]);

  // todo: GET SERVICE TYPE API CALL
  const { data: getServiceTypes, isLoading: getServiceTypesLoading } =
    useGetServiceTypesQuery({});

  // todo: GET CHARGE TYPE API CALL
  const { data: getChargeTypes, isLoading: getChargeTypeLoading } =
    useGetChargeTypesQuery({});

  // todo: GET CATEGORY  API CALL
  const { data: getCategories, isLoading: getCategoryLoading } =
    useGetCategoriesQuery({});

  // todo: 2) DELETE SERVICE API BIND
  const [deleteService, { isLoading: deleteServiceLoading }] =
    useDeleteServiceMutation();

  const handleDelete = async (id) => {
    try {
      const delService = await deleteService(id);

      if (!delService?.error) {
        toast.success("Service deleted successfully!");
      }
      if (delService?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Delete Service Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const getCategoryName = (id) => {
    const name = getCategories?.categories?.find((item) => item.id === id);
    return name;
  };

  const getServiceTypeName = (typeArray) => {
    const filteredServiceType = getServiceTypes?.filter((type) =>
      typeArray?.includes(type.id)
    );

    return filteredServiceType;
  };

  const getChargeType = (id) => {
    const name = getChargeTypes?.find((item) => item.id === id);
    return name;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const applyHandler = () => {
    setFilters({
      ...filters,
      category: category,
      serviceType: serviceType,
    });
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading data={headerData} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="secondary"
              backgroundcolor={"green"}
              startIcon={<AddIcon />}
              onClick={() => navigate("/dashboard/add-service")}
            >
              Add Service
            </Button>
          </Box>
        </Box>
        {(getServiceTypesLoading ||
          getChargeTypeLoading ||
          getCategoryLoading) && <Loader />}

        <SearchNav>
          <Box
            sx={{
              width: "30vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Service by Name"
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "90%", left: "30px" }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Search>
            {/* <Button onClick={() => searchOrder()}>Search</Button> */}
          </Box>
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Dropdown size="small" fullWidth>
              <InputLabel id="order-range-label">Categories</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                label="Categories"
                value={category || ""}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {getCategories?.categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {`${category.name} - ${category.arb_name}`}
                  </MenuItem>
                ))}
              </Select>
            </Dropdown>
            <Dropdown size="small" fullWidth>
              <InputLabel id="spending-filter-label">Service Type</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                label="Service Type"
                value={serviceType || ""}
                onChange={(e) => {
                  setServiceType(e.target.value);
                }}
              >
                {getServiceTypes?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {`${category.en_service_name} - ${category.ar_service_name}`}
                  </MenuItem>
                ))}
              </Select>
            </Dropdown>
            <Button onClick={() => applyHandler()}>Apply</Button>
            <Button
              onClick={() => {
                setFilters({
                  category: "",
                  serviceType: "",
                });
                setSearchText("");
                setCategory("");
                setServiceType("");
              }}
            >
              Reset
            </Button>
          </Box>
        </SearchNav>

        {/* <Box
					sx={{
						margin: "20px 0",
						padding: "20px",
						background: "#fff",
						borderRadius: "5px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								gap: "10px",
								alignItems: "center",
							}}
						>
							<PrimaryInput
								startAdornment={<SearchIcon />}
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								placeholder="Search Service by Name"
								sx={{
									width: "300px",
								}}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: "10px",
								alignItems: "center",
							}}
						>
							<SelectInput
								name="category"
								styles={{ width: "300px" }}
								value={category || ""}
								onChange={(e) => {
									setCategory(e.target.value);
								}}
								data={getCategories?.categories}
								fetching={getCategoryLoading}
								label="Categories"
								options={getCategories?.categories?.map((category) => {
									return {
										...category,
										id: category.id,
										value: category.id,
										label: `${category.name} - ${category.arb_name}`,
									};
								})}
							></SelectInput>

							<SelectInput
								name="serviceType"
								styles={{ width: "300px" }}
								value={serviceType || ""}
								onChange={(e) => {
									setServiceType(e.target.value);
								}}
								data={getServiceTypes}
								fetching={getServiceTypesLoading}
								label="Service Type"
								options={getServiceTypes?.map((category) => {
									return {
										...category,
										id: category.id,
										value: category.id,
										label: `${category.en_service_name} - ${category.ar_service_name}`,
									};
								})}
							></SelectInput>

							<Button onClick={applyHandler}>Apply</Button>
							<Button
								onClick={() => {
									setFilters({
										category: "",
										serviceType: "",
									});
									setSearchText("");
									setCategory("");
									setServiceType("");
								}}
							>
								Reset
							</Button>
						</Box>
					</Box>
				</Box> */}

        <Box
          sx={{
            margin: "10px 0",
          }}
        >
          <MUITable tableHead={tableHead}>
            {getServicesLoading ? (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "70px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <MoonLoader color="#000" size={20} />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : services?.results?.length > 0 ? (
              services?.results?.map((item, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{item?.serviceName}</StyledTableCell>
                    <StyledTableCell>
                      {`${getCategoryName(item?.category)?.name} - ${
                        getCategoryName(item?.category)?.arb_name
                      }`}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        {getServiceTypeName(item?.serviceType)?.map(
                          (service, index) => {
                            return (
                              <Chip
                                key={index}
                                label={`${service?.en_service_name}`}
                                sx={{
                                  fontSize: "12px",
                                }}
                              />
                            );
                          }
                        )}
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell>
                      {item?.en_description}
                      <br />
                      {item?.ar_description}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.en_serviceTerms}
                      <br />
                      {item?.ar_serviceTerms}
                    </StyledTableCell>
                    <StyledTableCell>
                      {`${getChargeType(item?.chargeType)?.en_charge_name} - ${
                        getChargeType(item?.chargeType)?.ar_charge_name
                      }`}
                    </StyledTableCell>
                    <StyledTableCell>
                      {thousandSeparator(Number(item?.rate))}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Edit" placement="top">
                          <EditIcon
                            sx={{ fontSize: "20px", color: "#07bc0c" }}
                            onClick={() => {
                              navigate(`/dashboard/edit-service/${item.uuid}`);
                            }}
                          />
                        </Tooltip>
                        {deleteServiceLoading &&
                        item.uuid === deleteValue.uuid ? (
                          <MoonLoader color="#000" size={16} />
                        ) : (
                          <Tooltip title="Delete" placement="top">
                            <DeleteIcon
                              sx={{ fontSize: "20px", color: "red" }}
                              onClick={() => {
                                handleDelete(item.uuid);
                                setDeleteValue(item);
                              }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "100px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {services?.results?.length === 0 ? "No records found" : ""}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </MUITable>
          <Box
            sx={{
              padding: "10px",
              background: "#fff",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#49464f",
                }}
              >
                Total Records: {services?.total_count}
              </Box>
              <Pagination
                count={Math.ceil(services?.total_count / rowsPerPage)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Dropdown = styled(FormControl)(() => ({
  width: "100%",
  border: "1px solid #EFEFEF",
  marginTop: "5px",
  backgroundColor: "#fff",
  borderRadius: "6px",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

export default Services;
