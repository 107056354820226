import React from 'react'
import { Button, Grid, Typography } from '@mui/material'

function Services({ removeSection }) {
    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <Typography sx={{fontSize: "16px", fontWeight: "600"}}>
                        Features
                    </Typography>
                    <Typography sx={{fontSize: "12px", fontWeight: "400"}}>
                        This section talks about our features/servies such as 24/7 customer support, fast delivery etc.
                    </Typography>
                </Grid>
                <Grid item md={12} display={"flex"} justifyContent={"end"}>
                    <Button variant='contained' background="primary" sx={{marginTop: "15px", borderRadius: "10px",textTransform: "capitalize"}}
                        onClick={removeSection}
                    >
                        Remove
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default Services