import React from 'react'
import { Button, Grid, TextField, Typography, styled } from '@mui/material'

function Newsletter({ state, handleContentChange, removeSection }) {

    const { engHeading, arbHeading } = state;

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <Typography sx={{fontSize: "16px", fontWeight: "600"}}>
                        Newsletter
                    </Typography>
                    <Typography sx={{fontSize: "12px", fontWeight: "400"}}>
                        This section displays a form for users to subscribe to the newsletter.
                    </Typography>
                </Grid>
                <Grid item md={12} m={3} display={"flex"} justifyContent={"start"} gap={"20px"}>
                    <Grid item md={3.5}>
                        <Label>Section Heading (English)</Label>
                        <Input
                            mt={3}
                            type="text"
                            color="primary"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={engHeading}
                            onChange={(e) => handleContentChange('Newsletter', 'engHeading', e.target.value)}
                        />
                    </Grid>
                    <Grid item md={3.5}>
                        <Label>Section Heading (Arabic)</Label>
                        <Input
                            mt={3}
                            type="text"
                            color="primary"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={arbHeading}
                            onChange={(e) => handleContentChange('Newsletter', 'arbHeading', e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item md={12} display={"flex"} justifyContent={"end"}>
                    <Button variant='contained' background="primary" sx={{marginTop: "15px", borderRadius: "10px",textTransform: "capitalize"}}
                        onClick={removeSection}
                    >
                        Remove
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const Input = styled(TextField)({
    width: "100%",
    background: "#fff",
    borderRadius: "5px",
});
  
  const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500",
}));

export default Newsletter