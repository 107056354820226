import { apiSlice } from "./apiSlice";

export const cmsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHeader: builder.query({
      query: () => {
        return {
          url: "get/header",
          method: "GET",
        };
      },
      // providesTags: ["Blog"],
    }),
    getFeatures: builder.query({
      query: () => {
        return {
          url: "feature",
          method: "GET",
        };
      },
      providesTags: ["Feature"],
    }),

    deleteFeature: builder.mutation({
      query: (id) => {
        return {
          url: `feature/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Feature"],
    }),

    getStyles: builder.query({
      query: () => {
        return {
          url: "get/styles",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetHeaderQuery,
  useGetFeaturesQuery,
  useDeleteFeatureMutation,
  useGetStylesQuery,
} = cmsApiSlice;
