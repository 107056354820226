import { apiSlice } from "./apiSlice";

export const serviceProductsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceProducts: builder.query({
      query: () => {
        return {
          url: "service-products",
          method: "GET",
        };
      },
      providesTags: ["ServiceProducts"],
    }),

    getServiceProduct: builder.query({
      query: (id) => {
        return {
          url: `service-product/${id}`,
          method: "GET",
        };
      },
      providesTags: ["ServiceProducts"],
    }),

    deleteServiceProduct: builder.mutation({
      query: (id) => {
        return {
          url: `service-product/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ServiceProducts"],
    }),
  }),
});

export const {
  useGetServiceProductsQuery,
  useGetServiceProductQuery,
  useDeleteServiceProductMutation,
} = serviceProductsApiSlice;
