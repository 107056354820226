import React, { useEffect, useState } from "react";
import Heading from "../../../components/Heading";

// MUI Components import
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
} from "@mui/material";

// Icons Import
import Loader from "../../../../../components/Loaders/Loader";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/MUITable";
import { useParams } from "react-router-dom";
import { useGetRFQDetailQuery } from "../../../../../redux/api/rfqsApiSlice";
import RFQInputField from "./Component/RFQInputField";
import CustomChip from "../../../../../components/CustomChip";

const headerData = {
  heading: "RFQ Detail",
  subheading: "",
};

function RfqDetail() {
  const { rfq_id } = useParams();

  const [rfqDetail, setRFQDetail] = useState();

  // todo: GET SINGLE PRODUCT VARIANTS API BIND
  const { data, isLoading } = useGetRFQDetailQuery(rfq_id);

  useEffect(() => {
    if (data) {
      setRFQDetail(data);
    }
  }, [data]);

  const tableHead = [
    "#",
    "Product Image",
    "Product SKU",
    "Product Name",
    "Product Quantity",
    "Price",
    "Delivery Location",
    "Delivery Date",
    "Notes",
    "Response Notes",
  ];

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Heading data={headerData} />
        </Box>
        <RfqNavWrapper>
          <Grid
            container
            my={1}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid
              item
              md={12}
              display={"flex"}
              justifyContent={"start"}
              gap={"15px"}
            >
              <Typography>RFQ Staus:</Typography>
              <Typography>
                {<CustomChip label={rfqDetail?.status_display} />}
              </Typography>
            </Grid>
            <RFQInputField
              label="RFQ ID"
              size={2.8}
              value={rfqDetail?.rfqId}
              disabled={true}
            />
            <RFQInputField
              label="RFQ Date:"
              size={2.8}
              value={new Date(rfqDetail?.rfqDate).toLocaleString()}
              disabled={true}
            />
            <RFQInputField
              label="RFQ Closing Date:"
              size={2.8}
              value={new Date(rfqDetail?.rfqCloseDate).toLocaleString()}
              disabled={true}
            />
            <RFQInputField
              label="Delivery Location:"
              size={2.8}
              value={rfqDetail?.deliveryLocation}
              disabled={true}
            />
            <RFQInputField
              label="Payment Terms:"
              size={3.8}
              value={rfqDetail?.paymentTerms}
              disabled={true}
            />
            <RFQInputField
              label="Terms and Conditions:"
              size={3.8}
              value={rfqDetail?.termsAndConditions}
              disabled={true}
            />
            <RFQInputField
              label="Notes:"
              size={3.8}
              value={rfqDetail?.notes}
              disabled={true}
            />
            <Grid item md={3.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Response Payment Terms:
              </Typography>
              <StyledTextarea
                value={rfqDetail?.user?.responsePaymentTerms}
                disabled={true}
              />
            </Grid>
            <Grid item md={3.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Response Terms and Conditions:
              </Typography>
              <StyledTextarea
                value={rfqDetail?.user?.responseTermsAndConditions}
                disabled={true}
              />
            </Grid>
            <Grid item md={3.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Response Notes:
              </Typography>
              <StyledTextarea
                value={rfqDetail?.user?.responseNotes}
                disabled={true}
              />
            </Grid>
          </Grid>
        </RfqNavWrapper>
        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead}>
              {rfqDetail?.products.length > 0 ? (
                rfqDetail?.products?.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          alt="product_image"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "5px",
                            marginRight: "8px",
                            objectFit: "fill",
                          }}
                        >
                          <img
                            src={row?.product?.images[0].image}
                            alt="Product"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{row?.product?.skU}</StyledTableCell>
                      <StyledTableCell>{row?.product?.commons?.en?.productName}</StyledTableCell>
                      <StyledTableCell>{row?.quantity}</StyledTableCell>
                      <StyledTableCell>
                        {parseFloat(row?.price || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        SAR
                      </StyledTableCell>
                      <StyledTableCell>{row?.deliveryLocation}</StyledTableCell>
                      <StyledTableCell>
                        {new Date(row?.deliveryDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>{row?.notes}</StyledTableCell>
                      <StyledTableCell>
                        <StyledTextarea value={row?.responseNotes} />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {rfqDetail?.products?.length === 0
                        ? "No records found"
                        : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
          </Box>
          <ButtonWrapper container>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              Total Estimated Price (SAR):
            </Typography>
            <Typography
              sx={{ fontSize: "18px", fontWeight: "800", paddingLeft: "10px" }}
            >
              {parseFloat(rfqDetail?.total_estimated_price || 0).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </Typography>
          </ButtonWrapper>
        </Box>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const RfqNavWrapper = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  margin: "10px 0",
  gap: "20px",
}));

const StyledTextarea = styled("textarea")(() => ({
  background: "#d9d9d9",
  borderRadius: "8px",
  fontSize: "16px",
  margin: "8px 0",
  height: "80px",
  width: "100%",
  border: "1px solid #C9CFD2",
}));

const ButtonWrapper = styled(Grid)(() => ({
  width: "100%",
  backgroundColor: "#fff",
  padding: "20px",
  display: "flex",
  alignItems: "center",
}));

export default RfqDetail;
