import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Roles Context
import { useUserRoleContext } from "./contexts/RoleContext.jsx";

// User Theme
import UserTheme from "./UserTheme";

// Auth Guard
import AuthGuard from "./Auth";

// Auth views
import Signin from "./views/MV/Auth/Signin.jsx";
import Signup from "./views/MV/Auth/Signup.jsx";
import ForgotPassword from "./views/MV/Auth/ForgotPassword.jsx";
import ResetPassword from "./views/MV/Auth/ResetPassword.jsx";

import LandingPage from "./views/MV/LandingPage.jsx"; /* Wrapper for Sidebar & Dashboard views */

// Admin Management views
import AdminDashboard from "./views/MV/Admin/Screens/Dashboard.jsx";

// Inventory Management views
import InventoryDashboard from "./views/MV/Vendor/Screens/Inventory/InventoryDashboard.jsx";
import BulkHandling from "./views/MV/Vendor/Screens/Inventory/BulkHandling.jsx"; /* Upload/Download XSLX Page */
import ProductList from "./views/MV/Vendor/Screens/Inventory/ProductList"; /* Products Listing Page */
import AddProduct from "./views/MV/Vendor/Screens/Inventory/AddProduct"; /* Add Product Form */
import EditProduct from "./views/MV/Vendor/Screens/Inventory/EditProduct"; /* Add Product Form */
import Reviews from "./views/MV/Vendor/Screens/Inventory/Reviews"; /* Manage Product reviews */

// Order Management Views
import OrdersDashboard from "./views/MV/Vendor/Screens/Orders/OrdersDashboard.jsx";
import OrdersList from "./views/MV/Vendor/Screens/Orders/OrdersList.jsx"; /* Show orders page */
import OrderDetail from "./views/MV/Vendor/Screens/Orders/OrderDetail"; /* Single Order Detail page */

// Customer Management Views
import CustomersDashboard from "./views/MV/Vendor/Screens/Customers/CustomersDashboard.jsx";
import CustomerList from "./views/MV/Vendor/Screens/Customers/CustomerList.jsx";
import CustomerOrder from "./views/MV/Vendor/Screens/Customers/CustomerOrder.jsx";

// Vendors Management Views
import VendorsDashboard from "./views/MV/Admin/Screens/Vendors/VendorsDashboard.jsx";
import VendorsList from "./views/MV/Admin/Screens/Vendors/VendorsList.jsx";

// RFQ Management Views
import RfqDashboard from "./views/MV/Vendor/Screens/RFQ/RfqDashboard.jsx";
import RfqList from "./views/MV/Vendor/Screens/RFQ/RfqList.jsx";
import RfqDetail from "./views/MV/Vendor/Screens/RFQ/RfqDetail.jsx";
import RfqEdit from "./views/MV/Vendor/Screens/RFQ/RfqEdit.jsx";
import RFQDeliveryNote from "./views/MV/Vendor/Screens/RFQ/RFQDeliveryNote.jsx";

// Controls views
import ControlsDashboard from "./views/MV/Vendor/Screens/Controls/ControlsDashboard.jsx";
import Brands from "./views/MV/Vendor/Screens/Controls/Brands.jsx";
import Models from "./views/MV/Vendor/Screens/Controls/Models";
import Categories from "./views/MV/Vendor/Screens/Controls/Categories";
import Subcategories from "./views/MV/Vendor/Screens/Controls/Subcategories";
import Warehouses from "./views/MV/Vendor/Screens/Controls/Warehouses";
import Units from "./views/MV/Vendor/Screens/Controls/Units.jsx";
import Variants from "./views/MV/Vendor/Screens/Controls/Variants";
import Testimonials from "./views/MV/Vendor/Screens/Controls/Testimonials";
import ServiceType from "./views/MV/Vendor/Screens/Controls/ServiceType.jsx";
import ProviderType from "./views/MV/Vendor/Screens/Controls/ProviderType";
import ChargeType from "./views/MV/Vendor/Screens/Controls/ChargeType.jsx";

// Profile views
import Profile from "./views/MV/Profile/Profile.jsx";

// Settings views
import SettingsDashboard from "./views/MV/Vendor/Screens/Settings/SettingsDashboard.jsx";
import Banners from "./views/MV/Vendor/Screens/Settings/Banners.jsx";
import Configurations from "./views/MV/Vendor/Screens/Settings/Configurations/Configurations.jsx";
import ThemeSettings from "./views/MV/Vendor/Screens/Settings/ThemeSettings.jsx";

// Services Views
import Services from "./views/MV/Services";
import AddService from "./views/MV/Services/components/AddService";
import ServiceProviders from "./views/MV/ServiceProviders";
import ServiceProvidersList from "./views/MV/ServiceProviders/components/ServiceProvidersList";
import AddServiceProvider from "./views/MV/ServiceProviders/components/AddServiceProvider";

// CMS Views
import CMS from "./views/MV/Admin/CMS/index.jsx";
import Home from "./views/MV/Admin/CMS/Pages/Home/Home.jsx";
import Header from "./views/MV/Admin/CMS/Pages/Header.jsx";
import Footer from "./views/MV/Admin/CMS/Pages/Footer/Footer.jsx";
import StaticPages from "./views/MV/Admin/CMS/Pages/StaticPages.jsx";
import Blogs from "./views/MV/Admin/CMS/Pages/Blogs.jsx";
import Styling from "./views/MV/Admin/CMS/Pages/Styling.jsx";
import BrandPartners from "./views/MV/Admin/CMS/Pages/BrandPartners.jsx";
import Features from "./views/MV/Admin/CMS/Pages/Features.jsx";

const VendorRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Signin />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <UserTheme>
          <LandingPage />
        </UserTheme>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <InventoryDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "products-list",
            element: (
              <AuthGuard>
                <ProductList />
              </AuthGuard>
            ),
          },
          {
            path: "add-product",
            element: (
              <AuthGuard>
                <AddProduct />
              </AuthGuard>
            ),
          },
          {
            path: "edit-product/:prod_id",
            element: (
              <AuthGuard>
                <AddProduct />
                {/* <EditProduct /> */}
              </AuthGuard>
            ),
          },
          // Services
          {
            path: "services",
            element: (
              <AuthGuard>
                <Services />
              </AuthGuard>
            ),
          },
          {
            path: "add-service",
            element: (
              <AuthGuard>
                <AddService />
              </AuthGuard>
            ),
          },
          {
            path: "edit-service/:id",
            element: (
              <AuthGuard>
                <AddService />
              </AuthGuard>
            ),
          },
          {
            path: "bulk",
            element: (
              <AuthGuard>
                <BulkHandling />
              </AuthGuard>
            ),
          },
          {
            path: "reviews",
            element: (
              <AuthGuard>
                <Reviews />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "rfqs",
        element: (
          <AuthGuard>
            <RfqDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "rfq-list",
            element: (
              <AuthGuard>
                <RfqList />
              </AuthGuard>
            ),
          },
          {
            path: "rfq-detail/:rfq_id",
            element: (
              <AuthGuard>
                <RfqDetail />
              </AuthGuard>
            ),
          },
          {
            path: "rfq-edit/:rfq_id",
            element: (
              <AuthGuard>
                <RfqEdit />
              </AuthGuard>
            ),
          },
          {
            path: "delivery-note/:rfq_id",
            element: (
              <AuthGuard>
                <RFQDeliveryNote />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "orders",
        element: (
          <AuthGuard>
            <OrdersDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "orders-list",
            element: (
              <AuthGuard>
                <OrdersList />
              </AuthGuard>
            ),
          },
          {
            path: "order-detail/:order_id",
            element: (
              <AuthGuard>
                <OrderDetail />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "customers",
        element: (
          <AuthGuard>
            <CustomersDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "customer-list",
            element: (
              <AuthGuard>
                <CustomerList />
              </AuthGuard>
            ),
          },
          {
            path: "customer-order/:customer_id",
            element: (
              <AuthGuard>
                <CustomerOrder />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "controls",
        element: (
          <AuthGuard>
            <ControlsDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "brands",
            element: (
              <AuthGuard>
                <Brands />
              </AuthGuard>
            ),
          },
          {
            path: "models",
            element: (
              <AuthGuard>
                <Models />
              </AuthGuard>
            ),
          },
          {
            path: "categories",
            element: (
              <AuthGuard>
                <Categories />
              </AuthGuard>
            ),
          },
          {
            path: "subcategories",
            element: (
              <AuthGuard>
                <Subcategories />
              </AuthGuard>
            ),
          },
          {
            path: "warehouses",
            element: (
              <AuthGuard>
                <Warehouses />
              </AuthGuard>
            ),
          },
          {
            path: "units",
            element: (
              <AuthGuard>
                <Units />
              </AuthGuard>
            ),
          },
          {
            path: "variants",
            element: (
              <AuthGuard>
                <Variants />
              </AuthGuard>
            ),
          },
          {
            path: "testimonials",
            element: (
              <AuthGuard>
                <Testimonials />
              </AuthGuard>
            ),
          },
          {
            path: "service-type",
            element: (
              <AuthGuard>
                <ServiceType />
              </AuthGuard>
            ),
          },
          {
            path: "provider-type",
            element: (
              <AuthGuard>
                <ProviderType />
              </AuthGuard>
            ),
          },
          {
            path: "charge-type",
            element: (
              <AuthGuard>
                <ChargeType />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "settings",
        element: (
          <AuthGuard>
            <SettingsDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "banners",
            element: (
              <AuthGuard>
                <Banners />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "profile",
        element: (
          <AuthGuard>
            <Profile />
          </AuthGuard>
        ),
      },
      // Service Provider
      {
        path: "service-provider",
        element: (
          <AuthGuard>
            <ServiceProviders />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: (
              <AuthGuard>
                <ServiceProvidersList />
              </AuthGuard>
            ),
          },
          {
            path: "add",
            element: (
              <AuthGuard>
                <AddServiceProvider />
              </AuthGuard>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <AuthGuard>
                <AddServiceProvider />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
]);

const AdminRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Signin />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <UserTheme>
          <LandingPage />
        </UserTheme>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <AdminDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "vendors",
        element: (
          <AuthGuard>
            <VendorsDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "vendors-list",
            element: (
              <AuthGuard>
                <VendorsList />
              </AuthGuard>
            ),
          },
        ],
      },
      // CMS
      {
        path: "cms",
        element: (
          <AuthGuard>
            <CMS />
          </AuthGuard>
        ),
        children: [
          {
            path: "home",
            element: (
              <AuthGuard>
                <Home />
              </AuthGuard>
            ),
          },
          {
            path: "header",
            element: (
              <AuthGuard>
                <Header />
              </AuthGuard>
            ),
          },
          {
            path: "footer",
            element: (
              <AuthGuard>
                <Footer />
              </AuthGuard>
            ),
          },
          {
            path: "static-pages",
            element: (
              <AuthGuard>
                <StaticPages />
              </AuthGuard>
            ),
          },
          {
            path: "features",
            element: (
              <AuthGuard>
                <Features />
              </AuthGuard>
            ),
          },
          {
            path: "blogs",
            element: (
              <AuthGuard>
                <Blogs />
              </AuthGuard>
            ),
          },
          {
            path: "styling",
            element: (
              <AuthGuard>
                <Styling />
              </AuthGuard>
            ),
          },
          {
            path: "partners",
            element: (
              <AuthGuard>
                <BrandPartners />
              </AuthGuard>
            ),
          },
        ],
      },
      // Settings
      {
        path: "settings",
        element: (
          <AuthGuard>
            <SettingsDashboard />
          </AuthGuard>
        ),
        children: [
          {
            path: "banners",
            element: (
              <AuthGuard>
                <Banners />
              </AuthGuard>
            ),
          },
          {
            path: "config",
            element: (
              <AuthGuard>
                <Configurations />
              </AuthGuard>
            ),
          },
          {
            path: "theme",
            element: (
              <AuthGuard>
                <ThemeSettings />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "profile",
        element: (
          <AuthGuard>
            <Profile />
          </AuthGuard>
        ),
      },
    ],
  },
]);

const RootComponent = () => {
  const { role } = useUserRoleContext();

  return (
    <>
      {role === 3 ? (
        <RouterProvider router={AdminRoutes} />
      ) : (
        <RouterProvider router={VendorRoutes} />
      )}
    </>
  );
};

export default RootComponent;
