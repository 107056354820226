import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// MUI Component Import
import { Box, Grid, Typography, Button, Modal } from "@mui/material";

// Loader Import
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons Import
import UploadIcon from "@mui/icons-material/UploadFile";
import { baseUrl } from "../../../../../constants/api";
import UploadCSVModal from "../Modals/UploadCSVModal";

function UploadCSVCard({ fetchFiles }) {
  const [files, setFiles] = useState(null);
  const [suggestions, setSuggestions] = useState("");
  const [uploading, setUploading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState();

  const inpUpload = useRef();

  const selectFile = () => {
    inpUpload.current.click();
  };

  async function uploadCSV(csvFile, filename) {
    const csvEndpoint = `${baseUrl}/api/xlsx/upload`; // Replace with your API endpoint
    const token = window.localStorage.getItem("user-token");

    try {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("xlsx_file", csvFile, filename); // 'formFile' should match the server-side field name

      // Set headers, including Authorization
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      };

      // Send the POST request using axios
      const response = await axios.post(csvEndpoint, formData, { headers });

      if (response.status === 201) {
        return { status: true, message: response.data.message };
      } else {
        return { status: false, message: response.data.message };
      }
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }

  async function sendFile() {
    setUploading(true);
    const success = await uploadCSV(files, files.name);
    if (success.status) {
      toast.success(success.message);
      setFiles(null);
      fetchFiles();
      // setModalOpen(false)
    } else {
      toast.error(success.message);
      // setModalOpen(false)
      setUploading(false);
    }
  }

  return (
    <>
      <Card item md={3.3}>
        <UploadCSVModal
          open={uploadCSVModalOpen}
          setOpen={setUploadCSVModalOpen}
          fetchFiles={fetchFiles}
        />

        <Title onClick={() => setUploadCSVModalOpen(true)}>
          Upload XLSX File
        </Title>
        <IconWrapper onClick={() => setUploadCSVModalOpen(true)}>
          <UploadIcon sx={{ fontSize: "90px", color: "#400969" }} />
          <input
            type="file"
            accept=".csv"
            ref={inpUpload}
            style={{ display: "none" }}
            onChange={(e) => {
              const selectedCsv = e.target.files[0];
              if (selectedCsv) {
                setFiles(selectedCsv);
              }
            }}
          />
        </IconWrapper>
      </Card>
      <Card item md={3.3} sx={{ background: "#fff" }}>
        {files ? (
          <>
            <Typography variant="h6">Selected File:</Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>{files.name}</li>
            </ul>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendFile()}
              style={{ marginTop: "20px" }}
            >
              {uploading ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : (
                "Upload File"
              )}
            </Button>
          </>
        ) : null}
      </Card>
      <Modal
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapper>
          <Box sx={{ maxHeight: "500px", overflow: "auto" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <span style={{ whiteSpace: "pre-line", fontSize: "10px" }}>
                {suggestions}
              </span>
            </Box>
            <Box
              mt={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                gap: "12px",
              }}
            >
              <Button
                onClick={() => sendFile()}
                variant="contained"
                color="error"
              >
                Skip
              </Button>
              <Button onClick={() => sendFile()} variant="contained">
                Incorporate AI
              </Button>
            </Box>
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  );
}

// Styled Components

const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#400969",
  borderRadius: "10px",
}));

const Title = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#fff",
}));

const IconWrapper = styled(Box)(() => ({
  width: "80%",
  height: "60%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "20px",
  cursor: "pointer",
}));

const ModalWrapper = styled(Box)(({ theme }) => ({
  width: "auto",
  // maxHeight: "600px",
  // overflow:"auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "50px",
  backgroundColor: "white",
  boxShadow: 24,
  border: "none",
  outline: "none",
  borderRadius: "32px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default UploadCSVCard;
