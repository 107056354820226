import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../../../../constants/api";
import axios from "axios";

// MUI Components Import
import { Container, Box, Button } from "@mui/material";

// Components Import
import Navbar from "../../../components/Navbar";
import Heading from "../../../components/Heading";
import DashboardCards from "../../components/Cards/DashboardCards";
import ReportCards from "../../components/Cards/ReportCards";

// Assets Import
import iconOne from "../../../../../assets/dash-card-icon-one.webp";
import iconTwo from "../../../../../assets/dash-card-icon-two.webp";
import iconThree from "../../../../../assets/dash-card-icon-three.webp";

// MUI Icons Import
import UploadIcon from "@mui/icons-material/FileUploadOutlined";
import ForecastCharts from "../../components/ForecastCharts";

const headerData = {
  heading: "Inventory Management",
  subheading: "",
};

function InventoryDashboard() {
  const location = useLocation();
  const current_loc = location.pathname.split("/").pop();

  const token = window.localStorage.getItem("user-token");

  const navigate = useNavigate();

  const [cardData, setCardData] = useState([]);

  const fetchSummaryCounts = async () => {
    await axios
      .get(`${baseUrl}/api/products/counts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setCardData([
          {
            backgroundColor: "#812990",
            icon: iconOne,
            heading: "Total Products",
            number: response.data.total_products,
          },
          {
            backgroundColor: "#812990",
            icon: iconTwo,
            heading: "New Products",
            number: response.data.newly_added_products_today,
          },
          {
            backgroundColor: "#812990",
            icon: iconThree,
            heading: "Out of Stock",
            number: response.data.out_of_stock_products,
          },
        ]);
      })
      .catch((err) => {
        console.error("Error fetching demand data:", err);
      });
  };

  useEffect(() => {
    fetchSummaryCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MainWrapper maxWidth={false}>
        <Navbar />
        {current_loc === "dashboard" ? (
          <Wrapper maxWidth={false}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Heading data={headerData} />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<UploadIcon />}
                onClick={() => navigate("bulk")}
              >
                Upload
              </Button>
            </Box>
            <DashboardCards data={cardData} />
            <ForecastCharts />
            <ReportCards />
          </Wrapper>
        ) : (
          <Outlet />
        )}
      </MainWrapper>
    </>
  );
}

const MainWrapper = styled(Container)(() => ({
  padding: "0 !important",
  width: "100%",
  height: "100%",
  overflow: "auto",
}));

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default InventoryDashboard;
