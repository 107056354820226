import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// MUI Components Import
import { Grid, Box, Typography, FormControl, InputLabel, Select, MenuItem} from '@mui/material'

// Recharts Components Import
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import ChartPlaceholder from './ChartPlaceholder';
import { baseUrl } from '../../../../constants/api';
import Loader from '../../../../components/Loaders/Loader';
import { useGetProductsQuery } from '../../../../redux/api/productsApiSlice';
import { PRODUCT_PAGINATION_LIMIT } from '../../../../constants/constant';

const ForecastCharts = () => {
	const navigate = useNavigate()
    const token = localStorage.getItem("user-token");

    const page = 1;
    const [loading, setLoading] = useState(false)
    const [demandData, setDemandData] = useState(null)
    const [salesData, setSalesData] = useState(null)
    const [product, setProduct] = useState(null)
    const [products, setProducts] = useState([])

    const fetchDemandData = (prodId) => {
        setLoading(true)
        axios.get(`${baseUrl}/api/demand-forecast?prodId=${prodId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            setDemandData(response.data)
            setLoading(false)
        })
        .catch((err) => {
            console.error('Error fetching demand data:', err);
            setLoading(false)
        })
    }

    const fetchSalesData = (prodId) => {
        setLoading(true)
        axios.get(`${baseUrl}/api/sales-forecast?prodId=${prodId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                  },    
            }
        )
        .then((response) => {
            setSalesData(response.data)
            setLoading(false)
        })
        .catch((err) => {
            console.error('Error fetching sales data:', err);
            setLoading(false)
        })
    }

    const { data } = useGetProductsQuery({
        limit: PRODUCT_PAGINATION_LIMIT,
        offset: page,
      });

      useEffect(() => {
        if (data && data.results.length > 0) {
          setProducts(data.results);
          const firstProduct = data.results[0];
          setProduct(firstProduct.prodId);
          fetchDemandData(firstProduct.prodId);
          fetchSalesData(firstProduct.prodId);
        }
      }, [data, page]);

    const handleProductChange = (e) => {
        const prodId = e.target.value;
        setProduct(prodId);
        fetchDemandData(prodId);
        fetchSalesData(prodId);
    }

	return (
		<>
			<Card container my={3}>
                <Grid item md={12} sx={{display: "flex", justifyContent: "space-between"}}>
                    <Typography>
                        Sales & Demand Forecast
                    </Typography>
                    <Dropdown size="small" sx={{width: "30%"}}>
                        <InputLabel id="order-range-label">Products</InputLabel>
                        <Select
                            sx={{ borderRadius: "6px", border: "none",  }}
                            label="Products"
                            value={product || ""}
                            onChange={handleProductChange}
                        >
                            {products?.map((item) => (
                            <MenuItem key={item.prodId} value={item.prodId}>
                                {`${item.commons.en.productName} - ${item.commons.ar.productName}`}
                            </MenuItem>
                            ))}
                        </Select>
                    </Dropdown>
                </Grid>
				<Grid item md={12}>
                    <CardHeading>Sales Forecast</CardHeading>
                    { loading ?
                        <Loader />
                        :
                        salesData? 
                        <Box sx={{height:"220px", cursor: "pointer"}} onClick={() => navigate('sales-forecast')}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={salesData}>
                                    <XAxis dataKey="name" tick={{fontSize: "10px"}} />
                                    <YAxis tick={{fontSize: "10px"}} />
                                    <Line dot={false} strokeWidth={2}  type="monotone" dataKey="counts" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>                        
                        </Box>
                        :
                        <ChartPlaceholder />
                    }
                </Grid>
                <Grid item md={12}>
                    <CardHeading>Demand Forecast</CardHeading>
                    {loading ? 
                        <Loader />
                        :
                        demandData ? 
                        <Box sx={{height:"220px", cursor: "pointer"}} onClick={() => navigate('demand-forecast')}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={demandData}>
                                    <XAxis dataKey="name" tick={{fontSize: "10px"}} />
                                    <YAxis tick={{fontSize: "10px"}} />
                                    <Line dot={false} strokeWidth={2}  type="monotone" dataKey="counts" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>                        
                        </Box>
                        :
                        <ChartPlaceholder />
                    }
                </Grid>
            </Card>
		</>
	);
};

// Styled Components
const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    background: "#fff",
    padding: "15px 25px",
    borderRadius: "14px",
    border: "1px solid #ddd"
}))

const CardHeading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600"
}))

const Dropdown = styled(FormControl)(() => ({
    width: "100%",
    border: "1px solid #EFEFEF",
    marginTop: "5px",
    backgroundColor: "#fff",
    borderRadius: "6px",
  }));

export default ForecastCharts;
