// React Imports
import React, { useState } from "react";
// React Toastify
import { toast } from "react-toastify";
// React Spinners
import { MoonLoader } from "react-spinners";
// MUI
import { Box, Button, Container, Grid, TextField, Tooltip, Typography, styled } from "@mui/material";
// Utils
import { onKeyDown } from "../../../../../utils";
// Formik
import { Form, Formik } from "formik";
// Yup
import * as Yup from "yup";
// Redux
import {
  useGetChargeTypesQuery,
  useCreateChargeTypeMutation,
  useUpdateChargeTypeMutation,
  useDeleteChargeTypeMutation,
} from "../../../../../redux/api/chargeTypeApiSlice";
// Custom
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/MUITable";
// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import Heading from "../../../components/Heading";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

const addDoctorSchema = Yup.object().shape({
  charge: Yup.string().required("Charge Type is required"),
  charge_ar: Yup.string().required("Arabic Charge Type is required"),
});

const tableHead = ["#", "Charge Type (Eng)", "Charge Type (Arabic)", "Actions"];
const headerData = {
  heading: "Configure Charge Types",
  subheading: "",
}

const ChargeType = () => {
  const [formValues, setFormValues] = useState({
    charge: "",
    charge_ar: "",
  });
  const [editValue, setEditValue] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [uuid, setUUID] = useState("");

  // todo: GET CHARGE TYPE API CALL
  const { data: getChargeTypes, isLoading: getChargeTypeLoading } =
    useGetChargeTypesQuery({});

  // todo: CREATE CHARGE TYPE API BIND
  const [createChargeType, { isLoading }] = useCreateChargeTypeMutation();

  // todo: UPDATE CHARGE TYPE API BIND
  const [updateChargeType, { isLoading: updateChargeTypeLoading }] =
    useUpdateChargeTypeMutation();

  const AddChargeTypeHandler = async (data, resetForm) => {
    const payload = {
      en_charge_name: data.charge,
      ar_charge_name: data.charge_ar,
    };

    // todo: UPDATE CHARGE TYPE
    if (editValue) {
      try {
        const update = await updateChargeType({
          body: payload,
          id: editValue?.uuid,
        });

        if (!update?.error) {
          toast.success("Charge Type updated successfully!");
          resetForm();
          setEditValue("");
          setFormValues({
            ...formValues,
            charge: "",
            charge_ar: "",
          });
        }
        if (update?.error) {
          toast.error("Something went wrong", "error");
        }
      } catch (error) {
        console.error("Update Charge Type Error:", error);
        toast.error(error.response.data.message);
      }

      return;
    }

    try {
      const create = await createChargeType(payload);

      console.log("create", create);

      if (!create?.error) {
        toast.success("Charge Type created successfully!");
        resetForm();
      }

      if (create?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Create Charge Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  // todo: DELETE VARIANT API BIND
  const [deleteChargeType, { isLoading: deleteVariantLoading }] =
    useDeleteChargeTypeMutation();

  const handleDelete = async (id) => {
    try {
      const deleteCharge = await deleteChargeType(id);

      if (!deleteCharge?.error) {
        toast.success("Charge Type deleted successfully!");
      }
      if (deleteCharge?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Delete Charge Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const openConfirmationModal = (id) => {
    setUUID(id);
    setConfirmationModal(true);
  };

  return (
    <>
      {uuid ? (
        <ConfirmationModal
          prodId={uuid}
          open={confirmationModal}
          setOpen={setConfirmationModal}
          heading={"Confirm Charge Deletion"}
          text={"Are you sure you want to delete the Charge?"}
          handleDeleteProduct={handleDelete}
        />
      ) : null}

      <Wrapper maxWidth={false} >
        <Heading data={headerData} />
        <ControlWrapper mt={3}>
          <ControlHeading>Create a Charge</ControlHeading>

          <Formik
            initialValues={formValues}
            onSubmit={(values, { resetForm }) => {
              AddChargeTypeHandler(values, resetForm);
            }}
            validationSchema={addDoctorSchema}
            enableReinitialize
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange } =
                props;

              return (
                <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                  <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
                    <Grid item md={4}>
                      <Label>Charge Name (Eng)</Label>
                      <Input
                        type="text"
                        label=""
                        name="charge"
                        value={values.charge}
                        helperText={
                          errors.charge_ar && touched.charge_ar
                            ? errors.charge_ar
                            : ""
                        }
                        error={
                          errors.charge_ar && touched.charge_ar ? true : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Label>Charge Name (Arabic)</Label>
                      <Input
                        type="text"
                        label=""
                        name="charge_ar"
                        value={values.charge_ar}
                        helperText={
                          errors.charge_ar && touched.charge_ar
                            ? errors.charge_ar
                            : ""
                        }
                        error={
                          errors.charge_ar && touched.charge_ar ? true : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item md={4} display={"flex"} justifyContent={"end"}>
                      <SaveBTN type="submit">
                        {isLoading || updateChargeTypeLoading ? (
                          <>
                            <MoonLoader color="#fff" size={20} />
                          </>
                        ) : editValue ? (
                          "Update"
                        ) : (
                          "Add"
                        )}
                      </SaveBTN>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

        </ControlWrapper >

        {/* Table */}
        <Box
          sx={{
            margin: "10px 0",
          }}>
          <MUITable tableHead={tableHead} background="#f3f4f5">
            {getChargeTypeLoading ? (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "70px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <MoonLoader color="#000" size={20} />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : getChargeTypes?.length > 0 ? (
              getChargeTypes?.map((item, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{index + 1}</StyledTableCell>

                    <StyledTableCell>{item?.en_charge_name}</StyledTableCell>
                    <StyledTableCell>{item?.ar_charge_name}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Edit" placement="top">
                          <EditIcon
                            sx={{ fontSize: "20px", color: "#07bc0c" }}
                            onClick={() => {
                              setFormValues({
                                ...formValues,
                                charge: item?.en_charge_name,
                                charge_ar: item?.ar_charge_name,
                              });
                              setEditValue(item);
                            }}
                          />
                        </Tooltip>
                        {deleteVariantLoading &&
                          item.uuid === deleteValue.uuid ? (
                          <MoonLoader color="#000" size={16} />
                        ) : (
                          <Tooltip title="Delete" placement="top">
                            <DeleteIcon
                              sx={{ fontSize: "20px", color: "red" }}
                              onClick={() => {
                                openConfirmationModal(item?.uuid);
                                setDeleteValue(item);
                              }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "100px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {getChargeTypes?.length === 0 ? "No records found" : ""}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </MUITable>
        </Box>

      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: '1rem',
  height: "calc(100vh - 60px)",
  overflow: "auto"
}))

const ControlWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600"
}))

const ControlHeading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242"
}))

const Input = styled(TextField)(({
  width: "100%",
  background: "#fff",
  borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
  width: '120px',
  height: '40px',
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: '5px',
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`
  }
}))

export default ChargeType;
