import { Box, Grid, Slider, Typography } from "@mui/material";
import React from "react";
import HeadingThreeImg from "../../../../../../assets/HeadingThreeImg.svg";
import TextPImg from "../../../../../../assets/TextP.png";

const HeadingThreePageName = [
  {
    id: 1,
    name: "Product Detail Page",
    points: [
      {
        id: 1,
        name: "Vendor Name",
      },
      {
        id: 2,
        name: "Features",
      },
      {
        id: 3,
        name: "100% Guarantee Safe Checkout",
      },
    ],
  },
  {
    id: 2,
    name: "Cart Page",
    points: [
      {
        id: 1,
        name: "Vendor Name",
      },
    ],
  },
  {
    id: 3,
    name: "Checkout Page",
    points: [
      {
        id: 1,
        name: "Vendor Name",
      },
      {
        id: 2,
        name: "Billing to",
      },
    ],
  },
  {
    id: 4,
    name: "Profile Page",
    points: [
      {
        id: 1,
        name: "Details",
      },
      {
        id: 2,
        name: "Addresses",
      },

      {
        id: 3,
        name: "Order History",
      },
      {
        id: 4,
        name: "RFQ History, etc.",
      },
    ],
  },
];

const HeadingTextPageName = [
  {
    id: 1,
    name: "Landing Page",
    points: [
      {
        id: 1,
        name: "On the Product Card, the product name is displayed as a text paragraph.",
      },
    ],
  },
];

const HeadingThreePara = ({
  headingThree,
  setHeadingThree,
  text,
  setText,
  two,
}) => {
  const pageName = two ? HeadingTextPageName : HeadingThreePageName;

  return (
    <>
      <Grid
        container
        mt={1}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item md={3}>
          <Typography
            sx={{
              fontSize: two ? `${text}px` : `${headingThree}px`,
              fontWeight: "600",
            }}
          >
            {two ? "Text Paragraph" : "Heading Three"}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Slider
              max={50}
              valueLabelDisplay="auto"
              value={two ? text : headingThree}
              onChange={(e) =>
                two ? setText(e.target.value) : setHeadingThree(e.target.value)
              }
            />

            <Box
              sx={{
                fontSize: "16px",
              }}
            >
              ({two ? `${text}px` : `${headingThree}px`})
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* bullets */}
      <Grid
        container
        mt={1}
        sx={{
          display: "flex",
        }}
      >
        <Grid item md={6}>
          <Typography
            sx={{
              fontWeight: "600",
            }}
          >
            {/* Implement on these pages */}
          </Typography>
          <Box
            sx={{
              margin: "10px 20px",
            }}
          >
            {pageName?.map((item) => {
              return (
                <Box key={item.id}>
                  <ul>
                    <li>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {item.name}
                      </Typography>
                      {item.points && item.points.length > 0 && (
                        <ul>
                          {item.points.map((point) => (
                            <li key={point.id}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                }}
                              >
                                {point.name}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  </ul>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "15px 15px 12px 15px",
              width: !two ? "unset" : "100%",
              height: !two ? "unset" : "395px",
            }}
          >
            <img
              src={two ? TextPImg : HeadingThreeImg}
              alt="heading"
              style={{
                width: "100%",
                height: !two ? "auto" : "100%",
                objectFit: !two ? "unset" : "contain",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadingThreePara;
