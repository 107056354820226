import React from 'react'
import { Button, Grid, TextField, Typography, styled } from '@mui/material'

function Recommendation({ state, handleContentChange, removeSection }) {

    const { bgColor, engHeading, arbHeading } = state;

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography sx={{fontSize: "16px", fontWeight: "600"}}>
                                Recommendations
                            </Typography>
                            <Typography sx={{fontSize: "12px", fontWeight: "400"}}>
                                This section displays the recommended products.
                            </Typography>
                        </Grid>
                        <Grid item md={6} display={"flex"} flexDirection={"column"} alignItems={"end"}>
                            <Label>Section Background Color</Label>
                            <input
                                type="color" 
                                value={bgColor} 
                                onChange={(e) => handleContentChange('Recomendation', 'bgColor', e.target.value)}
                                style={{ width: '30%' }}  
                            />
                            <Typography>{bgColor}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} m={3} display={"flex"} justifyContent={"start"} gap={"20px"}>
                    <Grid item md={3.5}>
                        <Label>Section Heading (English)</Label>
                        <Input
                            mt={3}
                            type="text"
                            color="primary"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={engHeading}
                            onChange={(e) => handleContentChange('Recomendation', 'engHeading', e.target.value)}
                        />
                    </Grid>
                    <Grid item md={3.5}>
                        <Label>Section Heading (Arabic)</Label>
                        <Input
                            mt={3}
                            type="text"
                            color="primary"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={arbHeading}
                            onChange={(e) => handleContentChange('Recomendation', 'arbHeading', e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item md={12} display={"flex"} justifyContent={"end"}>
                    <Button variant='contained' background="primary" sx={{marginTop: "15px", borderRadius: "10px",textTransform: "capitalize"}}
                        onClick={removeSection}
                    >
                        Remove
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const Input = styled(TextField)({
    width: "100%",
    background: "#fff",
    borderRadius: "5px",
});
  
  const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500",
}));

export default Recommendation