import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Box } from "@mui/material";
import Errors from "../Error";

const PrimaryPhoneInput = ({ value, onChange, sx, name, formik }) => {
  const handleChangeInput = (e) => {
    formik.setFieldValue(name, e);
  };

  return (
    <Box>
      <PhoneInput
        country={"sa"}
        value={value}
        name={name}
        onChange={handleChangeInput}
        specialLabel=""
        inputStyle={{
          width: "100%",
          height: "50px",
          fontSize: "16px",
          border:
            formik?.errors[name] && formik?.touched[name]
              ? "1px solid #e74c3c"
              : "1px solid #d1d1d1",
          ...sx,
        }}
        inputClass="phone-input"
        onBlur={formik?.handleBlur}
      />
      {formik?.errors[name] && formik?.touched[name] && (
        <Errors sx={{ paddingLeft: "0" }}>
          {formik?.errors.name && formik?.touched.name
            ? formik?.errors.name
            : ""}
        </Errors>
      )}
    </Box>
  );
};

export default PrimaryPhoneInput;
