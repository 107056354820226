// React Imports
import React, { useState } from "react";
// React Toastify
import { toast } from "react-toastify";
// React Spinners
import { MoonLoader } from "react-spinners";
// MUI
import { Box, Button, Container, Grid, TextField, Tooltip, Typography, styled } from "@mui/material";
// Utils
import { onKeyDown } from "../../../../../utils";
// Formik
import { Form, Formik } from "formik";
// Yup
import * as Yup from "yup";
// Redux
import {
	useCreateServiceTypeMutation,
	useDeleteServiceTypeMutation,
	useGetServiceTypesQuery,
	useUpdateServiceTypeMutation,
} from "../../../../../redux/api/serviceTypeApiSlice";
// Custom
import MUITable, {
	StyledTableCell,
	StyledTableRow,
} from "../../../../../components/MUITable";
// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import Heading from "../../../components/Heading";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

const addServiceTypeSchema = Yup.object().shape({
	service: Yup.string().required("Service Name is required"),
	service_ar: Yup.string().required("Arabic Name is required"),
});

const headerData = {
	heading: "Configure Service Types",
	subheading: "",
}

const tableHead = [
	"#",
	"Service Name (Eng)",
	"Service Name (Arabic)",
	"Actions",
];

const ServiceType = () => {
	const [formValues, setFormValues] = useState({
		service: "",
		service_ar: "",
	});
	const [editValue, setEditValue] = useState("");
	const [deleteValue, setDeleteValue] = useState("");
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [uuid, setUUID] = useState("");

	// todo: GET SERVICE TYPE API CALL
	const { data: getServiceTypes, isLoading: getServicesLoading } =
		useGetServiceTypesQuery({});

	// todo: CREATE SERVICE TYPE API BIND
	const [createServiceType, { isLoading }] = useCreateServiceTypeMutation();

	// todo: UPDATE SERVICE TYPE API BIND
	const [updateServiceType, { isLoading: updateServiceLoading }] =
		useUpdateServiceTypeMutation();

	const AddServiceTypeHandler = async (data, resetForm) => {
		const payload = {
			en_service_name: data.service,
			ar_service_name: data.service_ar,
		};

		// todo: UPDATE SERVICE TYPE
		if (editValue) {
			try {
				const update = await updateServiceType({
					body: payload,
					id: editValue?.uuid,
				});

				if (!update?.error) {
					toast.success("Service Type updated successfully!");
					resetForm();
					setEditValue("");
					setFormValues({
						...formValues,
						service: "",
						service_ar: "",
					});
				}
				if (update?.error) {
					toast.error("Something went wrong", "error");
				}
			} catch (error) {
				console.error("Update Service Type Error:", error);
				toast.error(error.response.data.message);
			}

			return;
		}

		try {
			const create = await createServiceType(payload);

			if (!create?.error) {
				toast.success("Service Type created successfully!");
				resetForm();
			}

			if (create?.error) {
				toast.error("Something went wrong", "error");
			}
		} catch (error) {
			console.error("Create Service Type Error:", error);
			toast.error(error.response.data.message);
		}
	};

	// todo: DELETE VARIANT API BIND
	const [deleteServiceType, { isLoading: deleteServiceTypeLoading }] =
		useDeleteServiceTypeMutation();

	const handleDelete = async (id) => {
		try {
			const deleteService = await deleteServiceType(id);

			if (!deleteService?.error) {
				toast.success("Service Type deleted successfully!");
			}
			if (deleteService?.error) {
				toast.error("Something went wrong", "error");
			}
		} catch (error) {
			console.error("Delete Service Type Error:", error);
			toast.error(error.response.data.message);
		}
	};

	const openConfirmationModal = (id) => {
		setUUID(id);
		setConfirmationModal(true);
	};

	return (
		<>
			{uuid ? (
				<ConfirmationModal
					prodId={uuid}
					open={confirmationModal}
					setOpen={setConfirmationModal}
					heading={"Confirm Service Deletion"}
					text={"Are you sure you want to delete the Service?"}
					handleDeleteProduct={handleDelete}
				/>
			) : null}
			<Wrapper maxWidth={false}>
				<Heading data={headerData} />
				<ControlWrapper mt={3}>
					<ControlHeading>Create a Service</ControlHeading>
					<Formik
						initialValues={formValues}
						onSubmit={(values, { resetForm }) => {
							AddServiceTypeHandler(values, resetForm);
						}}
						validationSchema={addServiceTypeSchema}
						enableReinitialize
					>
						{(props) => {
							const { values, touched, errors, handleBlur, handleChange } = props;
							return (
								<Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
									<Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
										<Grid item md={4}>
											<Label>Service Name (Eng)</Label>
											<Input
												type="text"
												label=""
												name="service"
												value={values.service}
												helperText={
													errors.service && touched.service
														? errors.service
														: ""
												}
												error={errors.service && touched.service ? true : false}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid>
										<Grid item md={4}>
											<Label>Service Name (Arabic)</Label>
											<Input
												type="text"
												label=""
												name="service_ar"
												value={values.service_ar}
												helperText={
													errors.service_ar && touched.service_ar
														? errors.service_ar
														: ""
												}
												error={
													errors.service_ar && touched.service_ar ? true : false
												}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid>
										<Grid item md={4} display={"flex"} justifyContent={"end"}>
											<SaveBTN type="submit">
												{isLoading || updateServiceLoading ? (
													<>
														<MoonLoader color="#fff" size={20} />
													</>
												) : editValue ? (
													"Update"
												) : (
													"Add"
												)}
											</SaveBTN>
										</Grid>
									</Grid>
								</Form>
							);
						}}
					</Formik>
				</ControlWrapper>
				<Box
					sx={{
						margin: "10px 0",
					}}
				>
					<MUITable tableHead={tableHead} background="#f3f4f5">
						{getServicesLoading ? (
							<StyledTableRow>
								<StyledTableCell
									sx={{ height: "70px" }}
									colSpan={tableHead?.length}
									align="center"
								>
									<Box
										sx={{
											fontSize: "18px",
											display: "flex",
											alignItems: "center",
											gap: 1,
										}}
									>
										<MoonLoader color="#000" size={20} />
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						) : getServiceTypes?.length > 0 ? (
							getServiceTypes?.map((item, index) => {
								return (
									<StyledTableRow key={index}>
										<StyledTableCell>{index + 1}</StyledTableCell>
										<StyledTableCell>{item?.en_service_name}</StyledTableCell>
										<StyledTableCell>{item?.ar_service_name}</StyledTableCell>
										<StyledTableCell>
											<Box
												sx={{
													display: "flex",
													gap: "10px",
													cursor: "pointer",
												}}
											>
												<Tooltip title="Edit" placement="top">
													<EditIcon
														sx={{ fontSize: "20px", color: "#07bc0c" }}
														onClick={() => {
															setFormValues({
																...formValues,
																service: item?.en_service_name,
																service_ar: item?.ar_service_name,
															});
															setEditValue(item);
														}}
													/>
												</Tooltip>
												{deleteServiceTypeLoading &&
													item.uuid === deleteValue.uuid ? (
													<MoonLoader color="#000" size={16} />
												) : (
													<Tooltip title="Delete" placement="top">
														<DeleteIcon
															sx={{ fontSize: "20px", color: "red" }}
															onClick={() => {
																openConfirmationModal(item?.uuid);
																setDeleteValue(item);
															}}
														/>
													</Tooltip>
												)}
											</Box>
										</StyledTableCell>
									</StyledTableRow>
								);
							})
						) : (
							<StyledTableRow>
								<StyledTableCell
									sx={{ height: "100px" }}
									colSpan={tableHead?.length}
									align="center"
								>
									<Box
										sx={{
											fontSize: "18px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											gap: 1,
										}}
									>
										{getServiceTypes?.length === 0 ? "No records found" : ""}
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						)}
					</MUITable>
				</Box>
			</Wrapper>
		</>
	);
};

const Wrapper = styled(Container)(() => ({
	width: "100%",
	padding: '1rem',
	height: "calc(100vh - 60px)",
	overflow: "auto"
}))

const ControlWrapper = styled(Box)(() => ({
	width: "100%",
	background: "#fff",
	padding: "10px 15px",
	borderRadius: "12px",
	fontSize: "22px",
	fontWeight: "600"
}))

const ControlHeading = styled(Typography)(() => ({
	fontSize: "16px",
	fontWeight: "600",
	color: "#424242"
}))

const Input = styled(TextField)(({
	width: "100%",
	background: "#fff",
	borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
	fontSize: "12px",
	fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
	width: '120px',
	height: '40px',
	marginTop: "15px",
	fontSize: "16px",
	background: `${theme.palette.primary.main}`,
	color: "#fff",
	borderRadius: '5px',
	textTransform: "capitalize",
	"&:hover": {
		backgroundColor: `${theme.palette.primary.main}`
	}
}))

export default ServiceType;
