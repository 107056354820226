import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

// MUI Component Import
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// Components Import
import Heading from "../../../components/Heading";
import UploadCSVCard from "../../components/Cards/UploadCSVCard";
import DownloadCSVCard from "../../components/Cards/DownloadCSVCard";

import { MoonLoader } from "react-spinners";
import axios from "axios";
import { baseUrl } from "../../../../../constants/api";

const headerData = {
  heading: "Bulk Products Upload/Download",
  subheading:
    "We utilize excel files import services to efficiently upload your catalogue",
};

function BulkHandling() {
  const token = window.localStorage.getItem("user-token");
  const [loading, isLoading] = useState(false);

  const [files, setFiles] = useState([]);

  const fetchFiles = () => {
    isLoading(true);
    axios
      .get(`${baseUrl}/api/files`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setFiles(response.data);
        isLoading(false);
      })
      .catch((err) => {
        console.log(err);
        isLoading(false);
      });
  };

  useEffect(() => {
    fetchFiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />
        <CardsWrapper container my={3}>
          <DownloadCSVCard />
          <UploadCSVCard fetchFiles={fetchFiles} />
        </CardsWrapper>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>File Id</TableHeadings>
                <TableHeadings>File Name</TableHeadings>
                <TableHeadings>Status</TableHeadings>
                <TableHeadings>File Status</TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow align="center">
                  <TableContent colSpan={8}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : files.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={8} align="center">
                    No data to show
                  </TableContent>
                </TableRow>
              ) : (
                files.map((file, index) => (
                  <TableRow key={index}>
                    <TableContent>{index + 1}</TableContent>
                    <TableContent>{file?.pfId}</TableContent>
                    <TableContent>{file?.file_name}</TableContent>
                    <TableContent>{file?.status_display}</TableContent>
                    <TableContent>{file?.reason}</TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const CardsWrapper = styled(Grid)(() => ({
  height: "70%",
  padding: "2rem",
  borderRadius: "15px",
  gap: "40px",
  background: "#fff",
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default BulkHandling;
