import React, { useEffect, useState } from 'react';
import { Box, Button, Container, styled } from '@mui/material';
import Heading from '../../../../components/Heading';
import SectionManager from './SectionManager';
import PromotionalBanners from './Components/PromotionalBanners';
import CategoryList from './Components/CategoryList';
import DiscountedProducts from './Components/DiscountedProducts';
import SingleBanner from './Components/SingleBanner';
import Services from './Components/Services';
import Newsletter from './Components/Newsletter';
import BestSellers from './Components/BestSellers';
import Recommendation from './Components/Recommendation';
import axios from 'axios';
import { baseUrl } from '../../../../../../constants/api';
import { MoonLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Partners from './Components/Partners';

const headerData = {
    heading: "Manage Marketplace Home Page",
    subheading: "Add/Remove sections or arrange them as you like",
};

const initialSections = [
    { id: 'BannersCarousel', content: 'Promotional Banners', index: 0, component: PromotionalBanners },
    { id: 'CategoryList', content: 'Category List', index: 1, component: CategoryList, state: { bgColor: '#ffffff', engHeading: '', arbHeading: '' } },
    { id: 'Partners', content: 'Brand Partners', index: 2, component: Partners },
    { id: 'BestSeller', content: 'Best Selling Products', index: 3, component: BestSellers, state: { bgColor: '#ffffff', engHeading: '', arbHeading: '' } },
    { id: 'Recomendation', content: 'Recommended Products', index: 4, component: Recommendation, state: { bgColor: '#ffffff', engHeading: '', arbHeading: '' } },
    { id: 'DiscountBanner', content: 'Static Banner', index: 5, component: SingleBanner, state: { banner: null } },
    { id: 'DiscountedProducts', content: 'Discounted Products', index: 6, component: DiscountedProducts, state: { bgColor: '#ffffff', engHeading: '', arbHeading: '' } },
    { id: 'Services', content: 'Features', index: 7, component: Services },
    { id: 'Newsletter', content: 'Newsletter', index: 8, component: Newsletter, state: { engHeading: '', arbHeading: '' } },
];

const componentMap = {
    BannersCarousel: PromotionalBanners,
    CategoryList: CategoryList,
    Partners: Partners,
    BestSeller: BestSellers,
    Recomendation: Recommendation,
    DiscountBanner: SingleBanner,
    DiscountedProducts: DiscountedProducts,
    Services: Services,
    Newsletter: Newsletter,
};

const Home = () => {
    const token = window.localStorage.getItem("user-token");

    const [updating, setUpdating] = useState(false);
    const [sections, setSections] = useState(initialSections);
    const [availableSections, setAvailableSections] = useState([]);

    const fetchArrangement = () => {
        axios.get(`${baseUrl}/api/get/arrangements`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            const fetchedSections = response.data.arrangements || [];
            if (fetchedSections.length === 0) {
                setSections(initialSections);
            } else {
                const updatedSections = fetchedSections.map(section => ({
                    ...section,
                    component: componentMap[section.id]
                }));
                setSections(updatedSections);

                const remainingSections = initialSections.filter(initialSection =>
                    !fetchedSections.some(fetchedSection => fetchedSection.id === initialSection.id)
                );
                setAvailableSections(remainingSections);
            }
        })
        .catch((err) => {
            console.log("Error", err);
        });
    };

    const addSection = (sectionToAdd) => {
        if (!sections.find(sec => sec.id === sectionToAdd.id)) {
            const updatedSections = [...sections, { ...sectionToAdd, component: componentMap[sectionToAdd.id] }];
            setSections(updatedSections);
            setAvailableSections(prevAvailableSections => prevAvailableSections.filter(sec => sec.id !== sectionToAdd.id));
        }
    };

    const removeSection = (removedSection) => {
        const updatedSections = sections.filter(sec => sec.id !== removedSection.id);
        setSections(updatedSections);
        setAvailableSections(prevAvailableSections => [...prevAvailableSections, removedSection]);
    };

    const handleContentChange = (id, field, value) => {
        const updatedSections = sections.map(sec =>
            sec.id === id ? { ...sec, state: { ...sec.state, [field]: value } } : sec
        );
        setSections(updatedSections);
    };

    useEffect(() => {
        fetchArrangement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateArrangement = () => {
        setUpdating(true);
        axios.put(`${baseUrl}/api/set/arrangements`, { arrangements: sections }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        })
        .then(() => {
            setUpdating(false);
            toast.success("Your updates have been saved!");
        })
        .catch((err) => {
            console.log("Error", err);
            setUpdating(false);
        });
    };

    return (
        <Wrapper maxWidth={false}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Heading data={headerData} />
                <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <Button
                        style={{ textTransform: "none" }}
                        variant="contained"
                        color="secondary"
                        backgroundcolor="primary"
                        onClick={updateArrangement}
                    >
                        {updating ? <MoonLoader color="#fff" size={20} /> : "Publish"}
                    </Button>
                </Box>
            </Box>
            <SectionManager
                sections={sections}
                setSections={setSections}
                availableSections={availableSections}
                addSection={addSection}
                removeSection={removeSection}
                handleContentChange={handleContentChange}
            />
        </Wrapper>
    );
};


// const Home = () => {
//     const token = window.localStorage.getItem("user-token");

//     const [updating, setUpdating] = useState(false);
//     const [sections, setSections] = useState([initialSections]);
//     const [availableSections, setAvailableSections] = useState([]);

//     const fetchArrangement = () => {
//         axios.get(`${baseUrl}/api/get/arrangements`, {
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Token ${token}`,
//             },
//         })
//         .then((response) => {
//             const fetchedSections = response.data.arrangements && response.data.arrangements ? response.data.arrangements : [];
//             if (fetchedSections.length === 0) {
//                 setSections(initialSections);
//             } else {
//                 const updatedSections = fetchedSections.map(section => ({
//                     ...section,
//                     component: componentMap[section.id]
//                 }));
//                 setSections([updatedSections]);

//                 const remainingSections = initialSections.filter(initialSection =>
//                     !fetchedSections.some(fetchedSection => fetchedSection.id === initialSection.id)
//                 );
//                 setAvailableSections(remainingSections);
//             }
//         })
//         .catch((err) => {
//             console.log("Error", err);
//         });
//     };

//     const addSection = (sectionToAdd) => {
//         const updatedSections = [...sections[0]];
//         if (!updatedSections.find(sec => sec.id === sectionToAdd.id)) {
//             updatedSections.push({ ...sectionToAdd, component: componentMap[sectionToAdd.id], index: updatedSections.length });
//         }
//         setSections([updatedSections]);
//         setAvailableSections(prevAvailableSections => prevAvailableSections.filter(sec => sec.id !== sectionToAdd.id));
//     };

//     const removeSection = (removedSection) => {
//         setAvailableSections(prevAvailableSections => [...prevAvailableSections, removedSection]);
//         const updatedSections = sections[0].filter(sec => sec.id !== removedSection.id)
//             .map((sec, index) => ({ ...sec, index }));
//         setSections([updatedSections]);
//     };

//     const handleContentChange = (id, field, value) => {
//         const updatedSections = sections[0].map(sec =>
//             sec.id === id ? { ...sec, state: { ...sec.state, [field]: value } } : sec
//         );
//         setSections([updatedSections]);
//     };

//     useEffect(() => {
//         fetchArrangement();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     const updateArrangement = () => {
//         setUpdating(true);
//         axios.put(`${baseUrl}/api/set/arrangements`, { arrangements: sections[0] }, {
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Token ${token}`,
//             },
//         })
//         .then(() => {
//             setUpdating(false);
//             toast.success("Your updates have been saved!");
//         })
//         .catch((err) => {
//             console.log("Error", err);
//             setUpdating(false);
//         });
//     };

//     return (
//         <Wrapper maxWidth={false}>
//             <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//                 <Heading data={headerData} />
//                 <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
//                     <Button
//                         style={{ textTransform: "none" }}
//                         variant="contained"
//                         color="secondary"
//                         backgroundcolor="primary"
//                         onClick={updateArrangement}
//                     >
//                         {updating ? <MoonLoader color="#fff" size={20} /> : "Publish"}
//                     </Button>
//                 </Box>
//             </Box>
//             <SectionManager
//                 sections={sections}
//                 setSections={setSections}
//                 availableSections={availableSections}
//                 addSection={addSection}
//                 removeSection={removeSection}
//                 handleContentChange={handleContentChange}
//             />
//         </Wrapper>
//     );
// };

// Styled Components
const Wrapper = styled(Container)(() => ({
    width: "100%",
    padding: "1rem",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}));

export default Home;
