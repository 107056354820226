import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiMVSlice = createApi({
  reducerPath: "mvApi", // Ensure unique and descriptive reducerPath

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MV_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().auth?.user?.token || localStorage.getItem("user-token");
      if (token) {
        headers.set("authorization", `Token ${token}`);
        headers.set("Content-Type", "application/json");
        headers.set("Accept", "application/json");
      }
      return headers;
    },
  }),
  tagTypes: [
    "Rfqs",
    "Orders",
    "Products",
    "Variants",
    "ServiceTypes",
    "ChargeTypes",
    "Services",
    "Footer Sections",
    "ServiceProviders",
    "Blogs",
  ],
  endpoints: (builder) => ({}),
});
