import { Badge, Box, Typography } from "@mui/material";
import UserIcon from "@mui/icons-material/Person";
import CartIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";

const HeaderBG = ({ bg, textColor }) => {
  const iconColor = textColor;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          backgroundColor: bg,
          width: "100%",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <UserIcon
            sx={{
              color: iconColor,
              fontSize: "22px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              gap: "0px",
            }}
          >
            <Typography
              sx={{
                color: iconColor,
                fontSize: "12px",
                fontWeight: "300",
              }}
            >
              Sign In
            </Typography>
            <Typography
              sx={{
                color: iconColor,
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Mohammad
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
          }}
        >
          <Badge color="error">
            <FavoriteIcon
              sx={{
                color: iconColor,
                fontSize: "22px",
              }}
            />
          </Badge>
          <Typography
            sx={{
              color: iconColor,
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Orders
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
          }}
        >
          <Badge badgeContent={20} color="error">
            <CartIcon
              sx={{
                color: iconColor,
                fontSize: "22px",
              }}
            />
          </Badge>
          <Typography
            sx={{
              color: iconColor,
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            15,000 SR
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default HeaderBG;
