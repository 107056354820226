import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

// MUI Components Import
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../../constants/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  backgroundColor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  gap: "12px",
};

function DownloadCSVModal({ open, setOpen }) {
  // eslint-disable-next-line no-unused-vars
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedDropdownType, setSelectedDropdownType] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const token = window.localStorage.getItem("user-token");

  async function getFile() {
    try {
      const response = await axios.get(`${baseUrl}/api/xlsx/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Token ${token}`,
        },
        responseType: "blob", // Specify response type as 'blob' to handle binary data
      });
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "products.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("xlsx file downloaded successfully.");
      } else {
        toast.error("Failed to download xlsx file.");
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/entities`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Token ${token}`,
        },
      });
      setCategoriesList(response.data.categories);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddClick = (dropdownType) => {
    setSelectedDropdownType(dropdownType);

    // Open the corresponding modal based on the dropdown type.
    switch (dropdownType) {
      case "Category":
        setCategoryModalOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
            Download CSV
          </Typography>
          <Wrapper>
            <Label>Category</Label>
            <FormControl
              size="small"
              sx={{
                width: "100%",
                mt: "5px",
                background: "#fff",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Select sx={{ borderRadius: "12px", width: "300px" }}>
                {categoriesList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name} - {item.arb_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Wrapper>
          <Grid container>
            <Grid
              item
              sm={12}
              md={12}
              display={"flex"}
              justifyContent={"end"}
              gap={"15px"}
            >
              <BottomButton
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </BottomButton>
              <BottomButton
                variant="contained"
                color="secondary"
                onClick={() => getFile()}
              >
                Download
              </BottomButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

const Wrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  gap: "10px",
}));

const Label = styled(Typography)({
  fontSize: "14px",
  fontWeight: "600",
});
const BottomButton = styled(Button)(({ theme }) => ({
  height: "40px",
  width: "auto",
  padding: "0 20px",
  mt: "23px",
  borderRadius: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

export default DownloadCSVModal;
