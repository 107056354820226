import { apiSlice } from "./apiSlice";

export const serviceProviderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceProviders: builder.query({
      query: () => {
        return {
          url: "service-providers",
          method: "GET",
        };
      },
      providesTags: ["ServiceProviders"],
    }),

    createServiceProvider: builder.mutation({
      query: (body) => {
        return {
          url: "service-provider/create",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["ServiceProviders"],
    }),

    updateServiceProvider: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `service-provider/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ServiceProviders"],
    }),

    deleteServiceProvider: builder.mutation({
      query: (id) => {
        return {
          url: `service-provider/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ServiceProviders"],
    }),
  }),
});

export const {
  useGetServiceProvidersQuery,
  useCreateServiceProviderMutation,
  useUpdateServiceProviderMutation,
  useDeleteServiceProviderMutation,
} = serviceProviderApiSlice;
