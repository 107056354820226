import React from "react";
import { styled } from "@mui/material/styles";
// MUI Components Import
import { Container } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import ServiceProductsList from "./components/ServiceProvidersList";
import Navbar from "../components/Navbar";

function ServiceProviders() {
  const location = useLocation();
  const current_loc = location.pathname.split("/").pop();

  return (
    <>
      <Wrapper maxWidth={false}>
        <Navbar />
        {current_loc === "service-provider" ? (
          <ServiceProductsList />
        ) : (
          <Outlet />
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  padding: "0 !important",
  width: "100%",
  height: "auto",
  overflow: "auto",
}));

export default ServiceProviders;
