import React from 'react'
import { styled } from "@mui/material/styles";
import { Outlet, useLocation } from 'react-router-dom';

// MUI Components Import
import { Container, Box } from "@mui/material";

// Components Import
import Navbar from '../../../components/Navbar';
import Heading from '../../../components/Heading';

const headerData = {
    heading: "Track your orders",
    subheading: "",
};

function OrdersDashboard() {

    const location = useLocation();
    const current_loc = location.pathname.split("/").pop();

    return (
        <>
            <MainWrapper maxWidth={false}>
                <Navbar />
                {current_loc === 'orders' ? (
                    <Wrapper maxWidth={false}>
                        <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        >
                            <Heading data={headerData} />
                        </Box>
                    </Wrapper>
                ) : (
                    <Outlet />
                )}
            </MainWrapper>
        </>
    )
}

const MainWrapper = styled(Container)(() => ({
    padding: "0 !important",
	width: "100%",
	height: "auto",
    overflow: "auto"
}))

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
    width: "100%",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}));

export default OrdersDashboard