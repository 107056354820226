import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useLoaderContext } from "../../../../../contexts/LoaderContext";
import axios from "axios";

// Loader Import
import { MoonLoader } from "react-spinners";

// MUI Components Import
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";

// Components Import
import Heading from "../../../components/Heading";

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik & Yup Imports
import { useFormik } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../../../../constants/api";

const validationSchema = Yup.object({
  engName: Yup.string().required("English Name is required"),
  arabicName: Yup.string().required("Arabic Name is required"),
});

const headerData = {
  heading: "Configure Models",
  subheading: "",
};

function Models() {
  const token = window.localStorage.getItem("user-token");

  const { loading, handleLoader } = useLoaderContext();

  const [saving, setSaving] = useState(false);
  const [modelsList, setModelsList] = useState([]);
  const [editingModel, setEditingModel] = useState(null);

  const formik = useFormik({
    enableReinitialze: true,
    initialValues: {
      engName: editingModel != null ? editingModel.name : "",
      arabicName: editingModel != null ? editingModel.arb_name : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (editingModel) {
        handleEdit(editingModel.uuid, values);
      } else {
        handleCreate(values);
      }
    },
  });

  const editModel = (model) => {
    formik.setFieldValue("engName", model.name);
    formik.setFieldValue("arabicName", model.arb_name);
    setEditingModel(model);
  };

  const handleCreate = (formValues) => {
    setSaving(true);
    axios
      .post(
        `${baseUrl}/api/model/create`,
        {
          name: formValues.engName,
          arb_name: formValues.arabicName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Model created successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchEntities();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleEdit = (id, formValues) => {
    setSaving(true);
    axios
      .put(
        `${baseUrl}/api/model/update/${id}`,
        {
          name: formValues.engName,
          arb_name: formValues.arabicName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Model edited successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchEntities();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}/api/model/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast.success("Model deleted successfully!");
        fetchEntities();
        setModelsList(response.data.brands);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  const fetchEntities = async () => {
    handleLoader(true);
    axios
      .get(`${baseUrl}/api/entities`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setModelsList(response.data.models);
        handleLoader(false);
      })
      .catch((error) => {
        console.log("Error", error);
        handleLoader(false);
      });
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />
        <ControlWrapper mt={3}>
          <ControlHeading>Create a Model</ControlHeading>
          <Grid
            container
            mt={1}
            spacing={3}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid item md={4}>
              <Label>Model Name (English)</Label>
              <Input
                name="engName"
                {...formik.getFieldProps("engName")}
                error={formik.touched.engName && Boolean(formik.errors.engName)}
                helperText={formik.touched.engName && formik.errors.engName}
              />
            </Grid>
            <Grid item md={4}>
              <Label>Model Name (Arabic)</Label>
              <Input
                name="arabicName"
                {...formik.getFieldProps("arabicName")}
                error={
                  formik.touched.arabicName && Boolean(formik.errors.arabicName)
                }
                helperText={
                  formik.touched.arabicName && formik.errors.arabicName
                }
              />
            </Grid>
            <Grid item md={4} display={"flex"} justifyContent={"end"}>
              <SaveBTN onClick={() => formik.handleSubmit()}>
                {saving ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : editingModel ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </SaveBTN>
            </Grid>
          </Grid>
        </ControlWrapper>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>Name</TableHeadings>
                <TableHeadings>Action</TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableContent sx={{ width: "100%" }}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : modelsList.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={4} align="center">
                    You do not have any models yet!
                  </TableContent>
                </TableRow>
              ) : (
                modelsList.map((model, index) => (
                  <TableRow key={index}>
                    <TableContent sx={{ width: "1%" }}>
                      {index + 1}
                    </TableContent>
                    <TableContent sx={{ width: "90%" }}>
                      {model.name} , {model.arb_name}
                    </TableContent>
                    <TableContent
                      sx={{ display: "flex", gap: "10px", cursor: "pointer" }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <EditIcon
                          sx={{ fontSize: "20px", color: "#000" }}
                          onClick={() => editModel(model)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" placement="top">
                        <DeleteIcon
                          sx={{ fontSize: "20px", color: "red" }}
                          onClick={() => handleDelete(model.uuid)}
                        />
                      </Tooltip>
                    </TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const ControlWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const ControlHeading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default Models;
