import { createContext, useContext, useState } from "react";

const UserRoleContext = createContext();

export const useUserRoleContext = () => {
    return useContext(UserRoleContext);
};

export const UserRoleProvider = ({ children }) => {

    const getRole = () => {
        const userRole = parseInt(window.localStorage.getItem("user-role"));
        if (userRole !== undefined || userRole !== null) {
            return userRole
        }
        return -1
    }

    const [role, setRole] = useState(getRole())
    return (
        <UserRoleContext.Provider value={{ role, setRole }}>
            {children}
        </UserRoleContext.Provider>
    );
};
