import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// MUI Components Import
import { Container, Box, Grid, Button, Typography } from "@mui/material";

// MUI Icons Import
import AddPhoto from "@mui/icons-material/AddPhotoAlternate";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Loader Import
import { MoonLoader } from "react-spinners";

// Components Import
import Heading from "../../../components/Heading";

import CancelIcon from "@mui/icons-material/Cancel";

// Theme Context Import
import { useUserThemeContext } from "../../../../../contexts/ThemeContext";
import { baseUrl } from "../../../../../constants/api";

const headerData = {
  heading: "Set a theme",
  subheading: "",
};

const ThemeSettings = () => {
  const { updateNavTheme, updateBgTheme, updateTextTheme } = useUserThemeContext();

  const token = window.localStorage.getItem("user-token");

  const logoUpload = useRef();

  const [reloading, setReloading] = useState(false);

  const [bgColor, setBgColor] = useState("#F4F4FA");
  const [textColor, setTextColor] = useState("#00000");
  const [navColor, setNavColor] = useState("#812990");
  const [logo, setLogo] = useState(null);
  const [previewImage, setPreviewImg] = useState(null);

  const selectLogo = () => {
    logoUpload.current.click();
  };

  const handleLogoUplaod = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo(file);
      setPreviewImg(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setLogo(null);
    setPreviewImg(null);
  };

  const setDefault = () => {
    setBgColor("#F4F4FA");
    setTextColor("#00000");
    setNavColor("#812990");
    toast.info("Save to apply the default settings!");
  };

  const updateUserSettings = async () => {
    if (!logo) {
      toast.error("Please upload an image.");
      return;
    }
    setReloading(true);

    const formData = new FormData();

    formData.append("bgColor", bgColor);
    formData.append("navColor", navColor);
    formData.append("textColor", textColor);

    if (typeof logo !== "string") {
        formData.append("logo", logo);
      }

    await axios
      .put(
        `${baseUrl}/api/auth/settings`, formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Settings Updated, page will be refreshed!");
        setTimeout(() => {
          setReloading(false);
        }, 2000);
        setLogo(null);
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Couldn't update the settings!");
        setReloading(false);
        console.log(err);
      });
  };

  const fetchUserSettings = async () => {
    await axios
      .get(`${baseUrl}/api/auth/settings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.data.bgColor &&
          response.data.textColor &&
          response.data.navColor
        ) {

          setBgColor(response.data.bgColor);
          setTextColor(response.data.textColor);
          setNavColor(response.data.navColor);
          updateNavTheme(response.data.navColor);
          updateBgTheme(response.data.bgColor);
          updateTextTheme(response.data.textColor);
        } else {
          updateNavTheme("#009444");
          updateBgTheme("#f4f4f4");
          updateTextTheme("#000000");
        }
        setLogo(response.data.logo);
        setPreviewImg(response.data.logo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUserSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />
        <BoxWrapper container my={2}>
          <Uploader item md={2} onClick={() => selectLogo()}>
            <HeadingText>Upload Your Logo</HeadingText>
            <input
              type="file"
              accept="image/*"
              ref={logoUpload}
              style={{ display: "none" }}
              onChange={(e) => handleLogoUplaod(e)}
              id="logoInput"
            />
            <AddPhoto sx={{ fontSize: "25px" }} />
            <Typography
              sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
            >
              Add Image
            </Typography>
          </Uploader>
          <Grid item md={9}>
            {logo ? (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <ImgDisplay
                  sx={{
                    background: `url(${previewImage}) center/cover no-repeat`,
                  }}
                />
                <CancelIcon
                  sx={{
                    fontSize: "20px",
                    position: "relative",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => removeImage()}
                />
              </div>
            ) : null}
          </Grid>
        </BoxWrapper>
        <BoxWrapper container my={2}>
          <ColorBox item md={3}>
            <HeadingText>Background Color</HeadingText>
            <input
              type="color"
              value={bgColor}
              onChange={(e) => setBgColor(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{bgColor}</Typography>
          </ColorBox>
          <ColorBox item md={3}>
            <HeadingText>Left Navigation Color</HeadingText>
            <input
              type="color"
              value={navColor}
              onChange={(e) => setNavColor(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{navColor}</Typography>
          </ColorBox>
          <ColorBox item md={12}>
            <Button
              color="secondary"
              variant="contained"
              style={{ textTransform: "none", width: "150px" }}
              onClick={() => setDefault()}
            >
              Set Default
            </Button>
          </ColorBox>
        </BoxWrapper>
        <Box
          mt={"50px"}
          gap={"30px"}
          width={"100%"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Button
            color="secondary"
            variant="outlined"
            style={{ textTransform: "none", width: "100px" }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ textTransform: "none", width: "100px" }}
            onClick={() => updateUserSettings()}
          >
            {reloading ? (
              <>
                <MoonLoader color="#fff" size={20} />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Wrapper>
    </>
  );
};

// Styled Components

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const HeadingText = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: "600",
  padding: "1rem",
}));

const Uploader = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90px",
  cursor: "pointer",
}));

const ImgDisplay = styled(Box)(() => ({
  width: "100px",
  height: "90px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  borderRadius: "10px",
}));

const BoxWrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  //   justifyContent: "space-between",
  gap: "30px",
  height: "auto",
  padding: "42px",
  border: "1px solid #DDD",
  borderRadius: "8px",
  backgroundColor: "white",
}));

const ColorBox = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: "10px",
}));

export default ThemeSettings;
