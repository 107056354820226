import { Box } from "@mui/material";
import React from "react";
import { MoonLoader } from "react-spinners";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import { thousandSeparator } from "../../../../utils";

const tableHead = [
  "Vendor ID",
  "Prof ID",
  "Image",
  "Name",
  "Email",
  "Total Sales",
  "Created At",
];

const TopSellers = ({ data, loading }) => {
  return (
    <>
      <Box
        sx={{
          margin: "20px 0",
        }}
      >
        <h3>Top 5 Sellers</h3>
      </Box>
      <Box
        sx={{
          margin: "10px 0",
        }}
      >
        <MUITable tableHead={tableHead}>
          {loading ? (
            <StyledTableRow>
              <StyledTableCell
                sx={{ height: "70px" }}
                colSpan={tableHead?.length}
                align="center"
              >
                <Box
                  sx={{
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <MoonLoader color="#000" size={20} />
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ) : data?.length > 0 ? (
            data?.map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{item?.vendor}</StyledTableCell>
                  <StyledTableCell>{item?.profId}</StyledTableCell>
                  <StyledTableCell>
                    {item?.profileImage ? (
                      <img
                        src={item?.profileImage}
                        alt="seller"
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : (
                      "N/A"
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{item?.fullname}</StyledTableCell>
                  <StyledTableCell>{item?.email}</StyledTableCell>
                  <StyledTableCell>
                    {thousandSeparator(Number(item?.total_sales))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {new Date(item?.created_at).toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
          ) : (
            <StyledTableRow>
              <StyledTableCell
                sx={{ height: "100px" }}
                colSpan={tableHead?.length}
                align="center"
              >
                <Box
                  sx={{
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  {data?.length === 0 ? "No records found" : ""}
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </MUITable>
      </Box>
    </>
  );
};

export default TopSellers;
