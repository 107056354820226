// React Imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Icons
import AddIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
// Utils
import { maskingPhoneNumber } from "../../../../utils";
// MUI
import { Box, Button, Container, Tooltip } from "@mui/material";
// Redux Toolkit
import { useGetServicesQuery } from "../../../../redux/api/serviceApiSlice";
import {
  useDeleteServiceProductMutation,
  useGetServiceProductsQuery,
} from "../../../../redux/api/serviceProductsApiSlice";
import { useGetServiceProvidersQuery } from "../../../../redux/api/serviceProviderApiSlice";
import { useGetSaudiCityQuery } from "../../../../redux/api/utilApiSlice";
// React Toastify
import { toast } from "react-toastify";
// React Spinners
import { MoonLoader } from "react-spinners";
// Custom
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components";

const tableHead = [
  "Image",
  "Name",
  "Service",
  "Service Provider",
  "Address",
  "City",
  "Phone Number",
  "WhatsApp",
  "Qualification (Eng)",
  "Qualification (Ar)",
  "Specialty (Eng)",
  "Specialty (Ar)",
  "Details (Eng)",
  "Details (Ar)",
  "Actions",
];

const ServiceProvidersList = () => {
  const navigate = useNavigate();

  const [deleteValue, setDeleteValue] = useState("");

  // todo: 1) GET SERVICE PRODUCTS API CALL
  const {
    data: getServiceProducts,
    isSuccess,
    isLoading: getServiceProductsLoading,
    refetch: refetchServiceProducts,
  } = useGetServiceProductsQuery({});

  // todo: GET SAUDI API CALL
  const { data: getSaudiCity, isLoading: getSaudiCityLoading } =
    useGetSaudiCityQuery({});

  // todo: GET SERVICES API CALL
  const { data: getServices, isLoading: getServicesLoading } =
    useGetServicesQuery({
      whole: true,
    });

  // todo: GET SERVICE PROVIDER API CALL
  const { data: getServiceProviders, isLoading: getServiceProvidersLoading } =
    useGetServiceProvidersQuery({});

  const getServiceName = (id) => {
    const name = getServices?.results?.find((item) => item.id === id);
    return name;
  };

  const getServiceProviderName = (id) => {
    const name = getServiceProviders?.find((item) => item.id === id);
    return name;
  };

  const getSaudiCityName = (id) => {
    const name = getSaudiCity?.find((item) => item.id === id);
    return name;
  };

  // todo: 2) DELETE SERVICE PRODUCT API BIND
  const [deleteService, { isLoading: deleteServiceLoading }] =
    useDeleteServiceProductMutation();

  const handleDelete = async (id) => {
    try {
      const delService = await deleteService(id);

      if (!delService?.error) {
        toast.success("Service Provider deleted successfully!");
      }
      if (delService?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Delete Service Provider Error:", error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("message")) {
      refetchServiceProducts();
      window.localStorage.removeItem("message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.localStorage.getItem("message")]);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            padding: "20px",
          }}
        >
          {(getServiceProductsLoading ||
            getSaudiCityLoading ||
            getServicesLoading ||
            getServiceProvidersLoading) && <Loader />}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Service Providers List</h3>
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="secondary"
              backgroundColor={"green"}
              startIcon={<AddIcon />}
              onClick={() => navigate("/dashboard/service-provider/add")}
            >
              Add Service Provider
            </Button>
          </Box>

          <Box
            sx={{
              margin: "20px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead}>
              {isSuccess && getServiceProducts?.length > 0 ? (
                getServiceProducts?.map((row) => {
                  return (
                    <StyledTableRow key={row?.uuid}>
                      <StyledTableCell>
                        <Box>
                          <img
                            src={row?.images[0]?.image}
                            alt="product"
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{row?.name}</StyledTableCell>
                      <StyledTableCell>
                        {getServiceName(row?.service)?.serviceName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getServiceProviderName(row?.serviceProvider)?.name}
                      </StyledTableCell>
                      <StyledTableCell>{row?.address}</StyledTableCell>
                      <StyledTableCell>
                        {getSaudiCityName(row?.city)?.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {maskingPhoneNumber(row?.phone_number)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {maskingPhoneNumber(row?.whatsapp_number)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.details?.en?.qualification}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.details?.ar?.qualification}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.details?.en?.specialty}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.details?.ar?.specialty}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.details?.en?.details}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.details?.ar?.details}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <EditIcon
                              sx={{ fontSize: "20px", color: "#07bc0c" }}
                              onClick={() => {
                                navigate(
                                  `/dashboard/service-provider/edit/${row.uuid}`
                                );
                              }}
                            />
                          </Tooltip>
                          {deleteServiceLoading &&
                          row?.uuid === deleteValue.uuid ? (
                            <MoonLoader color="#000" size={16} />
                          ) : (
                            <Tooltip title="Delete" placement="top">
                              <DeleteIcon
                                sx={{ fontSize: "20px", color: "red" }}
                                onClick={() => {
                                  handleDelete(row?.uuid);
                                  setDeleteValue(row);
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length / 2}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {getServiceProducts?.length === 0
                        ? "No records found"
                        : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                margin: "1px 0 0",
                padding: "15px 10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#49464f",
                    // removed when pagination is added
                    marginRight: "10px",
                  }}
                >
                  Total Records: {getServiceProducts?.length}
                </Box>
                {/* <Pagination
              count={Math.ceil(services?.total_count / rowsPerPage)}
              shape="rounded"
              onChange={handleChangePage}
            /> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default ServiceProvidersList;
