import { ThemeProvider } from "@mui/material";
import RootComponent from "./Router";
import theme from "./constants/theme";
import { UserThemeProvider } from "./contexts/ThemeContext";
import { LoaderProvider } from "./contexts/LoaderContext";
import { ToastContainer } from "react-toastify";
import { UserRoleProvider } from "./contexts/RoleContext";

//
function App() {
  return (
    <UserRoleProvider>
      <UserThemeProvider>
        <LoaderProvider>
          <ThemeProvider theme={theme}>
            <ToastContainer hideProgressBar={true} autoClose={2000} />
            <RootComponent />
          </ThemeProvider>
        </LoaderProvider>
      </UserThemeProvider>
    </UserRoleProvider>
  );
}

export default App;
