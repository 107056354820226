import { styled } from "@mui/material/styles";
import React, { useRef } from 'react';

// MUI Components Import
import { Box, Grid, Typography } from '@mui/material';

// Icons Import
import AddPhoto from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';

function ImgUploader({ onImageUpload, productImages, setProductImages, isEdit }) {

    const imgUpload = useRef();

    const selectFiles = () => {
        imgUpload.current.click();
    };


    const handleImageUpload = (e) => {
        const files = e.target.files;
        setProductImages([...productImages, ...files]);
    };

    const handleRemoveImage = (indexToRemove) => {
        setProductImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    return (
        <>
            <Wrapper container>
                <Uploader item md={1} onClick={() => selectFiles()}>
                    <input type="file" accept="image/*" multiple ref={imgUpload} style={{ display: 'none' }} onChange={handleImageUpload} id="imageInput" />
                    <AddPhoto sx={{ fontSize: '25px' }} />
                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2A3F54' }}>
                        Add Images*
                    </Typography>
                </Uploader>
                <Grid item md={11}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {productImages?.map((img, index) => (
                            <>
                                <ImgDisplay key={index} sx={{
                                    background: `url(${Object.keys(img).includes('image') ? img.image : URL.createObjectURL(img)
                                        }) center/cover no-repeat`
                                }} />
                                <CancelIcon sx={{ fontSize: "20px", position: "relative", right: "20px", cursor: "pointer" }}
                                    onClick={() => handleRemoveImage(index)}
                                />
                            </>
                        ))
                        }
                    </div>
                </Grid>
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}))

const Uploader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "90px",
    cursor: "pointer"
}))

const ImgDisplay = styled(Box)(() => ({
    width: '100px',
    height: '90px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    borderRadius: '10px',
}))

export default ImgUploader