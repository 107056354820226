import { Box, Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { SubHeading } from "../../../../../components/Heading";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/MUITable";
import PrimaryInput from "../../../../../components/PrimaryInput/PrimaryInput";
import {
  useDispatchDeliveryNoteMutation,
  useGetDeliveryNotesQuery,
  useUpdateDeliveryNoteMutation,
} from "../../../../../redux/api/rfqsApiSlice";
import { onKeyDown } from "../../../../../utils";
import Heading from "../../../components/Heading";
// Formik
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { RFQSchema } from "./validations/RFQSchema";

const tableHead = [
  "#",
  "Product Image",
  "Product SKU",
  "Product Name",
  "QTY Ordered",
  "DN Qty",
  "Qty Due",
];

const headerData = {
  heading: "Delivery Detail",
  subheading: "",
};

const RFQDeliveryNote = () => {
  const navigate = useNavigate();
  const { rfq_id } = useParams();

  const [formType, setFormType] = React.useState("");
  const [formValues, setFormValues] = React.useState({
    shipmentNo: "",
    supplierName: "",
    dnNo: "",
    dnDate: "",
    location: "",
    vehicleNo: "",
    driverName: "",
    contactNo: "",
    trackingNo: "",
    products: [],
  });

  // todo: GET DELIVERY NOTE
  const { data: getDeliveryNote, isLoading: getDeliveryNoteLoading } =
    useGetDeliveryNotesQuery({ id: rfq_id });

  console.log("getDeliveryNote", getDeliveryNote?.rfq?.status_display);

  useEffect(() => {
    if (getDeliveryNote) {
      const temp = getDeliveryNote?.products?.map((item) => {
        return {
          image: item?.product?.images[0]?.image,
          productName: item?.product?.commons?.en?.productName,
          qtyOrdered: Number(item?.qouted_quantity),
          dnQty:
            Number(item?.due_quantity) || Number(item?.qouted_quantity) || "",
          rfqPId: item?.rfqPId,
          sku: item?.product?.skU,
        };
      });

      setFormValues({
        shipmentNo: getDeliveryNote?.shipmentNo,
        supplierName: getDeliveryNote?.supplierName,
        dnNo: getDeliveryNote?.dnNo,
        dnDate: new Date(getDeliveryNote?.dnDate).toLocaleString(),
        location: getDeliveryNote?.location,
        vehicleNo: getDeliveryNote?.vehicleNo,
        driverName: getDeliveryNote?.driverName,
        contactNo: getDeliveryNote?.contactNo,
        trackingNo: getDeliveryNote?.trackingNo,
        products: temp,
      });
    }
  }, [getDeliveryNote]);

  // todo: SEND DELIVERY NOTE API BIND
  const [updateRFQ, { isLoading: updateRFQLoading }] =
    useUpdateDeliveryNoteMutation();

  // todo: DISPATCH DELIVERY NOTE API BIND
  const [dispatchRFQ, { isLoading: dispatchRFQLoading }] =
    useDispatchDeliveryNoteMutation();

  const AddDoctorHandler = async (values) => {
    const products = values.products.map((item) => {
      return {
        rfqPId: item.rfqPId,
        due_quantity: item.dnQty,
      };
    });

    const payload = {
      shipmentNo: values.shipmentNo,
      supplierName: values.supplierName,
      dnNo: values.dnNo,
      dnDate: values.dnDate,
      location: values.location,
      vehicleNo: values.vehicleNo,
      driverName: values.driverName,
      contactNo: values.contactNo,
      trackingNo: values.trackingNo,
      products,
    };

    // todo: SAVE DELIVERY NOTE
    if (formType === "save") {
      try {
        const update = await updateRFQ({
          body: payload,
          id: getDeliveryNote?.uuid,
        });

        if (!update?.error) {
          toast.success("Delivery Saved Successfully!");
          setFormType("");
          // navigate("/dashboard/rfqs/rfq-list");
        }
        if (update?.error) {
          toast.error("Something went wrong", "error");
        }
      } catch (error) {
        console.error("Delivery Saved Error:", error);
        toast.error(error.response.data.message);
      }

      return;
    }

    // todo: DISPATCH DELIVERY NOTE
    if (formType === "dispatch") {
      try {
        const update = await dispatchRFQ({
          id: rfq_id,
        });

        if (!update?.error) {
          toast.success("Dispatch Sended Successfully!");
          setFormType("");
          navigate("/dashboard/rfqs/rfq-list");
        }
        if (update?.error) {
          toast.error("Something went wrong", "error");
        }
      } catch (error) {
        console.error("Dispatch  Sended Error:", error);
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            margin: "0 10px",
          }}
        >
          <Heading data={headerData} />
        </Box>

        <Formik
          initialValues={formValues}
          onSubmit={(values) => {
            AddDoctorHandler(values);
          }}
          validationSchema={RFQSchema}
          enableReinitialize
        >
          {(props) => {
            const { values, touched, errors, handleBlur, handleChange } = props;

            return (
              <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "30px 10px",
                    gap: "20px",
                  }}
                >
                  <Button
                    size="large"
                    style={{ textTransform: "none", borderRadius: "24px" }}
                    variant="outlined"
                    marginleft={5}
                    color="error"
                    onClick={() => navigate("/dashboard/rfqs/rfq-list")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      getDeliveryNote?.rfq?.status_display === "Dispatched" ||
                      getDeliveryNote?.rfq?.status_display === "Delivered"
                    }
                    size="large"
                    style={{ textTransform: "none", borderRadius: "24px" }}
                    variant="outlined"
                    color="warning"
                    backgroundcolor={"green"}
                    onClick={() => {
                      setFormType("save");
                    }}
                  >
                    {formType === "save" && updateRFQLoading ? (
                      <MoonLoader color="#f90" size={20} />
                    ) : (
                      "Preparing"
                    )}
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      getDeliveryNote?.rfq?.status_display === "Dispatched" ||
                      getDeliveryNote?.rfq?.status_display === "Delivered"
                    }
                    size="large"
                    style={{ textTransform: "none", borderRadius: "24px" }}
                    variant="outlined"
                    color="primary"
                    backgroundcolor={"green"}
                    onClick={() => {
                      setFormType("dispatch");
                    }}
                  >
                    {formType === "dispatch" && dispatchRFQLoading ? (
                      <MoonLoader color="#812990" size={20} />
                    ) : (
                      "Dispatch"
                    )}
                  </Button>
                </Box>
                <Box
                  sx={{
                    padding: "20px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    margin: "10px 10px 0 10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Shipment No
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        placeholder="Shipment No"
                        name="shipmentNo"
                        value={values.shipmentNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.shipmentNo && errors.shipmentNo}
                        helperText={touched.shipmentNo && errors.shipmentNo}
                        borderRadius={3}
                      />
                    </Box>
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Supplier Name
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        placeholder="Supplier Name"
                        name="supplierName"
                        value={values.supplierName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.supplierName && errors.supplierName}
                        helperText={touched.supplierName && errors.supplierName}
                        borderRadius={3}
                      />
                    </Box>
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        DN No
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        disabled={true}
                        placeholder="DN No"
                        value={values.dnNo}
                        borderRadius={3}
                        background={"rgba(226, 228, 226, 0.50)"}
                      />
                    </Box>
                  </Box>
                  {/* 2nd */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        DN Date
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        disabled={true}
                        placeholder="DN Date"
                        value={values.dnDate}
                        borderRadius={3}
                        background={"rgba(226, 228, 226, 0.50)"}
                      />
                    </Box>
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Location
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        placeholder="Location"
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.location && errors.location}
                        helperText={touched.location && errors.location}
                        borderRadius={3}
                      />
                    </Box>
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Vehicle No
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        placeholder="Vehicle No"
                        name="vehicleNo"
                        value={values.vehicleNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.vehicleNo && errors.vehicleNo}
                        helperText={touched.vehicleNo && errors.vehicleNo}
                        borderRadius={3}
                      />
                    </Box>
                  </Box>

                  {/* 3rd */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Driver Name
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        placeholder="Driver Name"
                        name="driverName"
                        value={values.driverName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.driverName && errors.driverName}
                        helperText={touched.driverName && errors.driverName}
                        borderRadius={3}
                      />
                    </Box>
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Contact No
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        placeholder="Contact No"
                        name="contactNo"
                        value={values.contactNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.contactNo && errors.contactNo}
                        helperText={touched.contactNo && errors.contactNo}
                        borderRadius={3}
                      />
                    </Box>
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Tracking No
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        placeholder="Tracking No"
                        name="trackingNo"
                        value={values.trackingNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.trackingNo && errors.trackingNo}
                        helperText={touched.trackingNo && errors.trackingNo}
                        borderRadius={3}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ margin: "40px 10px 20px 10px" }}>
                  <h3>
                    Product {values?.products?.length > 1 ? "Lists" : "List"}
                  </h3>
                </Box>

                <Box
                  sx={{
                    margin: "20px 10px 0 10px",
                  }}
                >
                  <MUITable tableHead={tableHead}>
                    {getDeliveryNoteLoading ? (
                      <StyledTableRow>
                        <StyledTableCell
                          sx={{ height: "70px" }}
                          colSpan={tableHead?.length}
                          align="center"
                        >
                          <Box
                            sx={{
                              fontSize: "18px",
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <MoonLoader color="#000" size={20} />
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : values?.products?.length > 0 ? (
                      values?.products?.map((item, index) => {
                        const qtyDue =
                          item.qtyOrdered -
                          (values.products[index]?.dnQty || 0);
                        const newQTY = qtyDue === 0 ? "0" : qtyDue;

                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{index + 1}</StyledTableCell>
                            <StyledTableCell>
                              <Box
                                alt="product_image"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "5px",
                                  marginRight: "8px",
                                  objectFit: "fill",
                                }}
                              >
                                <img
                                  src={item?.image}
                                  alt="Product"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell>{item?.sku}</StyledTableCell>
                            <StyledTableCell>
                              {item?.productName}
                            </StyledTableCell>
                            <StyledTableCell>
                              <PrimaryInput
                                type="text"
                                label=""
                                placeholder="QTY Ordered"
                                readOnly={true}
                                value={item?.qtyOrdered}
                                sx={{
                                  width: "180px",
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <PrimaryInput
                                type="number"
                                label=""
                                placeholder="DN Qty"
                                name={`products[${index}].dnQty`}
                                value={values.products[index]?.dnQty || ""}
                                onChange={(e) => {
                                  if (e.target.value > item.qtyOrdered) {
                                    e.target.value = item.qtyOrdered;
                                  }
                                  handleChange(e, index, "dnQty");
                                }}
                                error={
                                  touched?.products?.[index]?.dnQty &&
                                  errors?.products?.[index]?.dnQty
                                }
                                helperText={
                                  touched?.products?.[index]?.dnQty &&
                                  errors?.products?.[index]?.dnQty
                                }
                                onBlur={handleBlur}
                                sx={{
                                  width: "180px",
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <PrimaryInput
                                type="text"
                                label=""
                                placeholder="Qty Due"
                                readOnly={true}
                                value={newQTY}
                                sx={{
                                  width: "180px",
                                }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    ) : null}
                  </MUITable>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));
export default RFQDeliveryNote;
