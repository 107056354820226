import { apiSlice } from "./apiSlice";

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: ({ limit, offset }) => {
        return {
          url: `customers?limit=${limit}&offset=${offset}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
      },
      providesTags: ["Customer"],
    }),
  }),
});

export const { useGetCustomerQuery } = customerApiSlice;