import * as Yup from "yup";

export const RFQSchema = Yup.object().shape({
  shipmentNo: Yup.string().required("Shipment No is required"),
  supplierName: Yup.string().required("Supplier Name is required"),
  location: Yup.string().required("Location is required"),
  vehicleNo: Yup.string().required("Vehicle No is required"),
  driverName: Yup.string().required("Driver Name is required"),
  contactNo: Yup.string().required("Contact No is required"),
  trackingNo: Yup.string().required("Tracking No is required"),
  dnNo: Yup.string(),
  dnDate: Yup.string(),
  products: Yup.array().of(
    Yup.object().shape({
      image: Yup.string(),
      productName: Yup.string(),
      qtyOrdered: Yup.number(),
      dnQty: Yup.number().required("Delivery Quantity is required"),
    })
  ),
});
