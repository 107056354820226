import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";

// MUI Components import
import {
  Box,
  Button,
  Container,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// Components Import
import Heading from "../../../components/Heading";

// Icons Import
import SearchIcon from "@mui/icons-material/Search";

// React Toastify Imports
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../../constants/api";

const headerData = {
  heading: "Vendors List",
  subheading: "Keep track of your vendors",
};

function VendorsList() {
  const token = window.localStorage.getItem("user-token");

  const [loading, isLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [updating, setUpdating] = useState(false)

  const [searchTerm, setSearchTerm] = useState("");
  const [orderRange, setOrderRange] = useState("");
  const [spendingFilter, setSpendingFilter] = useState("");



  const fetchVendorsList = () => {
    setSearchTerm("");
    setOrderRange("");
    setSpendingFilter("");
    isLoading(true);
    axios
      .get(`${baseUrl}/mv/api/vendors`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setVendors(response.data);
        isLoading(false);
      })
      .catch((err) => {
        isLoading(false);
      });
  };

  const setVendorState = (state, id) => {
    setUpdating(true)
    axios
      .post(
        `${baseUrl}/mv/api/user/state`,
        {
          profId: id,
          state: state,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        fetchVendorsList(response.data);
        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
      });
  }
  const searchVendor = () => {
    isLoading(true);
    axios
      .post(
        `${baseUrl}/mv/api/vendors`,
        {
          search_term: searchTerm === "" ? null : searchTerm,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setVendors(response.data);
        isLoading(false);
      })
      .catch((err) => {
        isLoading(false);
      });
  };

  const applyFilters = () => {
    isLoading(true);
    let payload = {};
    const start_OrderRange = orderRange.split("-")[0];
    const end_OrderRange = orderRange.split("-")[1];
    const start_SpendingFilter = spendingFilter.split("-")[0];
    const end_SpendingFilter = spendingFilter.split("-")[1];
    if (orderRange !== "") {
      payload.no_order_start = start_OrderRange;
      payload.no_order_end = end_OrderRange;
    }
    if (spendingFilter !== "") {
      payload.spent_start = start_SpendingFilter;
      payload.spent_end = end_SpendingFilter;
    }
    axios
      .post(`${baseUrl}/mv/api/vendors`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setVendors(response.data);
        isLoading(false);
      })
      .catch((err) => {
        isLoading(false);
      });
  };

  useEffect(() => {
    fetchVendorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />
        {/* <SearchNav>
          <Box
            sx={{
              width: "30vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by name, email, phone"
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "90%", left: "30px" }}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <Button onClick={() => searchVendor()}>Search</Button>
          </Box>
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Dropdown size="small" fullWidth>
              <InputLabel id="order-range-label">Order range</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="order-range-label"
                id="order-range-select"
                value={orderRange}
                label="Order Range"
                onChange={(e) => setOrderRange(e.target.value)}
              >
                <MenuItem value="0-10">Less than 10 Orders</MenuItem>
                <MenuItem value="11-50">11 - 50 Orders</MenuItem>
                <MenuItem value="51-100">51 - 100 Orders</MenuItem>
                <MenuItem value="101-500">More than 100 Orders</MenuItem>
              </Select>
            </Dropdown>
            <Dropdown size="small" fullWidth>
              <InputLabel id="spending-filter-label">
                Spending filter
              </InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="spending-filter-label"
                id="spending-filter-select"
                value={spendingFilter}
                label="Spending filter"
                onChange={(e) => setSpendingFilter(e.target.value)}
              >
                <MenuItem value="0-50000">Less than 50,000 SAR</MenuItem>
                <MenuItem value="50001-200000">50,001 - 200,000 SAR</MenuItem>
                <MenuItem value="200000-500000">More than 200,000 SAR</MenuItem>
              </Select>
            </Dropdown>
            <Button onClick={() => applyFilters()}>Apply</Button>
            <Button onClick={() => fetchVendorsList()}>Reset</Button>
          </Box>
        </SearchNav> */}
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>Name</TableHeadings>
                <TableHeadings>Email</TableHeadings>
                <TableHeadings>Phone</TableHeadings>
                <TableHeadings>Registered At</TableHeadings>
                <TableHeadings>Action</TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow align="center">
                  <TableContent colSpan={8}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : vendors.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={5} align="center">
                    No data to show
                  </TableContent>
                </TableRow>
              ) : (
                vendors.map((item, index) => (
                  <TableRow key={index}>
                    <TableContent>{index + 1}</TableContent>
                    <TableContent>{item.fullname}</TableContent>
                    <TableContent>{item.email}</TableContent>
                    <TableContent sx={{ width: "15%" }}>
                      {item.phone_number}
                    </TableContent>
                    <TableContent>
                      {new Date(item.created_at).toLocaleString()}
                    </TableContent>
                    <TableContent>
                      <Box
                        key={item.profId}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          padding: '10px 0',
                        }}
                      >
                        <Switch
                          checked={item?.is_active}
                          onChange={() => setVendorState(!item?.is_active, item.profId)}
                          disabled={updating}
                          color="primary"
                        />
                      </Box>

                    </TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Dropdown = styled(FormControl)(() => ({
  width: "100%",
  border: "1px solid #EFEFEF",
  marginTop: "5px",
  backgroundColor: "#fff",
  borderRadius: "6px",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default VendorsList;
