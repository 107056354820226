import React, { useEffect, useState } from "react";
import Heading from "../../../components/Heading";

// MUI Components import
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";

// React Spinners
import { MoonLoader } from "react-spinners";

// Icons Import
import Loader from "../../../../../components/Loaders/Loader";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/MUITable";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetRFQDetailQuery,
  useUpdateRFQMutation,
} from "../../../../../redux/api/rfqsApiSlice";
import RFQInputField from "./Component/RFQInputField";

// Date Picker imports
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import CustomChip from "../../../../../components/CustomChip";

const headerData = {
  heading: "Edit RFQ",
  subheading: "",
};

function RfqEdit() {
  const navigate = useNavigate();

  const { rfq_id } = useParams();

  const [rfqDetail, setRFQDetail] = useState();
  const [responsePaymentTerms, setResponsePaymentTerms] = useState("");
  const [responseTermsConditions, setResponseTermsConditions] = useState("");
  const [responseNotes, setResponseNotes] = useState("");
  const [productQuantities, setProductQuantities] = useState({});
  const [productTotalPrice, setProductTotalPrice] = useState({});
  const [productDeliveryDates, setProductDeliveryDates] = useState({});
  const [productResponseNotes, setProductResponseNotes] = useState({});
  const [clickedButton, setClickedButton] = useState(null);
  const [totalEstimatedPrice, setTotalEstimatedPrice] = useState(0);

  const handleProductQuantityChange = (id, value) => {
    setProductQuantities((prev) => ({ ...prev, [id]: value }));
  };

  const handleProductTotalPriceChange = (id, value) => {
    setProductTotalPrice((prev) => ({ ...prev, [id]: value }));
  };

  const handleProductDeliveryDateChange = (id, date) => {
    setProductDeliveryDates((prev) => ({ ...prev, [id]: date }));
  };

  const handleProductResponseNoteChange = (id, value) => {
    setProductResponseNotes((prev) => ({ ...prev, [id]: value }));
  };

  // todo: GET RFQ DETAIL API BIND
  const { data, isLoading } = useGetRFQDetailQuery(rfq_id);

  // todo: UPDATE RFQ API BIND
  const [updateRFQ, { isLoading: updateRFQLoading }] = useUpdateRFQMutation();

  const updateRFQHandler = async ({ rfq_id, status }) => {
    // const payload = {
    //     status: status,
    //     responsePaymentTerms: responsePaymentTerms,
    //     responseTermsAndConditions: responseTermsConditions,
    //     responesNotes: responseNotes,
    //     products: rfqDetail.products.map((product) => ({
    //         rfqPId: product.rfqPId,
    //         qouted_quantity: productQuantities[product.rfqPId] !== undefined ? productQuantities[product.rfqPId] : product.quantity,
    //         qouted_price: productTotalPrice[product.rfqPId] !== undefined ? productTotalPrice[product.rfqPId] : product.price,
    //         deliveryDate: productDeliveryDates[product.rfqPId] || product.deliveryDate,
    //         responseNotes: productResponseNotes[product.rfqPId] || '',
    //     })),
    // };

    const payload = {
      status: status,
      responsePaymentTerms: responsePaymentTerms,
      responseTermsAndConditions: responseTermsConditions,
      responseNotes: responseNotes,
      products: rfqDetail.products.map((product) => {
        const quantityStr =
          productQuantities[product.rfqPId]?.replace(/,/g, "") ||
          String(product.quantity);
        const quotedPriceStr =
          productTotalPrice[product.rfqPId]?.replace(/,/g, "") ||
          String(product.price);
        const qouted_quantity = parseFloat(quantityStr);
        const qouted_price = parseFloat(quotedPriceStr);

        return {
          rfqPId: product.rfqPId,
          qouted_quantity: qouted_quantity,
          qouted_price: qouted_price,
          deliveryDate:
            productDeliveryDates[product.rfqPId] || product.deliveryDate,
          responseNotes: productResponseNotes[product.rfqPId] || "",
        };
      }),
    };

    try {
      const update = await updateRFQ({
        body: payload,
        id: rfq_id,
      });

      if (!update?.error) {
        toast.success("RFQ Request submitted successfully!");
        navigate("/dashboard/rfqs/rfq-list");
      } else {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleUpdateRFQ = async (rfq_id, status, buttonType) => {
    setClickedButton(buttonType);
    await updateRFQHandler({ rfq_id, status });
    setClickedButton(null); // Reset after the operation
  };

  useEffect(() => {
    if (data) {
      setRFQDetail(data);
    }
  }, [data]);

  useEffect(() => {
    if (rfqDetail?.products) {
      const total = rfqDetail.products.reduce((acc, product) => {
        // Remove commas and parse quantity and quotedPrice as numbers
        const quantityStr =
          productQuantities[product.rfqPId]?.replace(/,/g, "") || "0";
        const quotedPriceStr =
          productTotalPrice[product.rfqPId]?.replace(/,/g, "") || "0";
        const quantity = parseFloat(quantityStr);
        const quotedPrice = parseFloat(quotedPriceStr);

        // Calculate total for the current product
        return acc + quotedPrice * quantity;
      }, 0);
      setTotalEstimatedPrice(total);
    }
  }, [rfqDetail, productQuantities, productTotalPrice]);

  const handleInputChange = (id, e, onChange) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/,/g, "");

    // Allow clearing the input field entirely
    if (inputValue === "" || /^\d{0,10}(\.\d{0,2})?$/.test(inputValue)) {
      // Format the input value with commas
      const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      onChange(id, formattedValue); // Call the onChange function with id and formatted value
    }
  };

  const handleBlur = (id, value, onChange) => {
    // If the input value is not empty and does not contain a decimal point, add ".00" at the end
    if (value && !/\./.test(value)) {
      onChange(id, value + ".00");
    }
  };

  const tableHead = [
    "#",
    "Product Image",
    "Product SKU",
    "Product Name",
    "Actual Quantity",
    "Quoted Quantity",
    "Actual Price",
    "Quoted Price",
    "Delivery Location",
    "Delivery Date",
    "Notes",
    "Response Notes",
  ];

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Heading data={headerData} />
        </Box>
        <RfqNavWrapper>
          <Grid
            container
            my={1}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid
              item
              md={12}
              display={"flex"}
              justifyContent={"start"}
              gap={"15px"}
            >
              <Typography>RFQ Staus:</Typography>
              <Typography>
                {<CustomChip label={rfqDetail?.status_display} />}
              </Typography>
            </Grid>
            <RFQInputField
              label="RFQ ID"
              size={2.8}
              value={rfqDetail?.rfqId}
              disabled={true}
            />
            <RFQInputField
              label="RFQ Date:"
              size={2.8}
              value={new Date(rfqDetail?.rfqDate).toLocaleString()}
              disabled={true}
            />
            <RFQInputField
              label="RFQ Closing Date:"
              size={2.8}
              value={new Date(rfqDetail?.rfqCloseDate).toLocaleString()}
              disabled={true}
            />
            <RFQInputField
              label="Delivery Location:"
              size={2.8}
              value={rfqDetail?.deliveryLocation}
              disabled={true}
            />
            <RFQInputField
              label="Payment Terms:"
              size={3.8}
              value={rfqDetail?.paymentTerms}
              disabled={true}
            />
            <RFQInputField
              label="Terms and Conditions:"
              size={3.8}
              value={rfqDetail?.termsAndConditions}
              disabled={true}
            />
            <RFQInputField
              label="Notes:"
              size={3.8}
              value={rfqDetail?.notes}
              disabled={true}
            />
            <Grid item md={3.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Response Payment Terms:
              </Typography>
              <StyledTextarea
                value={responsePaymentTerms}
                onChange={(e) => setResponsePaymentTerms(e.target.value)}
              />
            </Grid>
            <Grid item md={3.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Response Terms and Conditions:
              </Typography>
              <StyledTextarea
                value={responseTermsConditions}
                onChange={(e) => setResponseTermsConditions(e.target.value)}
              />
            </Grid>
            <Grid item md={3.8}>
              <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                Response Notes:
              </Typography>
              <StyledTextarea
                value={responseNotes}
                onChange={(e) => setResponseNotes(e.target.value)}
              />
            </Grid>
          </Grid>
        </RfqNavWrapper>
        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead}>
              {rfqDetail?.products.length > 0 ? (
                rfqDetail?.products?.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          alt="product_image"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "5px",
                            marginRight: "8px",
                            objectFit: "fill",
                          }}
                        >
                          <img
                            src={row?.product?.images[0].image}
                            alt="Product"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{row?.product?.skU}</StyledTableCell>
                      <StyledTableCell>{row?.product?.commons?.en?.productName}</StyledTableCell>
                      <StyledTableCell>{row?.quantity}</StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          size="small"
                          color="primary"
                          value={productQuantities[row.rfqPId] || ""}
                          onChange={(e) =>
                            handleInputChange(
                              row.rfqPId,
                              e,
                              handleProductQuantityChange
                            )
                          }
                          onBlur={() =>
                            handleBlur(
                              row.rfqPId,
                              productQuantities[row.rfqPId],
                              handleProductQuantityChange
                            )
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {parseFloat(row?.price || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        SAR
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          size="small"
                          color="primary"
                          value={productTotalPrice[row.rfqPId] || ""}
                          onChange={(e) =>
                            handleInputChange(
                              row.rfqPId,
                              e,
                              handleProductTotalPriceChange
                            )
                          }
                          onBlur={() =>
                            handleBlur(
                              row.rfqPId,
                              productTotalPrice[row.rfqPId],
                              handleProductTotalPriceChange
                            )
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row?.deliveryLocation}</StyledTableCell>
                      <StyledTableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box components={["DatePicker"]} size="small">
                            <DatePicker
                              value={dayjs(
                                productDeliveryDates[row.rfqPId] ||
                                  row.deliveryDate
                              )}
                              onChange={(date) =>
                                handleProductDeliveryDateChange(
                                  row.rfqPId,
                                  date
                                )
                              }
                            />
                          </Box>
                        </LocalizationProvider>
                      </StyledTableCell>
                      <StyledTableCell>{row?.notes}</StyledTableCell>
                      <StyledTableCell>
                        <StyledTextarea
                          value={productResponseNotes[row.rfqPId] || ""}
                          onChange={(e) =>
                            handleProductResponseNoteChange(
                              row.rfqPId,
                              e.target.value
                            )
                          }
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {rfqDetail?.products?.length === 0
                        ? "No records found"
                        : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
          </Box>
          <ButtonWrapper container>
            <Grid
              item
              md={6}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Total Estimated Price (SAR):
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "800",
                  paddingLeft: "10px",
                }}
              >
                {totalEstimatedPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                SAR
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              display={"flex"}
              justifyContent={"end"}
              gap={"20px"}
            >
              <SaveBTN onClick={() => handleUpdateRFQ(rfq_id, 3, "reject")}>
                {clickedButton === "reject" &&
                (isLoading || updateRFQLoading) ? (
                  <MoonLoader color="#fff" size={20} />
                ) : (
                  "Reject"
                )}
              </SaveBTN>
              <SaveBTN onClick={() => handleUpdateRFQ(rfq_id, 2, "accept")}>
                {clickedButton === "accept" &&
                (isLoading || updateRFQLoading) ? (
                  <MoonLoader color="#fff" size={20} />
                ) : (
                  "Accept"
                )}
              </SaveBTN>
            </Grid>
          </ButtonWrapper>
        </Box>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const RfqNavWrapper = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
  gap: "20px",
}));

const StyledTextarea = styled("textarea")(() => ({
  background: "#fff",
  borderRadius: "8px",
  fontSize: "16px",
  margin: "8px 0",
  height: "80px",
  width: "100%",
  border: "1px solid #C9CFD2",
}));

const ButtonWrapper = styled(Grid)(() => ({
  width: "100%",
  backgroundColor: "#fff",
  padding: "20px",
  display: "flex",
  alignItems: "center",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

export default RfqEdit;
