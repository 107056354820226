import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Grid,
  styled,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  position: "relative",
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#9ccbce" : "#fff",
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  borderRadius: "12px",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#f5f5f5" : "#f6f6f6",
  padding: grid,
  width: "100%",
});

const SectionManager = ({ sections, setSections, availableSections, addSection, removeSection, handleContentChange }) => {

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result.map((item, index) => ({
            ...item,
            index: index
        }));
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        const reorderedSections = reorder(sections, source.index, destination.index);
        setSections(reorderedSections);
    };

    const handleAddSection = (event) => {
        const sectionId = event.target.value;
        const sectionToAdd = availableSections.find(section => section.id === sectionId);
        addSection(sectionToAdd);
    };

    return (
        <BoxWrapper container my={2}>
            <Grid item md={12} mt={1} mb={3} sx={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: "30px"}}>
                <Typography sx={{fontSize: "12px"}}>
                    Drag and Drop sections to rearrange!
                </Typography>
                <FormControl size="small">
                    <InputLabel id="section">Select a Section</InputLabel>
                    <Select
                        label="Add Section"
                        variant="outlined"
                        style={{ width: '400px' }}
                        onChange={handleAddSection}
                    >   
                        { availableSections?.length > 0 ?
                            availableSections.map(section => (
                                <MenuItem key={section.id} value={section.id}>
                                    {section.content}
                                </MenuItem>
                            ))
                            :
                            <MenuItem disabled>No options to select</MenuItem>
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {sections?.map((item, index) => {
                                    const Component = item.component;
                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Component 
                                                        state={item.state}
                                                        handleContentChange={handleContentChange}
                                                        removeSection={() => removeSection(item)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>

        </BoxWrapper>
    );
};


// const SectionManager = ({
//   sections,
//   setSections,
//   availableSections,
//   addSection,
//   removeSection,
//   handleContentChange,
// }) => {
//   const reorder = (list, startIndex, endIndex) => {
//     const result = Array.from(list);
//     const [removed] = result.splice(startIndex, 1);
//     result.splice(endIndex, 0, removed);
//     return result.map((item, index) => ({
//       ...item,
//       index: index,
//     }));
//   };

//   const move = (source, destination, droppableSource, droppableDestination) => {
//     const sourceClone = Array.from(source);
//     const destClone = Array.from(destination);
//     const [removed] = sourceClone.splice(droppableSource.index, 1);
//     destClone.splice(droppableDestination.index, 0, removed);
//     const result = {};
//     result[droppableSource.droppableId] = sourceClone;
//     result[droppableDestination.droppableId] = destClone;
//     return result;
//   };

//   const onDragEnd = (result) => {
//     const { source, destination } = result;
//     if (!destination) return;
//     const sInd = +source.droppableId;
//     const dInd = +destination.droppableId;
//     if (sInd === dInd) {
//       const items = reorder(sections[sInd], source.index, destination.index);
//       const newState = [...sections];
//       newState[sInd] = items;
//       setSections(newState);
//     } else {
//       const result = move(sections[sInd], sections[dInd], source, destination);
//       const newState = [...sections];
//       newState[sInd] = result[sInd].map((item, index) => ({ ...item, index }));
//       newState[dInd] = result[dInd].map((item, index) => ({ ...item, index }));
//       setSections(newState.filter((group) => group.length > 0));
//     }
//   };

//   const handleAddSection = (event) => {
//     const sectionId = event.target.value;
//     const sectionToAdd = availableSections.find(
//       (section) => section.id === sectionId
//     );
//     addSection(sectionToAdd);
//   };

//   return (
//     <BoxWrapper container my={2}>
//       <Grid
//         item
//         md={12}
//         mt={1}
//         mb={3}
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           gap: "30px",
//         }}
//       >
//         <Typography sx={{ fontSize: "12px" }}>
//           Drag and Drop sections to rearrange!
//         </Typography>
//         <FormControl size="small">
//           <InputLabel id="section">Select a Section</InputLabel>
//           <Select
//             label="Add Section"
//             variant="outlined"
//             style={{ width: "400px" }}
//             onChange={handleAddSection}
//           >
//             {availableSections?.length > 0 ? (
//               availableSections.map((section) => (
//                 <MenuItem key={section.id} value={section.id}>
//                   {section.content}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled>No options to select</MenuItem>
//             )}
//           </Select>
//         </FormControl>
//       </Grid>
//       <Grid item md={12}>
//         <DragDropContext onDragEnd={onDragEnd}>
//           {sections?.map((el, ind) => (
//             <Droppable key={ind} droppableId={`${ind}`}>
//               {(provided, snapshot) => (
//                 <div
//                   ref={provided.innerRef}
//                   style={getListStyle(snapshot?.isDraggingOver)}
//                   {...provided?.droppableProps}
//                 >
//                   {Array.isArray(el) ? (
//                     el.map((item, index) => {
//                       const Component = item.component;
//                       return (
//                         <Draggable
//                           key={item.id}
//                           draggableId={item.id}
//                           index={index}
//                         >
//                           {(provided, snapshot) => (
//                             <div
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                               {...provided.dragHandleProps}
//                               style={getItemStyle(
//                                 snapshot.isDragging,
//                                 provided.draggableProps.style
//                               )}
//                             >
//                               <Component
//                                 state={item.state}
//                                 handleContentChange={handleContentChange}
//                                 removeSection={() => removeSection(item)}
//                               />
//                             </div>
//                           )}
//                         </Draggable>
//                       );
//                     })
//                   ) : (
//                     <div>No items to display</div>
//                   )}
//                   {provided.placeholder}
//                 </div>
//               )}
//             </Droppable>
//           ))}
//         </DragDropContext>
//       </Grid>
//     </BoxWrapper>
//   );
// };

const BoxWrapper = styled(Grid)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

export default SectionManager;
