import React from "react";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { SiPayloadcms } from "react-icons/si";
import { BsBuildings } from "react-icons/bs";
import InventoryIcon from "@mui/icons-material/DashboardOutlined";
import { Box } from "@mui/material";

const SuperAdminSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const current_loc = location.pathname.split("/").pop();

  const menuItemsOne = [{ label: "Dashboard", path: "" }];

  const menuItemsTwo = [
    {
      label: "Vendors List",
      path: "/dashboard/vendors/vendors-list",
      activePath: "vendors-list",
    },
  ];

  const menuItemsThree = [
    {
      label: "Home",
      path: "/dashboard/cms/home",
      activePath: "home",
    },
    {
      label: "Header",
      path: "/dashboard/cms/header",
      activePath: "header",
    },
    {
      label: "Footer",
      path: "/dashboard/cms/footer",
      activePath: "footer",
    },
    {
      label: "Static Pages",
      path: "/dashboard/cms/static-pages",
      activePath: "static-pages",
    },
    {
      label: "Blogs",
      path: "/dashboard/cms/blogs",
      activePath: "blogs",
    },
    {
      label: "Features",
      path: "/dashboard/cms/features",
      activePath: "features",
    },
    {
      label: "Styling",
      path: "/dashboard/cms/styling",
      activePath: "styling",
    },
    {
      label: "Brand Partners",
      path: "/dashboard/cms/partners",
      activePath: "partners",
    },
  ];

  const menuItemsFour = [
    {
      label: "Add Banners",
      path: "/dashboard/settings/banners",
      activePath: "banners",
    },
    {
      label: "Configurations",
      path: "/dashboard/settings/config",
      activePath: "config",
    },
    {
      label: "Theme",
      path: "/dashboard/settings/theme",
      activePath: "theme",
    },
  ];

  return (
    <>
      {menuItemsOne.map((item) => (
        <StyledMenuItemZero
          key={item.path}
          onClick={() => navigate(item.path)}
          active={
            current_loc === item.path ||
            (current_loc === "dashboard" && item.path === "")
          }
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <InventoryIcon
              style={{
                fontSize: "16px",
                width: "16px",
                height: "16px",
              }}
            />
            <span>{item.label}</span>
          </Box>
        </StyledMenuItemZero>
      ))}

      <StyledSubMenu icon={<BsBuildings />} label="Vendors">
        {menuItemsTwo.map((item) => (
          <StyledMenuItem
            key={item.path}
            onClick={() => navigate(item.path)}
            active={current_loc === item.activePath}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </StyledSubMenu>
      <StyledSubMenu icon={<SiPayloadcms />} label="CMS">
        {menuItemsThree.map((item) => (
          <StyledMenuItem
            key={item.path}
            onClick={() => navigate(item.path)}
            active={current_loc === item.activePath}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </StyledSubMenu>
      <StyledSubMenu icon={<SettingsIcon />} label="Settings">
        {menuItemsFour.map((item) => (
          <StyledMenuItem
            key={item.path}
            onClick={() => navigate(item.path)}
            active={current_loc === item.activePath}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </StyledSubMenu>
    </>
  );
};

const StyledSubMenu = styled(SubMenu)(() => ({
  ".ps-menu-button": {
    borderRadius: "8px",
    height: "45px !important",
    padding: "20px !important",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ active }) => ({
  width: "unset !important",
  position: "relative",
  borderRadius: "8px",
  padding: "0",
  margin: "5px 20px",
  backgroundColor: active ? "#fff" : "none",
  color: active ? "#000" : "#fff",
  "&:hover": {
    backgroundColor: active ? "#fff" : "rgba(255, 255, 255, 0.1)",
  },
}));

const StyledMenuItemZero = styled(MenuItem)(({ active }) => ({
  width: "unset !important",
  position: "relative",
  borderRadius: "8px",
  padding: "0",
  margin: "5px 20px",
  backgroundColor: active ? "#fff" : "none",
  color: active ? "#000" : "#fff",
  "&:hover": {
    backgroundColor: active ? "#fff" : "rgba(255, 255, 255, 0.1)",
  },
  "& .ps-menu-button": {
    paddingLeft: "10px !important", // Override the left padding
  },
}));

export default SuperAdminSidebar;
