// React Imports
import React, { useEffect, useState } from "react";
// MUI
import { Box, Button, Grid, Typography, styled } from "@mui/material";
// React Quill
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// React Toastify
import { toast } from "react-toastify";
// Redux
import {
  useGetFooterSectionsQuery,
  useUpdateFooterSectionMutation,
} from "../../../../../../redux/api/footerApiSlice";
// Custom
import { MoonLoader } from "react-spinners";

const FooterTextEditorOne = () => {
  const [sectionOne, setSectionOne] = useState("");
  const [sectionTwo, setSectionTwo] = useState("");
  const [updateSection, setUpdateSection] = useState(false);

  // todo: POST && PUT API BIND
  const [updateSections, { isLoading: updateSectionsLoading }] =
    useUpdateFooterSectionMutation();

  const handleSection = async () => {
    const cleanedValue1 = sectionOne.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
    const cleanedValue2 = sectionTwo.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags

    if (!cleanedValue1 || cleanedValue1.trim() === "") {
      toast.warning("Please enter some text to save in section 1");
      return;
    }

    if (!cleanedValue2 || cleanedValue2.trim() === "") {
      toast.warning("Please enter some text to save in section 2");
      return;
    }

    const payload = {
      section_one: sectionOne,
      section_two: sectionTwo,
    };

    try {
      const update = await updateSections({ body: payload });

      const message = updateSection ? "Updated" : "Added";

      if (update) {
        toast.success(`Sections ${message} successfully!`);
      }
      //   if (update?.error) {
      //     toast.error("User not found", "error");
      //   }
    } catch (error) {
      console.error("Update Sections Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const {
    data: getSections,
    // isLoading: getSectionsLoading
  } = useGetFooterSectionsQuery({});

  useEffect(() => {
    if (getSections) {
      setSectionOne(getSections.section_one);
      setSectionTwo(getSections.section_two);
      setUpdateSection(true);
    }
  }, [getSections]);

  //Custom Tool Bar
  const modules = {
    toolbar: [
      [{ header: [1, 2, 4, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        {
          color: ["oranges", "blue", "yellow", "white"],
        },
      ],
      // [{ "code-block": true }],
      ["clean"],
      // [{ background: ["#ff0000", "#00ff00"] }],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "indent",
    "image",
    // "code-block",
    "color",
    // "background",
  ];

  return (
    <>
      <SectionWrapper mt={3}>
        <Box
          sx={{
            marginBottom: "15px",
          }}
        >
          <Heading>Footer Sections</Heading>
        </Box>

        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item md={6}>
            <ReactQuill
              theme="snow"
              value={sectionOne}
              onChange={(e) => setSectionOne(e)}
              placeholder="Type your content for Section 1 here..."
              modules={modules}
              formats={formats}
              style={{ height: "100%", overflow: "hidden" }}
            />
          </Grid>

          <Grid item md={6}>
            <ReactQuill
              theme="snow"
              value={sectionTwo}
              onChange={(e) => setSectionTwo(e)}
              placeholder="Type your content for Section 2 here..."
              modules={modules}
              formats={formats}
              style={{ height: "100%", overflow: "hidden" }}
            />
          </Grid>

          <Grid
            item
            md={12}
            mt={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <SaveBTN onClick={handleSection}>
              {updateSectionsLoading ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : updateSection ? (
                "Update"
              ) : (
                "Save"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

export default FooterTextEditorOne;
