import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function PromotionalBanners({ removeSection }) {

    const navigate = useNavigate()

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <Typography sx={{fontSize: "16px", fontWeight: "600"}}>
                        Promotional Banners
                    </Typography>
                    <Typography sx={{fontSize: "12px", fontWeight: "400"}}>
                        This section displays a carousel featuring promotional banners.
                    </Typography>
                    <Button variant='contained' background="primary" sx={{marginTop: "15px", borderRadius: "10px",textTransform: "capitalize"}}
                        onClick={() => navigate('/dashboard/settings/banners')}
                    >
                        Upload Banners
                    </Button>
                </Grid>
                <Grid item md={12} display={"flex"} justifyContent={"end"}>
                    <Button variant='contained' background="primary" sx={{marginTop: "15px", borderRadius: "10px",textTransform: "capitalize"}}
                        onClick={removeSection}
                    >
                        Remove
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default PromotionalBanners