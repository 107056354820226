import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useLoaderContext } from "../../../../../contexts/LoaderContext";
import axios from "axios";

// Loader Import
import { MoonLoader } from "react-spinners";

// MUI Components Import
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Tooltip,
  Tab,
} from "@mui/material";

// Components Import
import Heading from "../../../components/Heading";

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import AddPhotoIcon from "@mui/icons-material/AddPhotoAlternate";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik & Yup Imports
import { useFormik } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../../../../constants/api";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  testimonial: Yup.string().required("Testimonial is required"),
});

const headerData = {
  heading: "Manage Testimonials",
  subheading: "",
};

function Testimonials() {
  const token = window.localStorage.getItem("user-token");

  const { loading, handleLoader } = useLoaderContext();

  const imgUpload = useRef();

  const selectFiles = () => {
    imgUpload.current.click();
  };

  const [saving, setSaving] = useState(false);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImg] = useState(null);
  const [testimonialsList, setTestimonialsList] = useState([]);
  const [editingTestimonial, setEditingTestimonial] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImg(URL.createObjectURL(file));
    }
  };

  const editTestimonial = (testimonial) => {
    formik.setFieldValue("name", testimonial.name);
    formik.setFieldValue("testimonial", testimonial.testimonial);
    setImage(testimonial.image);
    setPreviewImg(testimonial.image);
    setEditingTestimonial(testimonial);
  };

  const formik = useFormik({
    enableReinitialze: true,
    initialValues: {
      name: editingTestimonial != null ? editingTestimonial.name : "",
      testimonial:
        editingTestimonial != null ? editingTestimonial.arb_name : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (editingTestimonial) {
        handleEdit(editingTestimonial.uuid, values, image);
      } else {
        handleCreate(values);
      }
    },
  });

  const handleCreate = (formValues) => {
    if (!image) {
      toast.error("Please upload an image.");
      return;
    }
    setSaving(true);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("name", formValues.name);
    formData.append("is_active", true);
    formData.append("testimonial", formValues.testimonial);
    axios
      .post(`${baseUrl}/api/testimonial/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Testimonial added successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchTestimonials();
        setImage(null);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleEdit = (id, formValues, image) => {
    setSaving(true);

    const formData = new FormData();
    formData.append("is_active", true);
    formData.append("name", formValues.name);
    formData.append("testimonial", formValues.testimonial);
    if (image !== editingTestimonial.image) {
      formData.append("image", image);
    }
    axios
      .put(`${baseUrl}/api/testimonial/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Testimonial edited successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchTestimonials();
        setImage(null);
        setEditingTestimonial(null);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleDelete = (id) => {
    handleLoader(true);
    axios
      .delete(`${baseUrl}/api/testimonial/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Testimonial deleted successfully!");
        handleLoader(false);
        fetchTestimonials();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        handleLoader(false);
      });
  };

  const fetchTestimonials = () => {
    handleLoader(true);
    axios
      .get(`${baseUrl}/api/testimonials`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTestimonialsList(response.data);
        handleLoader(false);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />
        <ControlWrapper mt={3}>
          <ControlHeading>Create a Testimonial</ControlHeading>
          <Grid
            container
            mt={1}
            spacing={3}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid item md={2}>
              <Label>Name</Label>
              <Input
                name="name"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item md={7}>
              <Label>Testimonial</Label>
              <StyledTextarea
                name="testimonial"
                {...formik.getFieldProps("testimonial")}
                error={
                  formik.touched.testimonial &&
                  Boolean(formik.errors.testimonial)
                }
                helperText={
                  formik.touched.testimonial && formik.errors.testimonial
                }
              />
            </Grid>
            <Grid item md={2} display={"flex"} alignItems={"center"}>
              <Uploader item md={3} onClick={() => selectFiles()}>
                <input
                  type="file"
                  accept="image/*"
                  ref={imgUpload}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageUpload(e)}
                  id="imageInput"
                />
                <AddPhotoIcon sx={{ fontSize: "25px" }} />
                <Typography
                  sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
                >
                  Add Image
                </Typography>
              </Uploader>
              <Grid item md={9}>
                {image ? (
                  <ImgDisplay
                    sx={{
                      background: `url(${previewImage}) center/cover no-repeat`,
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid item md={1} sx={{ display: "flex", justifyContent: "end" }}>
              <SaveBTN onClick={() => formik.handleSubmit()}>
                {saving ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : editingTestimonial ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </SaveBTN>
            </Grid>
          </Grid>
        </ControlWrapper>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>Image</TableHeadings>
                <TableHeadings>Name</TableHeadings>
                <TableHeadings>Testimonial</TableHeadings>
                <TableHeadings>Action</TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableContent colSpan={5}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : testimonialsList?.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={5} align="center">
                    You do not have any Testimonials yet!
                  </TableContent>
                </TableRow>
              ) : (
                testimonialsList?.map((testimonial, index) => (
                  <TableRow key={index}>
                    <TableContent sx={{ width: "1%" }}>
                      {index + 1}
                    </TableContent>
                    <TableContent sx={{ width: "5%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <img
                          src={testimonial?.image}
                          alt="category_image"
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "5px",
                            marginRight: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </TableContent>
                    <TableContent sx={{ width: "10%" }}>
                      {testimonial.name}
                    </TableContent>
                    <TableContent sx={{ width: "85%" }}>
                      {testimonial.testimonial}
                    </TableContent>
                    <TableContent
                      sx={{ display: "flex", gap: "10px", cursor: "pointer" }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <EditIcon
                          sx={{ fontSize: "20px", color: "#000" }}
                          onClick={() => editTestimonial(testimonial)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" placement="top">
                        <DeleteIcon
                          sx={{ fontSize: "20px", color: "red" }}
                          onClick={() => handleDelete(testimonial.uuid)}
                        />
                      </Tooltip>
                    </TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const ControlWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

// TextAreaCSS
const StyledTextarea = styled("textarea")(() => ({
  background: "#fff",
  borderRadius: "5px",
  padding: "5px",
  height: "55px",
  width: "100%",
}));

const ControlHeading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const Uploader = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90px",
  cursor: "pointer",
}));

const ImgDisplay = styled(Box)(() => ({
  width: "100px",
  height: "90px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  borderRadius: "10px",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default Testimonials;
