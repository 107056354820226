import { apiSlice } from "./apiSlice";

export const blogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: ({ limit, offset, staticPage }) => {
        const url = staticPage
          ? `user/pages?limit=${limit}&offset=${offset}`
          : `user/blogs?limit=${limit}&offset=${offset}`;
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Blog"],
    }),

    getBlog: builder.query({
      query: (id) => {
        return {
          url: `blogs/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Blog"],
    }),

    createBlog: builder.mutation({
      query: ({ body, staticPage }) => {
        const url = staticPage ? "pages/create" : "blogs/create";
        return {
          url,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Blog"],
    }),

    updateBlog: builder.mutation({
      query: ({ id, body, staticPage }) => {
        const url = staticPage ? `user/page/${id}` : `user/blog/${id}`;
        return {
          url,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Blog"],
    }),

    deleteBlog: builder.mutation({
      query: ({ id, staticPage }) => {
        const url = staticPage ? `user/page/${id}` : `user/blog/${id}`;
        return {
          url,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Blog"],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useGetBlogQuery,
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
} = blogApiSlice;
