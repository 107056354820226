import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// MUI Components Import
import { Box, Container } from "@mui/material";
import { GiMoneyStack } from "react-icons/gi";
// Components Import
import axios from "axios";
import { IoPeopleSharp } from "react-icons/io5";
import { VscGraph } from "react-icons/vsc";
import iconOne from "../../../../assets/dash-card-icon-one.webp";
import { baseUrl } from "../../../../constants/api";
import { thousandSeparator } from "../../../../utils";
import DashboardCards from "../../Vendor/components/Cards/DashboardCards";
import Heading from "../../components/Heading";
import Navbar from "../../components/Navbar";
import { BsViewStacked } from "react-icons/bs";
import TopSellers from "../components/TopSellers";
import VendorsList from "../components/VendorsList";
import { IoReorderFour } from "react-icons/io5";
import { MdPendingActions } from "react-icons/md";
import { PiStampLight } from "react-icons/pi";
import { TbRubberStampOff } from "react-icons/tb";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaPeopleCarryBox } from "react-icons/fa6";

const headerData = {
  heading: "Admin Dashboard",
  subheading: "",
};

function AdminDashboard() {
  const location = useLocation();
  const current_loc = location.pathname.split("/").pop();
  const token = localStorage.getItem("user-token");

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState();

  const fetchSummaryCounts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/mv/api/cms/stats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      setLoading(false);
      setStats(response?.data);
      setCardData([
        {
          backgroundColor: "#812990",
          icon: iconOne,
          heading: "Total Vendors",
          number: response?.data?.total_vendors,
        },
        {
          backgroundColor: "#812990",
          icon: IoPeopleSharp,
          iconTrue: true,
          heading: "Total Customers",
          number: response?.data?.total_customers,
        },
        {
          backgroundColor: "#812990",
          icon: VscGraph,
          iconTrue: true,
          heading: "Total Sales",
          number: thousandSeparator(response?.data?.total_sales),
        },
        {
          backgroundColor: "#812990",
          icon: GiMoneyStack,
          iconTrue: true,
          heading: "Total Earned",
          number: thousandSeparator(response?.data?.total_earned),
        },
        {
          backgroundColor: "#812990",
          icon: BsViewStacked,
          iconTrue: true,
          heading: "Open RFQs",
          number: thousandSeparator(response?.data?.opened_rfqs),
        },
        {
          backgroundColor: "#812990",
          icon: BsViewStacked,
          iconTrue: true,
          heading: "Total RFQs",
          number: thousandSeparator(response?.data?.total_rfqs),
        },
        {
          backgroundColor: "#812990",
          icon: IoReorderFour,
          iconTrue: true,
          heading: "Total Orders",
          number: thousandSeparator(response?.data?.total_orders),
        },
        {
          backgroundColor: "#812990",
          icon: MdPendingActions,
          iconTrue: true,
          heading: "Total Pending Orders",
          number: thousandSeparator(response?.data?.total_pending),
        },
        // dd
        {
          backgroundColor: "#812990",
          icon: PiStampLight,
          iconTrue: true,
          heading: "Total Approved Orders",
          number: thousandSeparator(response?.data?.total_accepted),
        },
        {
          backgroundColor: "#812990",
          icon: TbRubberStampOff,
          iconTrue: true,
          heading: "Total Rejected Orders",
          number: thousandSeparator(response?.data?.total_rejected),
        },
        {
          backgroundColor: "#812990",
          icon: CiDeliveryTruck,
          iconTrue: true,
          heading: "Total Dispatched Orders",
          number: thousandSeparator(response?.data?.total_dispatched),
        },
        {
          backgroundColor: "#812990",
          icon: FaPeopleCarryBox,
          iconTrue: true,
          heading: "Total Delivered Orders",
          number: thousandSeparator(response?.data?.total_delivered),
        },
      ]);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching demand data:", err);
    }
  };

  useEffect(() => {
    fetchSummaryCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainWrapper maxWidth={false}>
        <Navbar />
        {current_loc === "dashboard" ? (
          <Wrapper maxWidth={false}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Heading data={headerData} />
            </Box>
            <DashboardCards data={cardData} fourCard={true} />
            <TopSellers data={stats?.top_5_sellers} loading={loading} />
            <VendorsList data={stats?.vendors} loading={loading} />
          </Wrapper>
        ) : (
          <Outlet />
        )}
      </MainWrapper>
    </>
  );
}

const MainWrapper = styled(Container)(() => ({
  padding: "0 !important",
  width: "100%",
  height: "auto",
  overflow: "auto",
}));

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default AdminDashboard;
