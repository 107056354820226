import * as Yup from "yup";

export const addDoctorSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string().required("Phone number is required"),
  WhatsApp: Yup.string().required("WhatsApp number is required"),
  city: Yup.object().required("City is required"),
  service: Yup.object().required("Service is required"),
  serviceProvider: Yup.object().required("Provider Type is required"),
  // todo: English and Arabic
  details: Yup.string().required("Details is required"),
  details_ar: Yup.string().required("Arabic Details is required"),
  qualification: Yup.string().required("Qualification is required"),
  qualification_ar: Yup.string().required("Arabic Qualification is required"),
  specialty: Yup.string().required("Specialty is required"),
  specialty_ar: Yup.string().required("Arabic Specialty is required"),
  // Picture is required
  // picture: Yup.mixed().test("fileType", "Picture is required", (value) => {
  //   return (
  //     value instanceof File || (typeof value === "string" && value.length > 0)
  //   );
  // }),
  // Picture is optional
  // picture: Yup.mixed()
  //   .nullable()
  //   .test("fileType", "Invalid file type", (value) => {
  //     return !value || value instanceof File;
  //   }),
  pictures: Yup.array()
    .of(
      Yup.mixed().test("fileType", "Picture is required", function (value) {
        // Check if the value is an instance of File or a non-empty string
        return (
          value instanceof File ||
          (typeof value === "string" && value.length > 0)
        );
      })
    )
    .min(1, "Image is required"),
});
