import { Box, Button, Container, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import AddPhoto from "@mui/icons-material/AddPhotoAlternate";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { onKeyDown } from "../../../../../utils";
import { SubHeading } from "../../../../../components/Heading";
import PrimaryInput from "../../../../../components/PrimaryInput/PrimaryInput";
import Errors from "../../../../../components/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { useLoaderContext } from "../../../../../contexts/LoaderContext";
import axios from "axios";
import { baseUrl } from "../../../../../constants/api";
import { MoonLoader } from "react-spinners";
import {
  useDeleteFeatureMutation,
  useGetFeaturesQuery,
} from "../../../../../redux/api/cmsApiSlice";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/MUITable";
import Loader from "../../../../../components/Loaders/Loader";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import FeatureImg from "../../../../../assets/Features.svg";

const headerData = {
  heading: "Add Features",
  subheading: "Such as Fast Shipping, Easy and secure, 24/7 Support, etc.",
};

const validationSchema = Yup.object({
  title: Yup.string().required("English Title is required"),
  description: Yup.string().required("English Description is required"),
  arTitle: Yup.string().required("Arabic Title is required"),
  arDescription: Yup.string().required("Arabic Description is required"),
  image: Yup.mixed().test("fileType", "Image is required", (value) => {
    return (
      value instanceof File || (typeof value === "string" && value.length > 0)
    );
  }),
});

const tableHead = [
  "#",
  "Image",
  "Title (Eng)",
  "Description (Eng)",
  "Title (Arabic)",
  "Description (Arabic)",
  "Actions",
];

const Features = () => {
  const fileRef = useRef();

  const token = localStorage.getItem("user-token");
  const { loading, handleLoader } = useLoaderContext();

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    arTitle: "",
    arDescription: "",
    image: "",
  });
  const [selectedFeature, setSelectedFeature] = useState("");
  const [editFeature, setEditFeature] = useState(null);

  const resetFormFields = () => {
    setFormValues({
      title: "",
      description: "",
      arTitle: "",
      arDescription: "",
      image: "",
    });
  };

  // todo: GET ALL FEATURES
  const {
    data: getAllFeatures,
    isLoading: getAllFeaturesLoading,
    isSuccess,
    refetch: refetchFeatures,
  } = useGetFeaturesQuery({});

  const AddFeatureHandler = async (data, resetForm) => {
    const formData = new FormData();
    formData.append("en_title", data.title);
    formData.append("en_description", data.description);

    formData.append("ar_title", data.arTitle);
    formData.append("ar_description", data.arDescription);

    if (typeof data.image !== "string") {
      formData.append("image", data.image);
    }

    // todo: UPDATE FEATURE API BIND
    if (editFeature) {
      try {
        handleLoader(true);
        const response = await axios.put(
          `${baseUrl}/api/feature/${editFeature}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );
        if (response?.status === 200) {
          toast.success("Feature updated successfully");
          handleLoader(false);
          refetchFeatures();
          setEditFeature(null);
          resetFormFields();
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
          handleLoader(false);
        }
      }

      return;
    }

    // todo: ADD FEATURE API BIND
    try {
      handleLoader(true);
      const response = await axios.post(`${baseUrl}/api/feature`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (response?.status === 201) {
        toast.success("Feature added successfully");
        handleLoader(false);
        resetForm();
        refetchFeatures();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        handleLoader(false);
      }
    }
  };

  // todo: DELETE FEATURE  API BIND
  const [deleteFeature, { isLoading: deleteLoading }] =
    useDeleteFeatureMutation();

  const deleteFeatureHandle = async (id) => {
    try {
      const delFeature = await deleteFeature(id);

      if (!delFeature?.error) {
        toast.success("Feature deleted successfully!");
        setEditFeature(null);
        resetFormFields();
      }
      if (delFeature?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Delete Feature Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "50%",
            }}
          >
            <Heading data={headerData} />
          </Box>
          <Box
            sx={{
              width: "50%",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "15px 15px 12px 15px",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={FeatureImg}
                alt="features"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "20px",
            background: "#fff",
            borderRadius: "12px",
            margin: "20px 0",
          }}
        >
          <Formik
            initialValues={formValues}
            onSubmit={(values, { resetForm }) => {
              AddFeatureHandler(values, resetForm);
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
              } = props;

              return (
                <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Title (Eng)
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        name="title"
                        placeholder="Title (Eng)"
                        value={values.title}
                        helperText={
                          errors.title && touched.title ? errors.title : ""
                        }
                        error={errors.title && touched.title ? true : false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        borderRadius="4px"
                      />
                    </Box>

                    <Box sx={{ height: "85px", width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Title (Arabic)
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        name="arTitle"
                        placeholder="Title (Arabic)"
                        value={values.arTitle}
                        helperText={
                          errors.arTitle && touched.arTitle
                            ? errors.arTitle
                            : ""
                        }
                        error={errors.arTitle && touched.arTitle ? true : false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        borderRadius="4px"
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Description (Eng)
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        name="description"
                        placeholder="Description (Eng)"
                        value={values.description}
                        helperText={
                          errors.description && touched.description
                            ? errors.description
                            : ""
                        }
                        error={
                          errors.description && touched.description
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        borderRadius="4px"
                        multiline={true}
                        maxRows={3}
                      />
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      <SubHeading
                        sx={{ marginBottom: "5px", fontSize: "12px" }}
                      >
                        Description (Arabic)
                      </SubHeading>
                      <PrimaryInput
                        type="text"
                        label=""
                        name="arDescription"
                        placeholder="Description (Arabic)"
                        value={values.arDescription}
                        helperText={
                          errors.arDescription && touched.arDescription
                            ? errors.arDescription
                            : ""
                        }
                        error={
                          errors.arDescription && touched.arDescription
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        borderRadius="4px"
                        multiline={true}
                        maxRows={3}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <input
                      hidden
                      ref={fileRef}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setFieldValue("image", e.target.files[0]);
                        e.target.value = null;
                      }}
                    />
                    <Box
                      onClick={() => fileRef.current.click()}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "130px",
                        height: "105px",
                        justifyContent: "center",
                      }}
                    >
                      <AddPhoto sx={{ fontSize: "25px" }} />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "#2A3F54",
                        }}
                      >
                        Add Image*
                      </Typography>
                      {errors.image && touched.image && (
                        <Errors sx={{ paddingLeft: "0" }}>
                          {errors.image}
                        </Errors>
                      )}
                    </Box>
                    {/* Showing image */}
                    {values.image && (
                      <Box sx={{ position: "relative", margin: "20px 0 0 0" }}>
                        <img
                          src={
                            typeof values.image === "string"
                              ? values.image
                              : URL.createObjectURL(values.image)
                          }
                          alt="icon"
                          width={80}
                          height={80}
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                        <CancelIcon
                          sx={{
                            fontSize: "20px",
                            position: "absolute",
                            top: "-10px",
                            right: "-9px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFieldValue("image", "");
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      sx={{
                        height: "40px",
                        width: "150px",
                        mt: "23px",
                        borderRadius: "5px",
                      }}
                    >
                      {loading ? (
                        <>
                          <MoonLoader color="#fff" size={20} />
                        </>
                      ) : editFeature ? (
                        "Update"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>

        {/* Table */}
        <Box>
          {getAllFeaturesLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead}>
              {isSuccess && getAllFeatures?.length > 0 ? (
                getAllFeatures?.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={row?.uuid}
                      sx={{
                        borderBottomLeftRadius: "0",
                        borderBottom: "100px",
                      }}
                    >
                      <StyledTableCell>{index + 1}</StyledTableCell>

                      <StyledTableCell>
                        <Box>
                          <img
                            src={row?.image}
                            alt={row?.en_title}
                            width={50}
                            height={50}
                            style={{
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </StyledTableCell>

                      <StyledTableCell>{row?.en_title}</StyledTableCell>

                      <StyledTableCell>{row?.en_description}</StyledTableCell>

                      <StyledTableCell>{row?.ar_title}</StyledTableCell>

                      <StyledTableCell>{row?.ar_description}</StyledTableCell>

                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                          }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <EditIcon
                              sx={{ cursor: "pointer", color: "#07bc0c" }}
                              onClick={() => {
                                setEditFeature(row?.uuid);
                                setFormValues({
                                  title: row?.en_title,
                                  description: row?.en_description,
                                  arTitle: row?.ar_title,
                                  arDescription: row?.ar_description,
                                  image: row?.image,
                                });
                              }}
                            />
                          </Tooltip>
                          {deleteLoading && row?.uuid === selectedFeature ? (
                            <MoonLoader color="#000" size={16} />
                          ) : (
                            <Tooltip title="Delete" placement="top">
                              <DeleteIcon
                                sx={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  setSelectedFeature(row?.uuid);
                                  deleteFeatureHandle(row?.uuid);
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {getAllFeatures?.length === 0 ? "No records found" : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default Features;
