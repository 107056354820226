import React, { useEffect, useState } from "react";
// MUI Components Import
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  styled,
} from "@mui/material";
import { baseUrl } from "../../../../../../constants/api";
// React Toastify Imports
import { toast } from "react-toastify";
// Loader Import
import { MoonLoader } from "react-spinners";
import axios from "axios";

function HolidayNote() {
  const token = localStorage.getItem("user-token");

  const [saving, setSaving] = useState(false);
  const [note, setNote] = useState("");
  const [isActive, setIsActive] = useState(false);

  const fetchPublicNote = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/holiday-note`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      setNote(response.data.holiday_note);
      setIsActive(response.data.is_publish);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPublicNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = async () => {
    if (note.length === 0) {
      toast.error("Please enter a valid note!");
      return;
    }

    setSaving(true);
    try {
      await axios.put(
        `${baseUrl}/api/holidy-note/update`,
        {
          holiday_note: note,
          is_publish: isActive,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Note updated successfully!");
      setTimeout(() => {
        setSaving(false);
      }, 2000);
      fetchPublicNote();
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      }
      setSaving(false);
    }
  };

  return (
    <>
      <SectionWrapper mt={3}>
        <Heading>Note on Public Holidays</Heading>
        <Grid
          container
          mt={1}
          spacing={3}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item md={4}>
            <Label>Note</Label>
            <Input
              placeholder="Delivery will be delayed on public holidays"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Grid>
          <Grid item md={4}>
            <Label>Publish</Label>
            <Checkbox
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
          </Grid>
          <Grid item md={4}>
            <SaveBTN onClick={() => handleUpdate()}>
              {saving ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : (
                "Update"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </SectionWrapper>
    </>
  );
}

const SectionWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  float: "right",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

export default HolidayNote;
