import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
// MUI Components Import
import {
  Container,
  Typography,
  Switch,
  Button,
  Box,
  FormControl,
} from "@mui/material";
// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
// React Toastify Imports
import { toast } from "react-toastify";
import SelectInput from "../../../../components/SelectInput";
import PrimaryInput from "../../../../components/PrimaryInput/PrimaryInput";

function ProductWarehouses({
  productWarehouses,
  onUpdateWarehouses,
  warehousesList,
  onAddClick,
}) {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [warehouse, setWarehouse] = useState([]);

  useEffect(() => {
    if (productWarehouses?.length > 0) {
      setIsSwitchOn(true);
      setWarehouse(productWarehouses);
    }
  }, [productWarehouses]);

  const isIdInWarehouse = (id) => {
    return warehouse.some((warehouseItem) => warehouseItem.warehouseId === id);
  };

  const handleAddClick = () => {
    onAddClick(true);
  };

  const handleAddWarehouse = () => {
    const newWarehouse = {
      warehouseId: "",
      warehouseStock: "",
    };
    const updatedWarehouses = [...warehouse, newWarehouse];
    setWarehouse(updatedWarehouses);
    onUpdateWarehouses(updatedWarehouses);
  };

  const handleWarehouseChange = (index, key, value) => {
    if (isIdInWarehouse(value)) {
      toast.error("Warehouse already added!");
      return;
    }
    const updatedWarehouses = [...warehouse];

    if (key === "warehouseStock") {
      value = value.replace(/,/g, "");
      if (value === "" || /^\d{0,10}(\.\d{0,2})?$/.test(value)) {
        const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        updatedWarehouses[index][key] = formattedValue;
      }
    } else {
      updatedWarehouses[index][key] = value;
    }

    setWarehouse(updatedWarehouses);
    onUpdateWarehouses(updatedWarehouses);
  };

  const handleBlur = (index) => {
    const updatedWarehouses = [...warehouse];
    const value = updatedWarehouses[index].warehouseStock;

    if (value && !/\./.test(value)) {
      updatedWarehouses[index].warehouseStock = value + ".00";
      setWarehouse(updatedWarehouses);
      onUpdateWarehouses(updatedWarehouses);
    }
  };

  const handleDeleteWarehouse = (index) => {
    const updatedWarehouses = [...warehouse];
    updatedWarehouses.splice(index, 1);
    setIsSwitchOn(updatedWarehouses.length > 0);
    setWarehouse(updatedWarehouses);

    onUpdateWarehouses(updatedWarehouses);
  };
  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "110px",
            }}
          >
            Warehouses
          </Box>
          <Switch
            size="medium"
            color="secondary"
            checked={isSwitchOn}
            onChange={() => setIsSwitchOn(!isSwitchOn)}
          />
        </Box>
        {isSwitchOn && (
          <>
            {warehouse.map((_, index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Label>Warehouse</Label>
                    <FormControl
                      size="small"
                      sx={{
                        background: "#fff",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "12px",
                        height: "50px",
                        "& .MuiInputBase-root": {
                          height: "50px",
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <SelectInput
                        name="warehouse"
                        styles={{ width: "100%" }}
                        borderRadius="4px"
                        height="41px"
                        value={warehouse[index].warehouseId} // Set the selected UUID here
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            "warehouseId",
                            e.target.value
                          )
                        }
                        data={warehousesList}
                        label="Select Warehouse"
                        options={warehousesList.map((option) => ({
                          id: option.uuid,
                          value: option.uuid,
                          label: option.name,
                        }))}
                      ></SelectInput>
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Label>Stock</Label>

                    <PrimaryInput
                      type="text"
                      label=""
                      name="volume"
                      placeholder="Stock"
                      value={warehouse[index].warehouseStock}
                      onChange={(e) =>
                        handleWarehouseChange(
                          index,
                          "warehouseStock",
                          e.target.value
                        )
                      }
                      onBlur={() => handleBlur(index)}
                      borderRadius="4px"
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginBottom: "3px",
                      }}
                    >
                      Action
                    </Box>
                    <DeleteIcon
                      sx={{
                        fontSize: "25px",
                        cursor: "pointer",
                        width: "25px",
                        height: "25px",
                        color: "#FF0000",
                      }}
                      onClick={() => handleDeleteWarehouse(index)}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}></Box>
              </Box>
            ))}

            <Box>
              <Button onClick={handleAddWarehouse}>Add</Button>
              <Button onClick={handleAddClick}>Create</Button>
            </Box>
          </>
        )}
      </Wrapper>
    </>
  );
}

// Styled Components
const Wrapper = styled(Container)(() => ({
  padding: "0 !important",
  margin: "10px 0 15px 0",
}));

const Label = styled(Typography)({
  fontSize: "12px",
  fontWeight: "500",
  marginBottom: "5px",
});

export default ProductWarehouses;
